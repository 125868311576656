import { defineStore } from "pinia";

export const userNeedsMapping = {
	swp: {
		49112: "Bring mich auf den neusten Stand",
		49122: "Erkläre es mir",
		49132: "Erweitere meinen Horizont",
		49142: "Lass mich mitfühlen",
		49152: "Unterhalte mich",
		49162: "Zeig mir, worüber alle reden",
		4: "Keine Kategorie", // default,
	},
	moz: {
		0: "Keine Kategorie", // default
		1: "Update me",
		2: "Neugierde",
		3: "Empathie/Sensationslust",
		4: "Gerechtigkeit",
		5: "Sicherheit",
	},
	lr: {
		0: "Keine Kategorie", // default
		1: "Update me",
		2: "Neugierde",
		3: "Empathie/Sensationslust",
		4: "Gerechtigkeit",
		5: "Sicherheit",
	},
};

export const useTrackingStore = defineStore("tracking", () => {
	const pageMetaData: object = ref({});
	const pageSystemData: object = ref({});
	const pagePaywall: Ref<boolean> = ref(false);

	function getPageTitle(): string {
		return this.pageMetaData?.title ?? "";
	}

	function getPageId(): string {
		return this.pageSystemData?.documentId ?? null;
	}

	function getPageDocumentType(): string {
		const documentType = this.pageSystemData?.documentType;

		if (documentType === "page") {
			return "overview";
		}

		if (["article", "overview", "media"].includes(documentType)) {
			return documentType;
		}

		return "other";
	}

	function getPageKilkayaDocumentType(): string {
		const documentType = this.pageSystemData?.documentType;
		const route = useRoute();

		if (route.path === "/") {
			return "frontpage";
		}

		if (["article", "page"].includes(documentType)) {
			return documentType;
		}

		return "page";
	}

	function getPageContentType(): string {
		return this.pageMetaData?.type;
	}

	function getPagePublicationDate(): string {
		return this.pageSystemData?.firstPublicationDate ?? "";
	}

	function getPageTags(): string[] {
		return this.pageMetaData?.topics?.values?.map((topic) => topic?.metadata?.title) || [];
	}

	function getRetrescoTags(): string[] {
		return this.pageMetaData?.retresco?.entities?.map((topic) => topic?.name) || [];
	}

	function getRetrescoTopics(): string[] {
		return usePageStore().getRetrescoTopics?.map((topic: any) => topic.name) || [];
	}

	function getPageAudience(): string | null {
		return this.pageMetaData?.["audience-" + this.pageMetaData?.sourcePublication] || null;
	}

	function getPageModificationDate(): string {
		return this.pageSystemData?.updatedAt ?? "";
	}

	function getPageAuthors(): string[] {
		return this.pageMetaData?.authors?.values?.map((author: any) => author.metadata.title);
	}

	function getPageIsPaidContent(): number {
		return this.pageMetaData?.payCategory === "plus" ? 1 : 0;
	}

	function getPagePaywall(): string {
		return this.pagePaywall ? "hard" : "open";
	}

	function isPagePaywall(): boolean {
		return this.pageMetaData?.payCategory === "plus";
	}

	function getPageUserNeeds(): string | null {
		const userNeedsId = this.pageMetaData?.[`userneeds-${this.pageMetaData?.sourcePublication}`] || null;

		return userNeedsMapping[this.pageMetaData?.sourcePublication]?.[userNeedsId] || null;
	}

	function getArticleType(): string {
		return this.pageMetaData?.payCategory === "plus" ? "plus" : "free";
	}

	function getSectionAndSubsections(): any {
		const route = useRoute();
		const slug = route?.params?.slug || [];

		return {
			section: slug?.slice(-2, -1).shift() || "",
			subsections: slug?.slice(0, -2) || [],
		};
	}

	return {
		pageMetaData,
		pageSystemData,
		pagePaywall,
		isPagePaywall,
		getPageTitle,
		getPageId,
		getPageDocumentType,
		getPageKilkayaDocumentType,
		getPageContentType,
		getPagePublicationDate,
		getPageTags,
		getRetrescoTags,
		getRetrescoTopics,
		getPageAudience,
		getPageModificationDate,
		getPageAuthors,
		getPageIsPaidContent,
		getPagePaywall,
		getPageUserNeeds,
		getArticleType,
		getSectionAndSubsections,
	};
});
