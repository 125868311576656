import {
	newTracker,
	enableActivityTracking,
	trackPageView,
	addGlobalContexts,
	trackSelfDescribingEvent,
	setUserId,
	clearUserData,
} from "@snowplow/browser-tracker";
import { AdTrackingPlugin } from "@snowplow/browser-plugin-ad-tracking";
import { ClientHintsPlugin } from "@snowplow/browser-plugin-client-hints";
import { ConsentPlugin } from "@snowplow/browser-plugin-consent";
import { ErrorTrackingPlugin } from "@snowplow/browser-plugin-error-tracking";
import { LinkClickTrackingPlugin, enableLinkClickTracking } from "@snowplow/browser-plugin-link-click-tracking";
import { enableButtonClickTracking } from "@snowplow/browser-plugin-button-click-tracking";
import { PerformanceNavigationTimingPlugin } from "@snowplow/browser-plugin-performance-navigation-timing";
import { PerformanceTimingPlugin } from "@snowplow/browser-plugin-performance-timing";
import { PrivacySandboxPlugin } from "@snowplow/browser-plugin-privacy-sandbox";
import { WebVitalsPlugin } from "@snowplow/browser-plugin-web-vitals";
import { SnowplowEcommercePlugin } from "@snowplow/browser-plugin-snowplow-ecommerce";
import { DwhTracking } from "~/tracking/track.dwh";
import Avo from "../libs/Avo.ts";
import { useUserDataFromCookie, useUserIsLoggedIn } from "#imports";
import { useTrackingStore } from "~/stores/tracking.store";

let dwhTracker: any;

const snowplowDWHTracker = {
	make: function (env: string) {
		const collectorUrls: { [key: string]: string } = {
			swp: useRuntimeConfig().public.snowplowTrackerDwhCollectorUrlSwp,
			moz: useRuntimeConfig().public.snowplowTrackerDwhCollectorUrlMoz,
			lr: useRuntimeConfig().public.snowplowTrackerDwhCollectorUrlLr,
		};
		const appIds: { [key: string]: string } = {
			swp: useRuntimeConfig().public.snowplowTrackerDwhAppIdSwp,
			moz: useRuntimeConfig().public.snowplowTrackerDwhAppIdMoz,
			lr: useRuntimeConfig().public.snowplowTrackerDwhAppIdLr,
		};

		// Initialisierung des Snowplow DWH Trackers
		newTracker("dwhTracker", collectorUrls[usePublication()], {
			appId: appIds[usePublication()],
			plugins: [
				AdTrackingPlugin(),
				ClientHintsPlugin(),
				ConsentPlugin(),
				ErrorTrackingPlugin(),
				LinkClickTrackingPlugin(),
				PerformanceNavigationTimingPlugin(),
				PerformanceTimingPlugin(),
				PrivacySandboxPlugin(),
				WebVitalsPlugin(),
				SnowplowEcommercePlugin(),
			],
			platform: "web",
			discoverRootDomain: true,
			cookieName: "sp",
			cookieSameSite: "Lax",
			cookieSecure: true,
			cookieLifetime: 63072000,
			sessionCookieTimeout: 1800,
			stateStorageStrategy: "cookieAndLocalStorage",
			maxLocalStorageQueueSize: 1000,
			crossDomainLinker: function (linkElement) {
				var networkSites = ["www.swp.de", "www.moz.de", "www.lr-online.de", "bw.bluum.de", "bb.bluum.de"];
				return linkElement.hostname !== location.hostname && networkSites.indexOf(linkElement.hostname) > -1;
			},
			encodeBase64: true,
			eventMethod: "post",
			bufferSize: 1,
			maxPostBytes: 40000,
			maxGetBytes: 1000,
			postPath: "/de.npg/t",
			resetActivityTrackingOnPageView: true,
			connectionTimeout: 5000,
			respectDoNotTrack: false,
			anonymousTracking: false,
			// anonymousTracking: { withSessionTracking: true },
			// anonymousTracking: { withSessionTracking: true, withServerAnonymisation: true },
			withCredentials: true, // Available from v3.2.0+
			contexts: {
				webPage: true, // Default
				session: true, // Adds client session context entity to events, off by default. Available in v3.5+.
				browser: true, // Adds browser context entity to events, off by default. Available in v3.9+.
				clientHints: true,
				performanceTiming: true,
			},
		});
		enableActivityTracking(
			{
				minimumVisitLength: 5,
				heartbeatDelay: 5,
			},
			["dwhTracker"],
		);

		enableLinkClickTracking({}, ["dwhTracker"]);

		enableButtonClickTracking({}, ["dwhTracker"]);

		// addGlobalContext
		// User entity based on the schema
		const user = useUserDataFromCookie();

		let userEntity = {
			schema: "iglu:de.npg/user/jsonschema/1-0-3",
			data: {
				user_id: user?.plenigoId ?? null, // 12345678 or null if not applicable
				user_type: useUserType(), // one of ["anonymous", "registered", "premium", "internal"]
				is_logged_in: useUserIsLoggedIn(), // true or false
				has_plus_access: ["premium", "internal"].includes(useUserType()), // false or true
				registered_at: user.registrationDate ? new Date(user.registrationDate).toISOString() : null, // The registration timestamp of this user
				products: user.subscriptions?.map((subscription: any) => ({
					access_right_unique_id: subscription.accessRightUniqueId,
					internal_title: subscription.internalTitle,
					product_id: subscription.productId,
				})),
				product_string: user.subscriptions?.map((subscription: any) => subscription.accessRightUniqueId).join(","),
				access_rights: user.accessRights?.map((accessRight: any) => ({
					life_time_start: accessRight.lifeTimeStart,
					life_time_end: accessRight.lifeTimeEnd,
					access_right_unique_id: accessRight.accessRightUniqueId,
					item_type: accessRight.itemType,
					item_id: accessRight.itemId,
				})),
				anonymous_appstore_purchase: user.anonymousStorePurchaseData,
			},
		};

		// Content entity based on the content schema
		const trackingStore = useTrackingStore();
		const { section, subsections } = trackingStore.getSectionAndSubsections();
		let contentEntity = {
			schema: "iglu:de.npg/content/jsonschema/2-0-1",
			data: {
				container_id: trackingStore.getPageId(), // 12345678 or null
				article_id: trackingStore.getPageId(), // 12345678 or null
				page_type: trackingStore.getPageDocumentType(), // one of ["article", "overview", "media", "other"]
				rtr_topics: trackingStore.getRetrescoTopics(), // Array of strings
				section: section, // "Politics" or null
				subsections: subsections, // Array of strings
			},
		};

		if (trackingStore.getPageDocumentType() === "article") {
			contentEntity.data = {
				...contentEntity.data,
				freemium_state: trackingStore.getArticleType(), // "free" or "plus"
				has_paywall: trackingStore.pagePaywall, // true or false
				content_user_need: trackingStore.getPageUserNeeds(), // "Information" or null
				content_type: trackingStore.getPageContentType(), // string or null
				audience: trackingStore.getPageAudience(), // string or null
				tags: trackingStore.getRetrescoTags(), // Array of strings
			};
		}

		if (trackingStore.getPagePublicationDate()) {
			contentEntity.data.publish_tstamp = trackingStore.getPagePublicationDate(); // timestamp or null
		}

		// Add entities to global contexts
		addGlobalContexts([userEntity, contentEntity], ["dwhTracker"]);
	},
	trackSelfDescribingEvent: (schema: string, data: any, contexts: any[]): void => {
		trackSelfDescribingEvent(
			{
				event: {
					schema: schema,
					data: data,
				},
				context: contexts,
			},
			["dwhTracker"],
		);
	},
	trackPageView: (title: string): void => {
		trackPageView({}, ["dwhTracker"]);
	},
	identify: (userId: string): void => {
		setUserId(userId, ["dwhTracker"]);
	},
	unidentify: (): void => {
		clearUserData({ preserveSession: false, preserveUser: false }, ["dwhTracker"]);
	},
};

const snowplowDriveTracker = {
	make: function (env: string) {
		const collectorUrls: { [key: string]: string } = {
			swp: useRuntimeConfig().public.snowplowTrackerDriveCollectorUrlSwp,
			moz: useRuntimeConfig().public.snowplowTrackerDriveCollectorUrlMoz,
			lr: useRuntimeConfig().public.snowplowTrackerDriveCollectorUrlLr,
		};
		const appIds: { [key: string]: string } = {
			swp: useRuntimeConfig().public.snowplowTrackerDriveAppIdSwp,
			moz: useRuntimeConfig().public.snowplowTrackerDriveAppIdMoz,
			lr: useRuntimeConfig().public.snowplowTrackerDriveAppIdLr,
		};

		// driveTracker
		newTracker("driveTracker", collectorUrls[usePublication()], {
			appId: appIds[usePublication()],
			plugins: [AdTrackingPlugin()],
			platform: "web",
			discoverRootDomain: true,
			cookieName: "sp",
			cookieSameSite: "Lax",
			cookieSecure: true,
			cookieLifetime: 63072000,
			sessionCookieTimeout: 1800,
			stateStorageStrategy: "cookieAndLocalStorage",
			maxLocalStorageQueueSize: 1000,
			encodeBase64: true,
			eventMethod: "post",
			bufferSize: 1,
			maxPostBytes: 40000,
			maxGetBytes: 1000,
			postPath: "/com.snowplowanalytics.snowplow/tp2",
			resetActivityTrackingOnPageView: true,
			connectionTimeout: 5000,
			respectDoNotTrack: false,
			anonymousTracking: false,
			// anonymousTracking: { withSessionTracking: true },
			// anonymousTracking: { withSessionTracking: true, withServerAnonymisation: true },

			contexts: {
				webPage: true, // Default
			},
		});

		enableActivityTracking(
			{
				minimumVisitLength: 5,
				heartbeatDelay: 5,
			},
			["driveTracker"],
		);

		const trackingStore = useTrackingStore();

		// User entity based on the schema
		let userContext = {
			schema: "iglu:de.drive/user_context/jsonschema/1-0-0",
			data: {
				userType: useUserType().replace("anonymous", "anonym"), // one of ["anonym", "registered", "premium", "internal"]
			},
		};

		// Content entity based on the content schema
		let articleContext = {
			schema: "iglu:de.drive/article_context/jsonschema/1-0-0",
			data: {
				articleId: String(trackingStore.getPageId()),
			},
		};

		if (trackingStore.getPageDocumentType() === "article") {
			articleContext.data = {
				...articleContext.data,
				articleType: trackingStore.getArticleType(), // ["plus", "free"]
				contentType: trackingStore.getPageContentType(), // ["article", "overview", "media", "other"]
				contentIsPaywall: trackingStore.isPagePaywall(), // [true, false]
			};
		}

		// Add entities to global contexts
		addGlobalContexts([userContext, articleContext], ["driveTracker"]);
	},
	trackSelfDescribingEvent: (schema: string, data: any, contexts: any[]): void => {
		trackSelfDescribingEvent(
			{
				event: {
					schema: schema,
					data: data,
				},
				context: contexts,
			},
			["driveTracker"],
		);
	},
	trackPageView: (title: string): void => {
		trackPageView({}, ["driveTracker"]);
	},
	identify: (userId: string): void => {
		setUserId(userId, ["driveTracker"]);
	},
	unidentify: (): void => {
		clearUserData({ preserveSession: false, preserveUser: false }, ["driveTracker"]);
	},
};

/**
 * Returns the scroll position in percent relative to the bottom of an element
 * 0 = element is below viewport
 * 100 = element is above viewport bottom
 *
 * @param el
 */
function getScrolledPercentage(el: Element) {
	const rect = el.getBoundingClientRect();
	const windowHeight = window.innerHeight || document.documentElement.clientHeight;
	const scrollHeight = window.pageYOffset || document.documentElement.scrollTop;
	const elementTop = rect.top + scrollHeight;
	const elementHeight = el.clientHeight;
	const scrolledDistanceBottom = Math.max(scrollHeight + windowHeight - elementTop, 0);

	return Math.min((100 / Math.max(elementHeight, 1)) * scrolledDistanceBottom, 100);
}

// Trigger Avo.articleRead() if the user scrolls the viewport to at least 80% of the article
function handleReadArticle() {
	let wasTriggered = false;
	const article = document.querySelector(".u-article");
	const paywall = getPaywallElement();
	const callback = () => {
		if (!wasTriggered && getScrolledPercentage(article) >= 80) {
			wasTriggered = true;
			dwhTracker.articleRead();
			window.removeEventListener("scroll", callback, true);
		}
	};

	if (article && !paywall) {
		window.addEventListener("scroll", callback, { passive: true });
	}
}

// Trigger Avo.paywallViewed() if more than 20% of the paywall is in the viewport
function handleViewPaywall() {
	const paywall = document?.querySelector(".u-paywall--show-text-overlay");
	const callback = (entries: any, observer: any) => {
		entries.forEach((entry: any) => {
			if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
				dwhTracker.paywallViewed();
				observer.unobserve(entry.target);
			}
		});
	};

	if (paywall) {
		const observer = new IntersectionObserver(callback, {
			root: null,
			threshold: 0.2,
		});
		observer.observe(paywall);
	}
}

function handleViewProductList() {
	const paywallOffers = parseOfferDataFromPaywall();
	const paywall = getPaywallElement();
	const callback = (entries: any, observer: any) => {
		entries.forEach((entry: any) => {
			if (entry.isIntersecting && entry.intersectionRatio == 1) {
				dwhTracker.productListViewed({ product: paywallOffers, name: "paywallOffers" });
				observer.unobserve(entry.target);
			}
		});
	};

	if (paywall) {
		const observer = new IntersectionObserver(callback, {
			root: null,
			rootMargin: "0px 0px 150px 0px",
			threshold: 1,
		});
		observer.observe(paywall);
	}
}

function getPaywallElement() {
	return document?.querySelector("." + useRuntimeConfig().public.paywallCssSelectorGlobal);
}

function getPaywallIds() {
	const paywallIds: { [key: string]: string } = {
		swp: useRuntimeConfig().public.paywallCssSelectorSwp,
		moz: useRuntimeConfig().public.paywallCssSelectorMoz,
		lr: useRuntimeConfig().public.paywallCssSelectorLr,
	};
	return paywallIds;
}

function getPaywallCtaButtonElements() {
	return document.querySelectorAll("." + useRuntimeConfig().public.paywallCssSelectorGlobal + " .ms-pwBtn");
}

function getPaywallOfferContainers() {
	return document.querySelectorAll("." + useRuntimeConfig().public.paywallCssSelectorGlobal + " .ms-campaignCol");
}

function getProductObjectData(htmlElement: any) {
	if (typeof htmlElement !== "object") {
		return false;
	}

	return {
		id: htmlElement?.getAttribute("data-offer") || "not set",
		name: htmlElement?.getAttribute("data-product") || null,
		category: "paywall",
		price: parseFloat(htmlElement?.getAttribute("data-price")) ?? 0,
		listPrice: parseFloat(htmlElement?.getAttribute("data-listprice")) ?? null,
		quantity: 1,
		variant: htmlElement?.getAttribute("data-variant") ?? null,
		brand: usePublication() ?? null,
		inventoryStatus: htmlElement?.getAttribute("data-inventory-status") ?? null,
		position: parseInt(htmlElement?.getAttribute("data-position")) ?? null,
		currency: htmlElement?.getAttribute("data-currency") || "EUR",
		creativeId: htmlElement?.getAttribute("data-creative-id") ?? null,
	};
}

function parseOfferDataFromPaywall() {
	const paywallCtaButtonElements = getPaywallCtaButtonElements();
	const productList: Array<any> = [];

	for (const paywallCtaButtonElement of paywallCtaButtonElements) {
		productList.push(getProductObjectData(paywallCtaButtonElement));
	}
	return productList;
}

function getElementTrackingData(element: Object): Object {
	let data = {};

	try {
		data = JSON.parse(element.dataset?.tracking);
	} catch (e) {}

	return data;
}

function getModuleContentData(module: Object): Object {
	const trackingData: Object = getElementTrackingData(module);

	return {
		zone: trackingData?.zone || "main_content",
		titleVisible: trackingData?.title_visible || "",
		titleInternal: trackingData?.title_internal || "",
		modulePosition: trackingData?.module_position || 1,
		moduleType: trackingData?.module_type || "content",
		contentOrigin: trackingData?.contentOrigin || null,
	};
}

function getModuleContentItemData(item: Object): Object {
	const itemTrackingData: Object = getElementTrackingData(item);

	return {
		containerId: itemTrackingData?.article_id || "",
		hasTeaser: itemTrackingData?.has_Teaser || false,
		hasImage: itemTrackingData?.has_Image || false,
		positionInModule: itemTrackingData?.position_in_module || 1,
		articleId: itemTrackingData?.article_id || "",
		positionInPage: itemTrackingData?.position_in_page || 1,
		correlationId: itemTrackingData?.corellation_id || null,
		isPlus: itemTrackingData?.is_plus || false,
	};
}

function handleModuleEngagedViewed(): void {
	const moduleItems = document.querySelectorAll("[data-tracking-module]");
	// Only track untracked modules. Re-execution of this function is required for modules loaded via Ajax.
	const modules = Array.from(moduleItems).filter((m) => (m?.isTracked ? false : (m.isTracked = true)));
	const callback = (entries: any, observer: any): void => {
		entries.forEach((entry) => {
			if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
				const module = entry.target;
				const moduleData = getModuleContentData(module);
				if (["content", "recommendation"].includes(moduleData?.moduleType)) {
					const moduleContentItemEntity: Object[] = [];
					module.querySelectorAll("[data-tracking-module-item]").forEach((item) => {
						moduleContentItemEntity.push(getModuleContentItemData(item));
					});
					dwhTracker.moduleEngaged({
						action: "viewed",
						moduleEntity: moduleData,
						moduleContentItemEntity: moduleContentItemEntity,
					});
				} else if (moduleData?.moduleType === "newsletter") {
					const moduleNewsletterItemEntity: Object[] = [];
					module.querySelectorAll("[data-tracking]").forEach((item) => {
						moduleNewsletterItemEntity.push(getElementTrackingData(item));
					});
					dwhTracker.moduleEngaged({
						action: "viewed",
						moduleEntity: moduleData,
						moduleNewsletterItemEntity: moduleNewsletterItemEntity,
					});
				}
				observer.unobserve(entry.target);
			}
		});
	};

	if (modules.length) {
		const observer = new IntersectionObserver(callback, {
			root: null,
			threshold: 0.2,
		});

		modules.forEach((target) => {
			observer.observe(target);
		});
	}
}

function handleModuleEngagedItemClicked() {
	document.body.addEventListener("click", (e: any) => {
		const moduleItem = e.target.closest("[data-tracking-module-item]");
		if (moduleItem) {
			const module = moduleItem.closest("[data-tracking-module]");
			if (module) {
				dwhTracker.moduleEngaged({
					action: "clicked",
					moduleEntity: getModuleContentData(module),
					moduleContentItemEntity: [getModuleContentItemData(moduleItem)],
				});
			}
		}
	});
	document.body.addEventListener("avo-submit-newsletter-form", (e: any) => {
		const form = e?.detail;
		if (form) {
			const moduleNewsletterItemEntity: Object[] = [];
			form.querySelectorAll("[data-tracking]").forEach((item) => {
				moduleNewsletterItemEntity.push(getElementTrackingData(item));
			});
			dwhTracker.moduleEngaged({
				action: "clicked",
				moduleEntity: getModuleContentData(form),
				moduleNewsletterItemEntity: moduleNewsletterItemEntity,
			});
		}
	});
}

function handleNavigationItemClicked() {
	const clickHandler = (e: any) => {
		const clickTarget = e.target;
		const sidebarItemSelector = ".u-menu .u-nav__link, .u-menu .u-link";
		const quickNavItemSelector = ".u-header__quick-navbar .u-link";
		const retrescoTagSelector = ".u-tags--retresco .u-link";
		const footerItemSelector = ".u-footer a";
		let zone, typeNavigation, label;

		// Sidebar navigation item clicked
		if (clickTarget.closest(sidebarItemSelector)) {
			label = (clickTarget.closest(sidebarItemSelector).innerText || "").trim();
			zone = "sidebar";
			typeNavigation = "main_navigation";
		}
		// Quick navigation item clicked
		else if (clickTarget.closest(quickNavItemSelector)) {
			label = (clickTarget.closest(quickNavItemSelector).innerText || "").trim();
			zone = "header";
			typeNavigation = "quick_navigation";
		}
		// Retresco tag clicked
		else if (clickTarget.closest(retrescoTagSelector)) {
			label = (clickTarget.closest(retrescoTagSelector).innerText || "").trim();
			zone = "header";
			typeNavigation = "retresco_entity";
		}
		// Footer navigation item clicked
		else if (clickTarget.closest(footerItemSelector)) {
			label = (clickTarget.closest(footerItemSelector).innerText || "").trim();
			zone = "footer";
			typeNavigation = "footer_navigation";
		}

		if (label) {
			dwhTracker.navigationItemClicked({
				label: label,
				zone: zone,
				typeNavigation: typeNavigation,
			});
		}
	};

	document.body.addEventListener("click", clickHandler);
	document.body.addEventListener("mousedown", function (e) {
		if (e.button === 1) {
			clickHandler(e);
		}
	});
}

function handleOnboardingFlowInteracted(): void {
	document.body.addEventListener("avo-onboarding-flow-interacted", (event: any) => {
		dwhTracker.onboardingFlowInteracted(event.detail);
	});
}

function handleElementEngagedClicked(): void {
	document.body.addEventListener("avo-element-engaged", (event: any) => {
		dwhTracker.elementEngaged(event.detail);
	});
}

// Trigger Avo.pageBookmarked() if the user attempts to add a bookmark
function handlePageBookmarked() {
	document.body.addEventListener("avo-bookmark-added", (event: any) => {
		dwhTracker.pageBookmarked();
	});
}

// Trigger Avo.productClicked if the user clicks one of the offered products at the paywall
function handleProductClicked() {
	const paywallCtaButtonElements = getPaywallCtaButtonElements();
	for (const paywallCtaButtonElement of paywallCtaButtonElements) {
		paywallCtaButtonElement.addEventListener("click", (e: any) => {
			const productData = getProductObjectData(paywallCtaButtonElement);
			dwhTracker.productClicked({ product: [productData], name: "paywallOffers" });
		});
	}
}

function handleProductViewed() {
	const paywallOfferContainers = getPaywallOfferContainers();
	const paywallCtaButtonElements = getPaywallCtaButtonElements();
	const trackProductViewed = function (button) {
		const productData = getProductObjectData(button);
		dwhTracker.productViewed({ product: [productData] });
	};
	for (const [index, paywallOfferContainer] of paywallOfferContainers.entries()) {
		const cta = paywallCtaButtonElements[index];
		const offerDetails = paywallOfferContainer.querySelector(".ms-campaignDetails");
		const offerDetailsVisible = offerDetails && offerDetails.offsetHeight > 1;
		// Offer details are visible. Trigger tracking as soon as they are visible inside the viewport
		if (offerDetailsVisible) {
			const observer = new IntersectionObserver(
				(entries: any, observer: any) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
							observer.unobserve(entry.target);
							trackProductViewed(cta);
						}
					});
				},
				{
					root: null,
					rootMargin: "0px 0px 150px 0px",
					threshold: 1,
				},
			);
			observer.observe(offerDetails);
		}
		// Offer details are collapsed. Trigger tracking after extending the details by click
		else {
			paywallOfferContainer.addEventListener("click", function handleProductViewedHelper() {
				paywallOfferContainer.removeEventListener("click", handleProductViewedHelper);
				trackProductViewed(cta);
				if (paywallOfferContainer.classList.contains("active")) {
					paywallOfferContainer.removeEventListener("click", (e: any) => {});
				}
			});
		}
	}
}

function handleUserLoggedIn() {
	document.body.addEventListener("avo-user-logged-in", (e: Event) => {
		const plenigoId = e?.detail?.plenigoId || useUserDataFromCookie()?.plenigoId;
		dwhTracker.userLoggedIn({ userId_: plenigoId });
	});
}

function initClickHandlers() {
	handleUserLoggedIn();
	handleProductClicked();
	handleProductViewed();
	handlePageBookmarked();
	handleModuleEngagedItemClicked();
	handleNavigationItemClicked();
	handleOnboardingFlowInteracted();
	handleElementEngagedClicked();
}

function initScrollHandlers() {
	handleReadArticle();
	handleViewPaywall();
	handleViewProductList();
	handleModuleEngagedViewed();
	document.body.addEventListener("avo-refresh-module-engaged-viewed", handleModuleEngagedViewed);
}

async function intConsoleDebugger() {
	if (["dev", "staging"].includes(useRuntimeConfig().public.snowplowTrackerEnv)) {
		return await import("@snowplow/browser-plugin-debugger");
	}
}

function trackPageViewed() {
	dwhTracker.pageViewed({ snowplowPageName_: useTrackingStore().getPageTitle() });
}

function trackUserLoggedIn(user) {
	dwhTracker.userLoggedIn({ userId_: user.plenigoId });
}

async function init(): void {
	const user = useUserDataFromCookie();
	const appIds: { [key: string]: string } = {
		swp: useRuntimeConfig().public.snowplowTrackerDwhAppIdSwp,
		moz: useRuntimeConfig().public.snowplowTrackerDwhAppIdMoz,
		lr: useRuntimeConfig().public.snowplowTrackerDwhAppIdLr,
	};

	// Init NPG avo wrapper
	dwhTracker = new DwhTracking(usePublication());

	// Init inspector
	const { AvoInspector } = await import("avo-inspector");
	const inspector = new AvoInspector({
		apiKey: Avo.avoInspectorApiKey,
		env: useRuntimeConfig().public.snowplowTrackerEnv,
		version: "0.0.1",
		appName: appIds[usePublication()],
	});

	// Init avo
	Avo.initAvo(
		{
			env: useRuntimeConfig().public.snowplowTrackerEnv,
			inspector: inspector,
		},
		null,
		snowplowDriveTracker,
		snowplowDWHTracker,
	);

	// Basic tracking
	user?.plenigoId && trackUserLoggedIn(user);
	trackPageViewed();

	// Init handlers
	initClickHandlers();
	initScrollHandlers();

	// Init debugger
	intConsoleDebugger();
}

export default {
	init,
};
