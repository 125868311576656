<template>
	<!-- STEP START -->
	<OnboardingStep0 v-if="step === 0" @goto="step = $event"></OnboardingStep0>

	<!-- STEP 1 -->
	<OnboardingStep1 v-if="step === 1" @goto="step = $event" :step="step" :steps="steps"></OnboardingStep1>

	<!-- STEP 2 -->
	<OnboardingStep2 v-if="step === 2" @goto="step = $event" :step="step" :steps="steps"></OnboardingStep2>

	<!-- STEP 3 -->
	<OnboardingStep3 v-if="step === 3" @goto="step = $event" :step="step" :steps="steps"></OnboardingStep3>

	<!-- STEP 4 -->
	<OnboardingStep4 v-if="step === 4" @goto="step = $event" :step="step" :steps="steps"></OnboardingStep4>
</template>

<script lang="ts" setup>
	const step = ref(0);
	const steps = ["App herunterladen", "Artikel speichern", "Artikel vorlesen lassen"];

	watch(step, (newStep, oldStep) => {
		if (newStep === 4 && newStep !== oldStep) {
			document.body.dispatchEvent(new CustomEvent("avo-onboarding-flow-interacted", {
				detail: { onboardingAction: "completed" }
			}));
		}
	});
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";
	.u-onboarding__modal {
		.u-modal__content {
			padding: 0;
		}
	}

	.u-onboarding {
		display: flex;
		@include tablet-below {
			flex-direction: column;
		}

		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	.u-onboarding__app-links {
		flex-wrap: wrap;
		justify-content: center;
		gap: 0.625rem;
	}

	.u-onboarding__next-step-button {
		width: 26.125rem;
		max-width: calc(100vw - 4.375rem * 2);
		min-height: 3.125rem;
	}

	.u-onboarding__side {
		flex: 1;
		padding: var(--space-10) var(--space-10);

		@include mobile {
			padding: var(--space-4) var(--space-10);
		}
	}

	.u-onboarding__content {
		flex: 2.16;
		padding: var(--space-10) var(--space-10);

		@include mobile {
			padding: var(--space-4) var(--space-10);
		}
	}
</style>
