import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

const STORAGE_KEY = "checkout";
const DROPOUT_STORAGE_KEY = "checkoutDropout";

export const useCheckoutStore = defineStore("checkout", () => {
	if (import.meta.server) {
		throw new Error("useCheckoutStore is not available on the server side");
	}

	const permanent = useStorage(
		DROPOUT_STORAGE_KEY,
		{
			checkoutFunnel: "",
			plenigoId: "",
			userEmail: "",
		},
		localStorage,
	);

	const session = useStorage(
		STORAGE_KEY,
		{
			actualUrl: "",
			actualHostWithPath: "",
			articleId: "",
			articlePublishedTime: "",
			articleSection: "",
			articleSubSection: "",
			articleTitle: "",
			articleTags: "",
			checkoutState: 0,
			freemiumState: "",
			hasStartedAt: "",
			hostName: "",
			offerid: "",
			originUrl: "",
			pageType: "",
			paymentMethod: "",
			plenigoId: "",
			position: "",
			purchaseId: "",
			referrer: "",
			userEmail: "",
		},
		sessionStorage,
	);

	return {
		permanent,
		session,
	};
});
