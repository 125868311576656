import { defineStore } from "pinia";

export const useClientStore = defineStore("client", () => {
	const bookmarks: Ref<number[]> = ref([]);
	const breadcrumbs: Ref<number[]> = ref([]);
	const rememberTopicForLogin: object | null = ref(null);

	watch(bookmarks, () => triggerBookmarkStateUpdate);

	function triggerBookmarkStateUpdate() {
		const event = new CustomEvent("update-bookmarks", { detail: Array.from(bookmarks.value) });
		document.body.dispatchEvent(event);
	}

	async function fetchBookmarks() {
		const response = await fetch("/api/user/user-favourites/bookmarks");
		const bookmarks = await response.json();
		updateBookmarks(bookmarks);
	}

	function updateBookmarks(newList: number[]) {
		bookmarks.value = newList;
		triggerBookmarkStateUpdate();
	}

	return {
		bookmarks,
		fetchBookmarks,
		updateBookmarks,
		triggerBookmarkStateUpdate,
		breadcrumbs,
		rememberTopicForLogin
	};
});
