/**
 * Represents incoming query params not set by regular users
 */
export enum IncomingProtectedQueryParams {
	CACHE = "cache",
	PAYWALL = "paywall",
	BOT = "bot",
	ADS = "ads",
	CDN = "cdn",
}

/**
 * Represents incoming query params used by regular users that create a page variant that should be respected by cloudfront caching
 */
export enum IncomingQueryParams {
	SESSIONID = "sessionid",
	XML = "_xml",
	RETURN_URL = "returnurl",
	STEP = "step",
	TOKEN = "token",
	VERIFICATION_TOKEN = "verificationtoken",
	ADZONE = "ad_zone",
	SHOWADS = "showAds",
	OFFERID = "offerid",
	GOOGFC = "googfc",
	PCI = "pci", // plenigo checkout id
	PCS = "pcs", // plenigo checkout step
	BA_TOKEN = "ba_token", // plenigo ba token
	ID = "id",
	UNDO = "undo",
	DEBUG = "debug",
	PAGE = "page",
	TERM = "term",
	ONBOARDING = "onboarding",
	DATEFILTER = "datefilter",
	ACTIVE = "active",
	P = "p",
}

/**
 * These options match the IncomingProtectedParams but can also be set regardless of the params value.
 * These options are present on every request.
 * They are only set by the edge function and cant be modified anywhere else.
 * These options are passed in the x-request-options header
 */
export type RequestOptions = {
	paywall: boolean;
	bot: "none" | "google";
	app: boolean;
};

/**
 * These options specify parameters only meant for debugging.
 * They may overwrite RequestOptions.
 */
export type RequestDebugOptions = {
	[IncomingProtectedQueryParams.CACHE]?: boolean;
	[IncomingProtectedQueryParams.PAYWALL]?: boolean;
	[IncomingProtectedQueryParams.BOT]?: "none" | "google";
	[IncomingProtectedQueryParams.ADS]?: boolean;
	[IncomingProtectedQueryParams.CDN]?: boolean;
};

export const InternalUsers = [
	// S-Bahn Stuttgart
	"185.46.137.10/32",
	"185.46.137.19/32",
	"176.74.57.163/32",
	"176.74.57.165/32",
	"87.230.72.90/32",
	// NPG
	"194.116.206.0/23",
	"62.152.185.5/32",
	"5.158.180.33/32",
	"217.8.56.101/32",
	"10.54.0.0/16",
	"132.112.0.0/16",
];
