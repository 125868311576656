export default async (topicId: string, allTopics: any[], action: "add" | "delete") => {
	const topic = allTopics.find((t) => t.topicId === topicId);

	if (!topic) return;

	await $fetch(action === "add" ? `/api/user/user-favourites/save-favourite-topic` : `/api/user/user-favourites/delete-favourite-topic`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: topic,
	});
};
