export default (options: { default?: any; validate?: (fieldValue: globalThis.Ref<any>) => Promise<string> | string }) => {
	const fieldValue = ref("default" in options ? options.default : "");
	const errorMessage = ref("");
	const isValidated = ref(false);
	const isValid = ref(false);

	return reactive({
		fieldValue,
		isValid: computed(() => isValid.value),
		isValidated: computed(() => isValidated.value),
		errorMessage: errorMessage,
		validate: async function () {
			errorMessage.value = options.validate ? await options.validate(fieldValue) : "";
			isValid.value = options.validate ? !errorMessage.value : true;
			isValidated.value = true;
			return isValid.value;
		},
		reset() {
			fieldValue.value = "default" in options ? options.default : "";
			errorMessage.value = "";
			isValidated.value = false;
			isValid.value = false;
		},
	});
};
