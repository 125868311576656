// Generated by Avo VERSION 135.0.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "h7mYhLKOnzHPpthtKZw4";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "WLw2Nzjns6HRTmj5y6w9",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "Qg1zFAwD0Y460MvsYCxk",
          "se": (new Date()).toISOString(),
          "so": "eiIXv1IWZx",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "WLw2Nzjns6HRTmj5y6w9",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "Qg1zFAwD0Y460MvsYCxk",
          "se": (new Date()).toISOString(),
          "so": "eiIXv1IWZx",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "Qg1zFAwD0Y460MvsYCxk",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface SnowplowDestination {
  make: (env: AvoEnv | null) => void;
  /**
   * @deprecated Use the `trackSelfDescribingEvent` method instead
   */
  logEvent?: (data: any, context: any) => void;
  trackSelfDescribingEvent: (schema: string, data: any, contexts: any[]) => void;
  trackPageView: (title: string) => void;
  identify: (userId: string) => void;
  unidentify: () => void;
}

export interface SnowplowDestination {
  make: (env: AvoEnv | null) => void;
  /**
   * @deprecated Use the `trackSelfDescribingEvent` method instead
   */
  logEvent?: (data: any, context: any) => void;
  trackSelfDescribingEvent: (schema: string, data: any, contexts: any[]) => void;
  trackPageView: (title: string) => void;
  identify: (userId: string) => void;
  unidentify: () => void;
}

export interface ModuleContentItemEntity {
  containerId: string | null | undefined
  hasTeaser: boolean | null | undefined
  hasImage: boolean | null | undefined
  positionInModule: number | null | undefined
  articleId: string | null | undefined
  positionInPage: number | null | undefined
  correlationId: string | null | undefined
  isPlus: boolean | null | undefined
}

export interface AnonymousAppstorePurchase {
  permissions: string[] | null | undefined
  productId: string | null | undefined
}

export interface CheckoutEntity {
  status: StatusValueType
}

export interface ModuleEntity {
  zone: ZoneValueType
  titleVisible: string
  titleInternal: string
  modulePosition: number
  moduleType: ModuleTypeValueType
  contentOrigin: ContentOriginValueType | null | undefined
}

export interface UserEntity {
  userId: string | null | undefined
  userType: UserTypeValueType | null | undefined
  isLoggedIn: boolean
  hasPlusAccess: boolean
  registeredAt: string | null | undefined
  productString: string | null | undefined
  accessRights: AccessRights[] | null | undefined
  anonymousAppstorePurchase: AnonymousAppstorePurchase[] | null | undefined
  products: Products[] | null | undefined
}

export interface Promotion {
  id: string
  name: string | null | undefined
  productIds: string[] | null | undefined
  creativeId: string | null | undefined
  type: TypeValueType | null | undefined
  slot: string | null | undefined
  position: number | null | undefined
}

export interface TransactionError {
  errorCode: string | null | undefined
  errorShortcode: string | null | undefined
  errorDescription: string | null | undefined
  errorType: ErrorTypeValueType | null | undefined
  resolution: string | null | undefined
}

export interface Transaction {
  transactionId: string
  revenue: number
  currency: string
  totalQuantity: number
  tax: number | null | undefined
  shipping: number | null | undefined
  discountCode: string | null | undefined
  discountAmount: number | null | undefined
  creditOrder: boolean | null | undefined
  paymentMethod: PaymentMethodValueType | null | undefined
}

export interface AccessRights {
  lifeTimeStart: string | null | undefined
  lifeTimeEnd: string
  accessRightUniqueId: string | null | undefined
  itemType: ItemTypeValueType | null | undefined
  itemId: string | null | undefined
}

export interface ModuleMarketingItemEntity {
  positionInModule: number | null | undefined
  label: string
  elementCategory: string
}

export interface Cart {
  cartId: string | null | undefined
  totalValue: number
  currency: string
}

export interface Product {
  id: string
  name: string | null | undefined
  category: string
  price: number
  listPrice: number | null | undefined
  quantity: number | null | undefined
  variant: string | null | undefined
  brand: string | null | undefined
  inventoryStatus: string | null | undefined
  position: number | null | undefined
  currency: string
  creativeId: string | null | undefined
}

export interface Products {
  accessRightUniqueId: string | null | undefined
  internalTitle: string | null | undefined
  productId: string | null | undefined
}

export interface EpaperEntity {
  edition: string
  book: string
  pageNumber: number
  numberOfPages: number
  publishedAtDate: string
  viewMode: ViewModeValueType
  issue: number
  year: number
}

export interface CheckoutStep {
  step: number
  shippingPostcode: string | null | undefined
  billingPostcode: string | null | undefined
  shippingFullAddress: string | null | undefined
  billingFullAddress: string | null | undefined
  deliveryProvider: string | null | undefined
  deliveryMethod: string | null | undefined
  couponCode: string | null | undefined
  accountType: string | null | undefined
  paymentMethod: PaymentMethodValueType | null | undefined
  proofOfPayment: string | null | undefined
}

export interface ContentEntity {
  containerId: string | null | undefined
  articleId: string | null | undefined
  pageType: PageTypeValueType | null | undefined
  freemiumState: FreemiumStateValueType | null | undefined
  hasPaywall: boolean | null | undefined
  contentUserNeed: string | null | undefined
  contentType: string | null | undefined
  audience: string | null | undefined
  tags: string[] | null | undefined
  rtrTopics: string[] | null | undefined
  subsections: string[] | null | undefined
  publishTstamp: string | null | undefined
  section: string | null | undefined
}

export interface ModuleNewsletterItemEntity {
  newsletter: string
  positionInModule: number | null | undefined
  state: StateValueType | null | undefined
}

export interface EnvironmentEntity {
  environment: EnvironmentValueType
  appMode: AppModeValueType | null | undefined
  epaperViewMode: EpaperViewModeValueType | null | undefined
}

export const State = {
  'CHECKED': 'checked',
  'UNCHECKED': 'unchecked',
} as const;
export type StateType = typeof State;
export type StateValueType = StateType[keyof StateType];

export const Type = {
  'ADD_TO_CART': 'add_to_cart',
  'CHECKOUT_STEP': 'checkout_step',
  'LIST_CLICK': 'list_click',
  'LIST_VIEW': 'list_view',
  'PRODUCT_VIEW': 'product_view',
  'PROMO_CLICK': 'promo_click',
  'PROMO_VIEW': 'promo_view',
  'REFUND': 'refund',
  'REMOVE_FROM_CART': 'remove_from_cart',
  'TRANSACTION': 'transaction',
  'TRNS_ERROR': 'trns_error',
  'PRODUCT_CLICK': 'product_click',
} as const;
export type TypeType = typeof Type;
export type TypeValueType = TypeType[keyof TypeType];

export const FreemiumState = {
  'FREE': 'free',
  'PLUS': 'plus',
} as const;
export type FreemiumStateType = typeof FreemiumState;
export type FreemiumStateValueType = FreemiumStateType[keyof FreemiumStateType];

export const TypeNavigation = {
  'MAIN_NAVIGATION': 'main_navigation',
  'FOOTER_NAVIGATION': 'footer_navigation',
  'QUICK_NAVIGATION': 'quick_navigation',
  'RETRESCO_ENTITY': 'retresco_entity',
} as const;
export type TypeNavigationType = typeof TypeNavigation;
export type TypeNavigationValueType = TypeNavigationType[keyof TypeNavigationType];

export const ErrorType = {
  'HARD': 'hard',
  'SOFT': 'soft',
} as const;
export type ErrorTypeType = typeof ErrorType;
export type ErrorTypeValueType = ErrorTypeType[keyof ErrorTypeType];

export const ModuleType = {
  'CONTENT': 'content',
  'NEWSLETTER': 'newsletter',
  'MARKETING': 'marketing',
} as const;
export type ModuleTypeType = typeof ModuleType;
export type ModuleTypeValueType = ModuleTypeType[keyof ModuleTypeType];

export const PaymentMethod = {
  'STRIPE_PAYMENT': 'StripePayment',
  'SEPA_PAYMENT': 'SepaPayment',
  'PAY_PAL_PAYMENT': 'PayPalPayment',
  'AMAZON_PAYMENT': 'AmazonPayment',
} as const;
export type PaymentMethodType = typeof PaymentMethod;
export type PaymentMethodValueType = PaymentMethodType[keyof PaymentMethodType];

export const ElementType = {
  'BUTTON': 'button',
  'LINK': 'link',
  'ACCORDION': 'accordion',
  'SLIDER': 'slider',
  'DROPDOWN': 'dropdown',
  'TAB': 'tab',
  'TOGGLE': 'toggle',
  'CAROUSEL': 'carousel',
  'IMAGE': 'image',
  'MODAL': 'modal',
} as const;
export type ElementTypeType = typeof ElementType;
export type ElementTypeValueType = ElementTypeType[keyof ElementTypeType];

export const Environment = {
  'WEB': 'web',
  'APP': 'app',
} as const;
export type EnvironmentType = typeof Environment;
export type EnvironmentValueType = EnvironmentType[keyof EnvironmentType];

export const Action = {
  'CLICKED': 'clicked',
  'VIEWED': 'viewed',
} as const;
export type ActionType = typeof Action;
export type ActionValueType = ActionType[keyof ActionType];

export const ViewMode = {
  'CLASSIC': 'classic',
  'MODERN': 'modern',
} as const;
export type ViewModeType = typeof ViewMode;
export type ViewModeValueType = ViewModeType[keyof ViewModeType];

export const ItemType = {
  'EXTERNAL': 'EXTERNAL',
  'SUBSCRIPTION_ITEM': 'SUBSCRIPTION_ITEM',
  'SINGLE_PRODUCT': 'SINGLE_PRODUCT',
  'APP_STORE_SUBSCRIPTION': 'APP_STORE_SUBSCRIPTION',
} as const;
export type ItemTypeType = typeof ItemType;
export type ItemTypeValueType = ItemTypeType[keyof ItemTypeType];

export const UserIdentificationAction = {
  'LOGIN': 'login',
  'LOGOUT': 'logout',
} as const;
export type UserIdentificationActionType = typeof UserIdentificationAction;
export type UserIdentificationActionValueType = UserIdentificationActionType[keyof UserIdentificationActionType];

export const AppMode = {
  'EPAPER': 'epaper',
  'ONLINE': 'online',
} as const;
export type AppModeType = typeof AppMode;
export type AppModeValueType = AppModeType[keyof AppModeType];

export const OnboardingAction = {
  'STARTED': 'started',
  'ABANDONED': 'abandoned',
  'COMPLETED': 'completed',
} as const;
export type OnboardingActionType = typeof OnboardingAction;
export type OnboardingActionValueType = OnboardingActionType[keyof OnboardingActionType];

export const UserType = {
  'ANONYMOUS': 'anonymous',
  'REGISTERED': 'registered',
  'PREMIUM': 'premium',
  'INTERNAL': 'internal',
} as const;
export type UserTypeType = typeof UserType;
export type UserTypeValueType = UserTypeType[keyof UserTypeType];

export const PageType = {
  'ARTICLE': 'article',
  'OVERVIEW': 'overview',
  'MEDIA': 'media',
  'OTHER': 'other',
} as const;
export type PageTypeType = typeof PageType;
export type PageTypeValueType = PageTypeType[keyof PageTypeType];

export const CostModel = {
  'CPC': 'cpc',
  'CPM': 'cpm',
  'CPA': 'cpa',
} as const;
export type CostModelType = typeof CostModel;
export type CostModelValueType = CostModelType[keyof CostModelType];

export const Zone = {
  'HEADER': 'header',
  'FOOTER': 'footer',
  'MAIN_CONTENT': 'main_content',
  'SIDEBAR': 'sidebar',
} as const;
export type ZoneType = typeof Zone;
export type ZoneValueType = ZoneType[keyof ZoneType];

export const EpaperViewMode = {
  'CLASSIC': 'classic',
  'MODERN': 'modern',
} as const;
export type EpaperViewModeType = typeof EpaperViewMode;
export type EpaperViewModeValueType = EpaperViewModeType[keyof EpaperViewModeType];

export const Status = {
  'STARTED': 'started',
  'COMPLETED': 'completed',
} as const;
export type StatusType = typeof Status;
export type StatusValueType = StatusType[keyof StatusType];

export const ContentOrigin = {
  'RECOMMENDATION': 'recommendation',
  'AUTOMATIC': 'automatic',
  'CURRATED': 'currated',
} as const;
export type ContentOriginType = typeof ContentOrigin;
export type ContentOriginValueType = ContentOriginType[keyof ContentOriginType];

let SnowplowDriveTracker: SnowplowDestination | null = null;
let SnowplowDWHTracker: SnowplowDestination | null = null;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  SnowplowDriveTrackerDestination: SnowplowDestination,
  SnowplowDWHTrackerDestination: SnowplowDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }

    SnowplowDriveTracker = SnowplowDriveTrackerDestination;
    SnowplowDriveTracker && SnowplowDriveTracker.make && SnowplowDriveTracker.make(__AVO_ENV__);

    SnowplowDWHTracker = SnowplowDWHTrackerDestination;
    SnowplowDWHTracker && SnowplowDWHTracker.make && SnowplowDWHTracker.make(__AVO_ENV__);
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertModuleContentItemEntityOptional(
  moduleContentItemEntity: ModuleContentItemEntity[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (moduleContentItemEntity !== undefined && moduleContentItemEntity !== null) {
    messages = messages.concat(AvoAssert.assertList("-KeKi2kID", "module_content_item_entity", moduleContentItemEntity));
    moduleContentItemEntity.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("-KeKi2kID", "item at index " + index + " in module_content_item_entity", value));
      messages = messages.concat(assertPositionInModuleOptional(value.positionInModule, "item at index " + index + " in module_content_item_entity"));
      messages = messages.concat(assertPositionInPageOptional(value.positionInPage, "item at index " + index + " in module_content_item_entity"));
    });
  }
  return messages;
}

function assertAnonymousAppstorePurchase(
  anonymousAppstorePurchase: AnonymousAppstorePurchase[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("0lAO_cnMc", "anonymous_appstore_purchase", anonymousAppstorePurchase));
  if (anonymousAppstorePurchase !== null && anonymousAppstorePurchase !== undefined && anonymousAppstorePurchase.forEach) {
    anonymousAppstorePurchase.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("0lAO_cnMc", "item at index " + index + " in anonymous_appstore_purchase", value));
    });
  }
  return messages;
}

function assertAnonymousAppstorePurchaseOptional(
  anonymousAppstorePurchase: AnonymousAppstorePurchase[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (anonymousAppstorePurchase !== undefined && anonymousAppstorePurchase !== null) {
    messages = messages.concat(AvoAssert.assertList("0lAO_cnMc", "anonymous_appstore_purchase", anonymousAppstorePurchase));
    anonymousAppstorePurchase.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("0lAO_cnMc", "item at index " + index + " in anonymous_appstore_purchase", value));
    });
  }
  return messages;
}

function assertPrice(price: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("1LB32tv7C6k", _label ? "price" + ': ' + _label : "price", price));
  messages = messages.concat(AvoAssert.assertMin("1LB32tv7C6k", _label ? "price" + ": " + _label : "price", 0.000000, price));
  messages = messages.concat(AvoAssert.assertMax("1LB32tv7C6k", _label ? "price" + ": " + _label : "price", 9999999.000000, price));
  return messages;
}

function assertListPriceOptional(listPrice: number | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (listPrice !== undefined && listPrice !== null) {
    messages = messages.concat(AvoAssert.assertFloat("1XP6fuJrNmh", _label ? "list_price" + ': ' + _label : "list_price", listPrice));
    messages = messages.concat(AvoAssert.assertMin("1XP6fuJrNmh", _label ? "list_price" + ": " + _label : "list_price", 0.000000, listPrice));
    messages = messages.concat(AvoAssert.assertMax("1XP6fuJrNmh", _label ? "list_price" + ": " + _label : "list_price", 9999999.000000, listPrice));
  }
  return messages;
}

function assertModulePosition(modulePosition: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("5Gm1HIJld", _label ? "module_position" + ': ' + _label : "module_position", modulePosition));
  messages = messages.concat(AvoAssert.assertMin("5Gm1HIJld", _label ? "module_position" + ": " + _label : "module_position", -1, modulePosition));
  messages = messages.concat(AvoAssert.assertMax("5Gm1HIJld", _label ? "module_position" + ": " + _label : "module_position", 100, modulePosition));
  return messages;
}

function assertCheckoutEntity(checkoutEntity: CheckoutEntity, _label?: string
  ) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("5RTXMuQzx", _label ? "checkout_entity" + ': ' + _label : "checkout_entity", checkoutEntity));
  if (checkoutEntity !== null && checkoutEntity !== undefined) {
  }
  return messages;
}

function assertPositionOptional(position: number | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (position !== undefined && position !== null) {
    messages = messages.concat(AvoAssert.assertInt("5vFzALMVnZu", _label ? "position" + ': ' + _label : "position", position));
    messages = messages.concat(AvoAssert.assertMin("5vFzALMVnZu", _label ? "position" + ": " + _label : "position", 0, position));
    messages = messages.concat(AvoAssert.assertMax("5vFzALMVnZu", _label ? "position" + ": " + _label : "position", 9999999, position));
  }
  return messages;
}

function assertModuleEntity(moduleEntity: ModuleEntity, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("HCubDtiGz", _label ? "module_entity" + ': ' + _label : "module_entity", moduleEntity));
  if (moduleEntity !== null && moduleEntity !== undefined) {
    messages = messages.concat(assertModulePosition(moduleEntity.modulePosition, _label));
  }
  return messages;
}

function assertPositionInPage(positionInPage: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("MNXuX0729", _label ? "position_in_page" + ': ' + _label : "position_in_page", positionInPage));
  messages = messages.concat(AvoAssert.assertMax("MNXuX0729", _label ? "position_in_page" + ": " + _label : "position_in_page", 100, positionInPage));
  messages = messages.concat(AvoAssert.assertMin("MNXuX0729", _label ? "position_in_page" + ": " + _label : "position_in_page", -1, positionInPage));
  return messages;
}

function assertPositionInPageOptional(
  positionInPage: number | null | undefined, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (positionInPage !== undefined && positionInPage !== null) {
    messages = messages.concat(AvoAssert.assertInt("MNXuX0729", _label ? "position_in_page" + ': ' + _label : "position_in_page", positionInPage));
    messages = messages.concat(AvoAssert.assertMax("MNXuX0729", _label ? "position_in_page" + ": " + _label : "position_in_page", 100, positionInPage));
    messages = messages.concat(AvoAssert.assertMin("MNXuX0729", _label ? "position_in_page" + ": " + _label : "position_in_page", -1, positionInPage));
  }
  return messages;
}

function assertUserEntity(userEntity: UserEntity, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("NJHJ_JtxZY", _label ? "user_entity" + ': ' + _label : "user_entity", userEntity));
  if (userEntity !== null && userEntity !== undefined) {
    messages = messages.concat(assertAccessRightsOptional(userEntity.accessRights, _label));
    messages = messages.concat(assertAnonymousAppstorePurchaseOptional(userEntity.anonymousAppstorePurchase, _label));
    messages = messages.concat(assertProductsOptional(userEntity.products, _label));
  }
  return messages;
}

function assertPositionInModule(positionInModule: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("PAOQXBbVJ", _label ? "position_in_module" + ': ' + _label : "position_in_module", positionInModule));
  messages = messages.concat(AvoAssert.assertMax("PAOQXBbVJ", _label ? "position_in_module" + ": " + _label : "position_in_module", 100, positionInModule));
  messages = messages.concat(AvoAssert.assertMin("PAOQXBbVJ", _label ? "position_in_module" + ": " + _label : "position_in_module", -1, positionInModule));
  return messages;
}

function assertPositionInModuleOptional(
  positionInModule: number | null | undefined, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (positionInModule !== undefined && positionInModule !== null) {
    messages = messages.concat(AvoAssert.assertInt("PAOQXBbVJ", _label ? "position_in_module" + ': ' + _label : "position_in_module", positionInModule));
    messages = messages.concat(AvoAssert.assertMax("PAOQXBbVJ", _label ? "position_in_module" + ": " + _label : "position_in_module", 100, positionInModule));
    messages = messages.concat(AvoAssert.assertMin("PAOQXBbVJ", _label ? "position_in_module" + ": " + _label : "position_in_module", -1, positionInModule));
  }
  return messages;
}

function assertPromotion(promotion: Promotion, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("PAWMTeyY1Z", _label ? "promotion" + ': ' + _label : "promotion", promotion));
  if (promotion !== null && promotion !== undefined) {
    messages = messages.concat(assertPositionOptional(promotion.position, _label));
  }
  return messages;
}

function assertStep(step: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("TkvzSf1GYXJ", _label ? "step" + ': ' + _label : "step", step));
  messages = messages.concat(AvoAssert.assertMin("TkvzSf1GYXJ", _label ? "step" + ": " + _label : "step", 1, step));
  messages = messages.concat(AvoAssert.assertMax("TkvzSf1GYXJ", _label ? "step" + ": " + _label : "step", 90, step));
  return messages;
}

function assertTransactionError(transactionError: TransactionError,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("VTcgijj3Wy", _label ? "transaction_error" + ': ' + _label : "transaction_error", transactionError));
  if (transactionError !== null && transactionError !== undefined) {
  }
  return messages;
}

function assertTransaction(transaction: Transaction, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("b8s9z7a-Y", _label ? "transaction" + ': ' + _label : "transaction", transaction));
  if (transaction !== null && transaction !== undefined) {
    messages = messages.concat(assertRevenue(transaction.revenue, _label));
  }
  return messages;
}

function assertAccessRights(accessRights: AccessRights[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("crP8FKb7z", "access_rights", accessRights));
  if (accessRights !== null && accessRights !== undefined && accessRights.forEach) {
    accessRights.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("crP8FKb7z", "item at index " + index + " in access_rights", value));
    });
  }
  return messages;
}

function assertAccessRightsOptional(
  accessRights: AccessRights[] | null | undefined, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (accessRights !== undefined && accessRights !== null) {
    messages = messages.concat(AvoAssert.assertList("crP8FKb7z", "access_rights", accessRights));
    accessRights.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("crP8FKb7z", "item at index " + index + " in access_rights", value));
    });
  }
  return messages;
}

function assertModuleMarketingItemEntityOptional(
  moduleMarketingItemEntity: ModuleMarketingItemEntity[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (moduleMarketingItemEntity !== undefined && moduleMarketingItemEntity !== null) {
    messages = messages.concat(AvoAssert.assertList("foeNylSmD", "module_marketing_item_entity", moduleMarketingItemEntity));
    moduleMarketingItemEntity.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("foeNylSmD", "item at index " + index + " in module_marketing_item_entity", value));
      messages = messages.concat(assertPositionInModuleOptional(value.positionInModule, "item at index " + index + " in module_marketing_item_entity"));
    });
  }
  return messages;
}

function assertCart(cart: Cart, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("i63dQ2U1EG", _label ? "cart" + ': ' + _label : "cart", cart));
  if (cart !== null && cart !== undefined) {
    messages = messages.concat(assertTotalValue(cart.totalValue, _label));
  }
  return messages;
}

function assertProduct(product: Product[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("iYU2x5SoNC", "product", product));
  if (product !== null && product !== undefined && product.forEach) {
    product.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("iYU2x5SoNC", "item at index " + index + " in product", value));
      messages = messages.concat(assertPrice(value.price, "item at index " + index + " in product"));
      messages = messages.concat(assertListPriceOptional(value.listPrice, "item at index " + index + " in product"));
      messages = messages.concat(assertQuantityOptional(value.quantity, "item at index " + index + " in product"));
      messages = messages.concat(assertPositionOptional(value.position, "item at index " + index + " in product"));
    });
  }
  return messages;
}

function assertProducts(products: Products[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("maesmUUfp", "products", products));
  if (products !== null && products !== undefined && products.forEach) {
    products.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("maesmUUfp", "item at index " + index + " in products", value));
    });
  }
  return messages;
}

function assertProductsOptional(products: Products[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (products !== undefined && products !== null) {
    messages = messages.concat(AvoAssert.assertList("maesmUUfp", "products", products));
    products.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("maesmUUfp", "item at index " + index + " in products", value));
    });
  }
  return messages;
}

function assertQuantityOptional(quantity: number | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (quantity !== undefined && quantity !== null) {
    messages = messages.concat(AvoAssert.assertInt("ml1k3jCygFF", _label ? "quantity" + ': ' + _label : "quantity", quantity));
    messages = messages.concat(AvoAssert.assertMin("ml1k3jCygFF", _label ? "quantity" + ": " + _label : "quantity", 0, quantity));
    messages = messages.concat(AvoAssert.assertMax("ml1k3jCygFF", _label ? "quantity" + ": " + _label : "quantity", 9999999, quantity));
  }
  return messages;
}

function assertEpaperEntity(epaperEntity: EpaperEntity, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("nUxZpBEsGe", _label ? "epaper_entity" + ': ' + _label : "epaper_entity", epaperEntity));
  if (epaperEntity !== null && epaperEntity !== undefined) {
  }
  return messages;
}

function assertTotalValue(totalValue: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("ngHR4d5_3FK", _label ? "total_value" + ': ' + _label : "total_value", totalValue));
  messages = messages.concat(AvoAssert.assertMin("ngHR4d5_3FK", _label ? "total_value" + ": " + _label : "total_value", 0.000000, totalValue));
  messages = messages.concat(AvoAssert.assertMax("ngHR4d5_3FK", _label ? "total_value" + ": " + _label : "total_value", 9999999.000000, totalValue));
  return messages;
}

function assertCheckoutStep(checkoutStep: CheckoutStep, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("pG67MKKD7x", _label ? "checkout_step" + ': ' + _label : "checkout_step", checkoutStep));
  if (checkoutStep !== null && checkoutStep !== undefined) {
    messages = messages.concat(assertStep(checkoutStep.step, _label));
  }
  return messages;
}

function assertContentEntity(contentEntity: ContentEntity, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("rfyvtI1ceG", _label ? "content_entity" + ': ' + _label : "content_entity", contentEntity));
  if (contentEntity !== null && contentEntity !== undefined) {
  }
  return messages;
}

function assertRevenue(revenue: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("tHqM9oPh2uY", _label ? "revenue" + ': ' + _label : "revenue", revenue));
  messages = messages.concat(AvoAssert.assertMin("tHqM9oPh2uY", _label ? "revenue" + ": " + _label : "revenue", 0.000000, revenue));
  messages = messages.concat(AvoAssert.assertMax("tHqM9oPh2uY", _label ? "revenue" + ": " + _label : "revenue", 9999999.000000, revenue));
  return messages;
}

function assertModuleNewsletterItemEntityOptional(
  moduleNewsletterItemEntity: ModuleNewsletterItemEntity[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (moduleNewsletterItemEntity !== undefined && moduleNewsletterItemEntity !== null) {
    messages = messages.concat(AvoAssert.assertList("wlEEfM5ur", "module_newsletter_item_entity", moduleNewsletterItemEntity));
    moduleNewsletterItemEntity.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("wlEEfM5ur", "item at index " + index + " in module_newsletter_item_entity", value));
      messages = messages.concat(assertPositionInModuleOptional(value.positionInModule, "item at index " + index + " in module_newsletter_item_entity"));
    });
  }
  return messages;
}

function assertEnvironmentEntity(environmentEntity: EnvironmentEntity,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertObject("zJ31YiiUkb", _label ? "environment_entity" + ': ' + _label : "environment_entity", environmentEntity));
  if (environmentEntity !== null && environmentEntity !== undefined) {
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface ModuleEngagedProperties {
  action: ActionValueType;
  moduleEntity: ModuleEntity;
  moduleContentItemEntity: ModuleContentItemEntity[] | null | undefined;
  moduleNewsletterItemEntity: ModuleNewsletterItemEntity[] | null | undefined;
  moduleMarketingItemEntity: ModuleMarketingItemEntity[] | null | undefined;
}
/**
 * Module engaged: When a module is in the viewport of the user or module items are clicked
 *
 * When to trigger this event:
 * 1. Content:
﻿When a content module appears in the viewport.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/f-YQJ5gTP
 * 2. Content:
﻿When a content module item is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/b9X0DgAKY
 * 3. Marketing:
When our bluum widget is in the viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/Hv9I2fXqI
 * 4. Marketing:
﻿When our service module is in the viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/_0RtZmGsP
 * 5. Marketing:
﻿When an item in the service module is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/3B7Um8vzI
 * 6. Newsletter:
﻿Newsletter module is in viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/RqMrND7x8
 * 7. Newsletter:
﻿Newsletter signup button is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/1Ov7GdVle
 * 8. Marketing:
When an item of the bluum widget is clicked.

If this is even possible to detect...
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp/trigger/PuKxt7kaY
 *
 * @param properties the properties associatied with this event
 * @param properties.action: The action type
 * @param properties.moduleEntity: Entity for describing the module the user engaged with
 * @param properties.moduleContentItemEntity: Entity for describing content items
 * @param properties.moduleNewsletterItemEntity: Entity for describing newsletter items
 * @param properties.moduleMarketingItemEntity: Entity for describing marketing items inside a module
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/8-Bk6KfMTp}
 */
export function moduleEngaged(properties: ModuleEngagedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "aK1YDgAdu", name: "action", value: properties.action});
  eventPropertiesArray.push({id: "HCubDtiGz", name: "module_entity", value: {
    "zone": properties.moduleEntity.zone,
    "title_visible": properties.moduleEntity.titleVisible,
    "title_internal": properties.moduleEntity.titleInternal,
    "module_position": properties.moduleEntity.modulePosition,
    "module_type": properties.moduleEntity.moduleType,
    "content_origin": properties.moduleEntity.contentOrigin,
    "schema": "iglu:de.npg/module_entity/jsonschema/1-0-1",
  }});
  properties.moduleContentItemEntity !== undefined && properties.moduleContentItemEntity !== null ?
    eventPropertiesArray.push({id: "-KeKi2kID", name: "module_content_item_entity", value: properties.moduleContentItemEntity.map(function(value) { return {
        "container_id": value.containerId,
        "has_teaser": value.hasTeaser,
        "has_image": value.hasImage,
        "position_in_module": value.positionInModule,
        "article_id": value.articleId,
        "position_in_page": value.positionInPage,
        "correlation_id": value.correlationId,
        "is_plus": value.isPlus,
        "schema": "iglu:de.npg/module_content_item_entity/jsonschema/1-0-1",
      }
    })}) :
    eventPropertiesArray.push({id: "-KeKi2kID", name: "module_content_item_entity", value: null});
  properties.moduleNewsletterItemEntity !== undefined && properties.moduleNewsletterItemEntity !== null ?
    eventPropertiesArray.push({id: "wlEEfM5ur", name: "module_newsletter_item_entity", value: properties.moduleNewsletterItemEntity.map(function(value) { return {
        "schema": "iglu:de.npg/module_newsletter_item_entity/jsonschema/1-0-0",
        "newsletter": value.newsletter,
        "position_in_module": value.positionInModule,
        "state": value.state,
      }
    })}) :
    eventPropertiesArray.push({id: "wlEEfM5ur", name: "module_newsletter_item_entity", value: null});
  properties.moduleMarketingItemEntity !== undefined && properties.moduleMarketingItemEntity !== null ?
    eventPropertiesArray.push({id: "foeNylSmD", name: "module_marketing_item_entity", value: properties.moduleMarketingItemEntity.map(function(value) { return {
        "position_in_module": value.positionInModule,
        "label": value.label,
        "category": value.elementCategory,
        "schema": "iglu:de.npg/module_marketing_item_entity/jsonschema/1-0-1",
      }
    })}) :
    eventPropertiesArray.push({id: "foeNylSmD", name: "module_marketing_item_entity", value: null});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/module_engaged/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertModuleEntity(properties.moduleEntity));
    messages = messages.concat(assertModuleContentItemEntityOptional(properties.moduleContentItemEntity));
    messages = messages.concat(assertModuleNewsletterItemEntityOptional(properties.moduleNewsletterItemEntity));
    messages = messages.concat(assertModuleMarketingItemEntityOptional(properties.moduleMarketingItemEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8-Bk6KfMTp", "57abe3f70bfb6cde1a140537e769f636e5406f8f44d5bd8a20c5aaa14725b0a1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Module engaged", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8-Bk6KfMTp", "Module engaged", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Module engaged': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Module engaged", eventProperties, "8-Bk6KfMTp", "57abe3f70bfb6cde1a140537e769f636e5406f8f44d5bd8a20c5aaa14725b0a1");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.moduleEntity !== undefined && properties.moduleEntity !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:de.npg/module_entity/jsonschema/1-0-1",
        "data": {
          "zone": properties.moduleEntity.zone,
          "title_visible": properties.moduleEntity.titleVisible,
          "title_internal": properties.moduleEntity.titleInternal,
          "module_position": properties.moduleEntity.modulePosition,
          "module_type": properties.moduleEntity.moduleType,
          "content_origin": properties.moduleEntity.contentOrigin,
        }
      });
    }
    if (properties.moduleContentItemEntity !== undefined && properties.moduleContentItemEntity !== null) {
      properties.moduleContentItemEntity.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:de.npg/module_content_item_entity/jsonschema/1-0-1",
          "data": {
            "container_id": value.containerId,
            "has_teaser": value.hasTeaser,
            "has_image": value.hasImage,
            "position_in_module": value.positionInModule,
            "article_id": value.articleId,
            "position_in_page": value.positionInPage,
            "correlation_id": value.correlationId,
            "is_plus": value.isPlus,
          }});
      });
    }
    if (properties.moduleNewsletterItemEntity !== undefined && properties.moduleNewsletterItemEntity !== null) {
      properties.moduleNewsletterItemEntity.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:de.npg/module_newsletter_item_entity/jsonschema/1-0-0",
          "data": {
            "newsletter": value.newsletter,
            "position_in_module": value.positionInModule,
            "state": value.state,
          }});
      });
    }
    if (properties.moduleMarketingItemEntity !== undefined && properties.moduleMarketingItemEntity !== null) {
      properties.moduleMarketingItemEntity.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:de.npg/module_marketing_item_entity/jsonschema/1-0-1",
          "data": {
            "position_in_module": value.positionInModule,
            "label": value.label,
            "category": value.elementCategory,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/module_engaged/jsonschema/1-0-0",
      "data": Object.assign({
        "action": properties.action,
        }, { "avo_event_name": "Module engaged" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/module_engaged/jsonschema/1-0-0",
      {
        "action": properties.action,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface PageViewedProperties {
  snowplowPageName_: string;
}
/**
 * Page viewed: Basic page view tracking
 *
 * When to trigger this event:
 * 1. On every page on DOM ready
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/mbIuPnnkfe/trigger/i9oA6pUn1
 *
 * @param properties the properties associatied with this event
 * @param properties.snowplowPageName_: Name of the page.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/mbIuPnnkfe}
 */
export function pageViewed(properties: PageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mbIuPnnkfe", "9b7f4e1466e3506106a460bb279b9d4d87540be6ba81ef3d64950c9d90992dfc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mbIuPnnkfe", "Page viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination SnowplowDriveTracker
    SnowplowDriveTracker && SnowplowDriveTracker.trackPageView && SnowplowDriveTracker.trackPageView(properties.snowplowPageName_);

    // destination SnowplowDWHTracker
    SnowplowDWHTracker && SnowplowDWHTracker.trackPageView && SnowplowDWHTracker.trackPageView(properties.snowplowPageName_);
  } else {
    // do nothing
  }
}

export interface UserLoggedInProperties {
  userId_: string;
}
/**
 * User logged in: This event is sent, when a user logs in / is logged in and has a Plenigo id.
 *
 * When to trigger this event:
 * 1. When a user logs in
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/HN25KKZAkT/trigger/17BJm_rYN
 * 2. When a registration is completed in the beginning of our checkout process
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/HN25KKZAkT/trigger/Svd82zIsi
 * 3. On every page view when the user is logged in
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/HN25KKZAkT/trigger/qZRUtWrct
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/HN25KKZAkT}
 */
export function userLoggedIn(properties: UserLoggedInProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/user_identified/jsonschema/1-0-0"});
  eventPropertiesArray.push({id: "ijJkkkxVV", name: "action", value: "login"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "HN25KKZAkT", "b8b69bce6277ef3f4953ae8743cd8baf3caf98f1d089c739b8ed3e00ab60fc5b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("User logged in", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("HN25KKZAkT", "User logged in", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User logged in", eventProperties, "HN25KKZAkT", "b8b69bce6277ef3f4953ae8743cd8baf3caf98f1d089c739b8ed3e00ab60fc5b");
    }
    // destination SnowplowDriveTracker
    SnowplowDriveTracker.identify(properties.userId_);
    let SnowplowDriveTrackerContext: any = [];
    SnowplowDriveTracker && SnowplowDriveTracker.logEvent && SnowplowDriveTracker.logEvent({"shema": "iglu:de.npg/user_identified/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "login",
        }, { "avo_event_name": "User logged in" })
      }
    , SnowplowDriveTrackerContext);
    SnowplowDriveTracker && SnowplowDriveTracker.trackSelfDescribingEvent("iglu:de.npg/user_identified/jsonschema/1-0-0",
      {
        "action": "login",
        },
    SnowplowDriveTrackerContext);

    // destination SnowplowDWHTracker
    SnowplowDWHTracker.identify(properties.userId_);
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/user_identified/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "login",
        }, { "avo_event_name": "User logged in" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/user_identified/jsonschema/1-0-0",
      {
        "action": "login",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

/**
 * User logged out: This event is sent, when a user logs out.
 *
 * When to trigger this event:
 * 1. when a user logs out
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/zvzsrKI8ZT/trigger/9-iBP9dWi
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/zvzsrKI8ZT}
 */
export function userLoggedOut() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/user_identified/jsonschema/1-0-0"});
  eventPropertiesArray.push({id: "ijJkkkxVV", name: "action", value: "logout"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zvzsrKI8ZT", "75baa7f58f300349558b6d62841504d4fcb31b244d6cd3ad6e8c3e697f377662", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("User logged out", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zvzsrKI8ZT", "User logged out", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User logged out", eventProperties, "zvzsrKI8ZT", "75baa7f58f300349558b6d62841504d4fcb31b244d6cd3ad6e8c3e697f377662");
    }
    // destination SnowplowDriveTracker
    let SnowplowDriveTrackerContext: any = [];
    SnowplowDriveTracker && SnowplowDriveTracker.logEvent && SnowplowDriveTracker.logEvent({"shema": "iglu:de.npg/user_identified/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "logout",
        }, { "avo_event_name": "User logged out" })
      }
    , SnowplowDriveTrackerContext);
    SnowplowDriveTracker && SnowplowDriveTracker.trackSelfDescribingEvent("iglu:de.npg/user_identified/jsonschema/1-0-0",
      {
        "action": "logout",
        },
    SnowplowDriveTrackerContext);
    SnowplowDriveTracker.unidentify();

    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/user_identified/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "logout",
        }, { "avo_event_name": "User logged out" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/user_identified/jsonschema/1-0-0",
      {
        "action": "logout",
        },
    SnowplowDWHTrackerContext);
    SnowplowDWHTracker.unidentify();
  } else {
    // do nothing
  }
}

export interface NavigationItemClickedProperties {
  label: string;
  zone: ZoneValueType | null | undefined;
  typeNavigation: TypeNavigationValueType;
}
/**
 * Navigation item clicked: This event is sent, when a user clicks on items in the navigation.
 *
 * When to trigger this event:
 * 1. Triggered, when any item in any navigation is clicked.
This includes the quick nav bar as well
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ZZ-qiOQXJg/trigger/SgPpxsgQf
 * 2. When the a retresco entity in an article is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ZZ-qiOQXJg/trigger/qhgDPLFQi
 * 3. When an item in the footer navigation is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ZZ-qiOQXJg/trigger/O4tMLgnVJ
 *
 * @param properties the properties associatied with this event
 * @param properties.label: The label of the item
 * @param properties.zone: Describes the general position of the module
 * @param properties.typeNavigation: Indicates the type of navigation, the clicked item belongs to.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ZZ-qiOQXJg}
 */
export function navigationItemClicked(
  properties: NavigationItemClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "_kCEubpUt", name: "label", value: properties.label});
  properties.zone !== undefined && properties.zone !== null ?
    eventPropertiesArray.push({id: "vihj61jvd", name: "zone", value: properties.zone}) :
    eventPropertiesArray.push({id: "vihj61jvd", name: "zone", value: null});
  eventPropertiesArray.push({id: "BDOYAhiFmY", name: "type", value: properties.typeNavigation});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/navigation_item_clicked/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ZZ-qiOQXJg", "7c48d5397182c90bcb07e4c75c49fd26ec4fe2f9e03888145bff37970e386c06", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Navigation item clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ZZ-qiOQXJg", "Navigation item clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Navigation item clicked", eventProperties, "ZZ-qiOQXJg", "7c48d5397182c90bcb07e4c75c49fd26ec4fe2f9e03888145bff37970e386c06");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/navigation_item_clicked/jsonschema/1-0-0",
      "data": Object.assign({
        "label": properties.label,
        "zone": properties.zone,
        "type": properties.typeNavigation,
        }, { "avo_event_name": "Navigation item clicked" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/navigation_item_clicked/jsonschema/1-0-0",
      {
        "label": properties.label,
        "zone": properties.zone,
        "type": properties.typeNavigation,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

/**
 * Article read: This event is sent, when a user finished reading an article.
 *
 * When to trigger this event:
 * 1. Triggers when 80% of an article are read.
Can't trigger when the paywall is shown.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/sAI9kfUdyM/trigger/WJnER1ORG
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/sAI9kfUdyM}
 */
export function articleRead() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/article_read/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sAI9kfUdyM", "b15bace083c78dad33b665cab8db6a8812cf87e12780ecaf21e78e11a0725af7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Article read", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sAI9kfUdyM", "Article read", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Article read", eventProperties, "sAI9kfUdyM", "b15bace083c78dad33b665cab8db6a8812cf87e12780ecaf21e78e11a0725af7");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/article_read/jsonschema/1-0-0",
      "data": Object.assign({}, { "avo_event_name": "Article read" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/article_read/jsonschema/1-0-0",
      {},
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

/**
 * Paywall viewed: This event fires, when the Paywall is seen by a user.
 *
 * When to trigger this event:
 * 1. Triggered, when the paywall comes into the viewport.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Z3YS-_X8W2/trigger/E3-T-JrfG
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Z3YS-_X8W2}
 */
export function paywallViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/paywall_viewed/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Z3YS-_X8W2", "b7b6ab13d9d29070e74440eec1fbe9231886da172a5ae8fec111f4391543c6eb", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Paywall viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Z3YS-_X8W2", "Paywall viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Paywall viewed", eventProperties, "Z3YS-_X8W2", "b7b6ab13d9d29070e74440eec1fbe9231886da172a5ae8fec111f4391543c6eb");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/paywall_viewed/jsonschema/1-0-0",
      "data": Object.assign({}, { "avo_event_name": "Paywall viewed" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/paywall_viewed/jsonschema/1-0-0",
      {},
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductAddedToCartProperties {
  cart: Cart;
  product: Product[];
  name: string | null | undefined;
}
/**
 * Product added to cart: This event is sent, when a product gets added to the cart. We don't really have a cart, so this should fire when a user clicks on an offer and lands on the first checkout page.
 *
 * When to trigger this event:
 * 1. When a product is added to the cart.
This could be a paywall click.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/YFsKnTMXOrj/trigger/4m9D-Cl_F
 * 2. When the user clicks on the downgrade option to a monthly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/YFsKnTMXOrj/trigger/dPPmRaAPH
 * 3. When the user clicks on the upgrade option to a monthly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/YFsKnTMXOrj/trigger/LXoRDGUsK
 *
 * @param properties the properties associatied with this event
 * @param properties.cart: Schema for a cart entity in Ecommerce
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.name: The name or title of the product.
The name of the promotion.
The name of the list presented to the user E.g. product list, search results, shop the look, frequently bought with.

 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/YFsKnTMXOrj}
 */
export function productAddedToCart(properties: ProductAddedToCartProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "i63dQ2U1EG", name: "cart", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/cart/jsonschema/1-0-0",
    "cart_id": properties.cart.cartId,
    "total_value": properties.cart.totalValue,
    "currency": properties.cart.currency,
  }});
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  properties.name !== undefined && properties.name !== null ?
    eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: properties.name}) :
    eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: null});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "add_to_cart"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertCart(properties.cart));
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "YFsKnTMXOrj", "4332aa864c429a3dc5353cd8141b08a3afbbd055b4099a30ddd81e2dbfbb1aec", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product added to cart", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("YFsKnTMXOrj", "Product added to cart", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product added to cart': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product added to cart", eventProperties, "YFsKnTMXOrj", "4332aa864c429a3dc5353cd8141b08a3afbbd055b4099a30ddd81e2dbfbb1aec");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.cart !== undefined && properties.cart !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/cart/jsonschema/1-0-0",
        "data": {
          "cart_id": properties.cart.cartId,
          "total_value": properties.cart.totalValue,
          "currency": properties.cart.currency,
        }
      });
    }
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "add_to_cart",
        "name": properties.name,
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "add_to_cart",
        "name": properties.name,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductRemovedFromCartProperties {
  cart: Cart;
  product: Product[];
  name: string | null | undefined;
}
/**
 * Product removed from cart: This event is sent, when a product gets removed from the cart. E.g. Upsell or downsell in the checkout process from Probeabo to Jahresabo.
 *
 * When to trigger this event:
 * 1. When a product is removed from the cart.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/S8hAf3H_W/trigger/VIUopdjAeL
 * 2. When the user clicks on the downgrade option to a monthly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/S8hAf3H_W/trigger/I35WcGuh5
 * 3. When the user clicks on the upgrade option to a monthly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/S8hAf3H_W/trigger/1mU6j5M_f
 *
 * @param properties the properties associatied with this event
 * @param properties.cart: Schema for a cart entity in Ecommerce
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.name: The name or title of the product.
The name of the promotion.
The name of the list presented to the user E.g. product list, search results, shop the look, frequently bought with.

 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/S8hAf3H_W}
 */
export function productRemovedFromCart(
  properties: ProductRemovedFromCartProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "i63dQ2U1EG", name: "cart", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/cart/jsonschema/1-0-0",
    "cart_id": properties.cart.cartId,
    "total_value": properties.cart.totalValue,
    "currency": properties.cart.currency,
  }});
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  properties.name !== undefined && properties.name !== null ?
    eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: properties.name}) :
    eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: null});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "remove_from_cart"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertCart(properties.cart));
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "S8hAf3H_W", "8a4a7c9b79961b0962e618e7e91a2cd2af6e3d82cec5eee7fe160ca8fd933c37", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product removed from cart", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("S8hAf3H_W", "Product removed from cart", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product removed from cart': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product removed from cart", eventProperties, "S8hAf3H_W", "8a4a7c9b79961b0962e618e7e91a2cd2af6e3d82cec5eee7fe160ca8fd933c37");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.cart !== undefined && properties.cart !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/cart/jsonschema/1-0-0",
        "data": {
          "cart_id": properties.cart.cartId,
          "total_value": properties.cart.totalValue,
          "currency": properties.cart.currency,
        }
      });
    }
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "remove_from_cart",
        "name": properties.name,
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "remove_from_cart",
        "name": properties.name,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface OrderCompletedProperties {
  product: Product[];
  transaction: Transaction;
}
/**
 * Order completed: This event is sent, when a order transaction is completed.
 *
 * When to trigger this event:
 * 1. Triggers once, when Plenigo sends the purchaseComplete  event on the Thank You page.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/WL8yOd2Cn/trigger/M8InJu_UR
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.transaction: Schema for a transaction entity in Ecommerce
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/WL8yOd2Cn}
 */
export function orderCompleted(properties: OrderCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "b8s9z7a-Y", name: "transaction", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/transaction/jsonschema/1-0-0",
    "transaction_id": properties.transaction.transactionId,
    "revenue": properties.transaction.revenue,
    "currency": properties.transaction.currency,
    "total_quantity": properties.transaction.totalQuantity,
    "tax": properties.transaction.tax,
    "shipping": properties.transaction.shipping,
    "discount_code": properties.transaction.discountCode,
    "discount_amount": properties.transaction.discountAmount,
    "credit_order": properties.transaction.creditOrder,
    "payment_method": properties.transaction.paymentMethod,
  }});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "transaction"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    messages = messages.concat(assertTransaction(properties.transaction));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WL8yOd2Cn", "e79031203e8455435deb33455f288b14075d131f43469b4a4893b5179f6bcaaa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Order completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WL8yOd2Cn", "Order completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Order completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order completed", eventProperties, "WL8yOd2Cn", "e79031203e8455435deb33455f288b14075d131f43469b4a4893b5179f6bcaaa");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    if (properties.transaction !== undefined && properties.transaction !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/transaction/jsonschema/1-0-0",
        "data": {
          "transaction_id": properties.transaction.transactionId,
          "revenue": properties.transaction.revenue,
          "currency": properties.transaction.currency,
          "total_quantity": properties.transaction.totalQuantity,
          "tax": properties.transaction.tax,
          "shipping": properties.transaction.shipping,
          "discount_code": properties.transaction.discountCode,
          "discount_amount": properties.transaction.discountAmount,
          "credit_order": properties.transaction.creditOrder,
          "payment_method": properties.transaction.paymentMethod,
        }
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "transaction",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "transaction",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface OrderFailedProperties {
  product: Product[];
  transactionError: TransactionError;
}
/**
 * Order failed: This event is sent, when a order transaction fails because of an error
 *
 * When to trigger this event:
 * 1. Triggers once, when a order fails or is rejected by Plenigo
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/vOYmyxEBp/trigger/LQYiSUBote
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.transactionError: Schema for a transaction error or rejection entity in ecommerce.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/vOYmyxEBp}
 */
export function orderFailed(properties: OrderFailedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "VTcgijj3Wy", name: "transaction_error", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/transaction_error/jsonschema/1-0-0",
    "error_code": properties.transactionError.errorCode,
    "error_shortcode": properties.transactionError.errorShortcode,
    "error_description": properties.transactionError.errorDescription,
    "error_type": properties.transactionError.errorType,
    "resolution": properties.transactionError.resolution,
  }});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "trns_error"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    messages = messages.concat(assertTransactionError(properties.transactionError));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vOYmyxEBp", "dd79ad19d80dfe9f744cb5f2a02445ca9090c001409aad3af9688ac44139b850", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Order failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vOYmyxEBp", "Order failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Order failed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order failed", eventProperties, "vOYmyxEBp", "dd79ad19d80dfe9f744cb5f2a02445ca9090c001409aad3af9688ac44139b850");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    if (properties.transactionError !== undefined && properties.transactionError !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/transaction_error/jsonschema/1-0-0",
        "data": {
          "error_code": properties.transactionError.errorCode,
          "error_shortcode": properties.transactionError.errorShortcode,
          "error_description": properties.transactionError.errorDescription,
          "error_type": properties.transactionError.errorType,
          "resolution": properties.transactionError.resolution,
        }
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "trns_error",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "trns_error",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface PromotionViewedProperties {
  promotion: Promotion;
  product: Product[];
}
/**
 * Promotion viewed: This event is sent, when a promotion is viewed. Send all products and promotions that are visible to the users.
 *
 * When to trigger this event:
 * 1. When the paywall with offers is in the viewport.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/A70tG2Dtk/trigger/pu7p8dV1ub
 * 2. When the plus offers are in the viewport on the /plus page
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/A70tG2Dtk/trigger/JjC2pQ1CJ
 *
 * @param properties the properties associatied with this event
 * @param properties.promotion: Schema for a promotion entity in Ecommerce
 * @param properties.product: Schema for a product entity in Ecommerce
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/A70tG2Dtk}
 */
export function promotionViewed(properties: PromotionViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "PAWMTeyY1Z", name: "promotion", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/promotion/jsonschema/1-0-0",
    "id": properties.promotion.id,
    "name": properties.promotion.name,
    "product_ids": properties.promotion.productIds,
    "creative_id": properties.promotion.creativeId,
    "type": properties.promotion.type,
    "slot": properties.promotion.slot,
    "position": properties.promotion.position,
  }});
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "promo_view"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPromotion(properties.promotion));
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "A70tG2Dtk", "fe06b5604f5ba1ee237b5eddccf6cc8f67158e70ba2de669ef6ef4fdfa47c275", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("A70tG2Dtk", "Promotion viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion viewed", eventProperties, "A70tG2Dtk", "fe06b5604f5ba1ee237b5eddccf6cc8f67158e70ba2de669ef6ef4fdfa47c275");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.promotion !== undefined && properties.promotion !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/promotion/jsonschema/1-0-0",
        "data": {
          "id": properties.promotion.id,
          "name": properties.promotion.name,
          "product_ids": properties.promotion.productIds,
          "creative_id": properties.promotion.creativeId,
          "type": properties.promotion.type,
          "slot": properties.promotion.slot,
          "position": properties.promotion.position,
        }
      });
    }
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "promo_view",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "promo_view",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface PromotionClickedProperties {
  promotion: Promotion;
  product: Product[];
}
/**
 * Promotion clicked: This event is sent, when a promotion is clicked. Only send the properties of the specific product that was clicked.
 *
 * When to trigger this event:
 * 1. Similiar to `productListClicked`: When one of the offers is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Z93u7fxtk/trigger/cnHVBmqIHS
 * 2. When a product on the /plus page is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Z93u7fxtk/trigger/2EWIJEsll
 *
 * @param properties the properties associatied with this event
 * @param properties.promotion: Schema for a promotion entity in Ecommerce
 * @param properties.product: Schema for a product entity in Ecommerce
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Z93u7fxtk}
 */
export function promotionClicked(properties: PromotionClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "PAWMTeyY1Z", name: "promotion", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/promotion/jsonschema/1-0-0",
    "id": properties.promotion.id,
    "name": properties.promotion.name,
    "product_ids": properties.promotion.productIds,
    "creative_id": properties.promotion.creativeId,
    "type": properties.promotion.type,
    "slot": properties.promotion.slot,
    "position": properties.promotion.position,
  }});
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "promo_click"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPromotion(properties.promotion));
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Z93u7fxtk", "2b4a187dbcfcf0e082fe1ba454bfab267b232843a93e7e61738c0330222b7f7d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Z93u7fxtk", "Promotion clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion clicked", eventProperties, "Z93u7fxtk", "2b4a187dbcfcf0e082fe1ba454bfab267b232843a93e7e61738c0330222b7f7d");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.promotion !== undefined && properties.promotion !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/promotion/jsonschema/1-0-0",
        "data": {
          "id": properties.promotion.id,
          "name": properties.promotion.name,
          "product_ids": properties.promotion.productIds,
          "creative_id": properties.promotion.creativeId,
          "type": properties.promotion.type,
          "slot": properties.promotion.slot,
          "position": properties.promotion.position,
        }
      });
    }
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "promo_click",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "promo_click",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductViewedProperties {
  product: Product[];
}
/**
 * Product viewed: This event is sent, when a single product is viewed. Right now we only have this in our Aboshop.
 *
 * When to trigger this event:
 * 1. When a single product page is viewed
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ze1rFf0aD/trigger/Pp00oNx-2
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ze1rFf0aD}
 */
export function productViewed(properties: ProductViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "product_view"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ze1rFf0aD", "3f261666b313c51de42fd9b430f2d503b28e6e60cdd54a36504f4dd5517438e9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ze1rFf0aD", "Product viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product viewed", eventProperties, "ze1rFf0aD", "3f261666b313c51de42fd9b430f2d503b28e6e60cdd54a36504f4dd5517438e9");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "product_view",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "product_view",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductListViewedProperties {
  product: Product[];
  name: string;
}
/**
 * Product list viewed: This event is sent, when a list of products is viewed and contains all products that are in that list.
 *
 * When to trigger this event:
 * 1. When a list of products is in the viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/4fEjBDOqW/trigger/qbPbFIHU00
 * 2. When the plus offers are in the viewport on the /plus page
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/4fEjBDOqW/trigger/6kuvmz9ln
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.name: The name or title of the product.
The name of the promotion.
The name of the list presented to the user E.g. product list, search results, shop the look, frequently bought with.

 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/4fEjBDOqW}
 */
export function productListViewed(properties: ProductListViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "list_view"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4fEjBDOqW", "fed15bd3b03f56436d36e9e866ce03ea8a9a9e9093371221e822eeb8a75e1149", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product list viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4fEjBDOqW", "Product list viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product list viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product list viewed", eventProperties, "4fEjBDOqW", "fed15bd3b03f56436d36e9e866ce03ea8a9a9e9093371221e822eeb8a75e1149");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "list_view",
        "name": properties.name,
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "list_view",
        "name": properties.name,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductClickedProperties {
  product: Product[];
  name: string;
}
/**
 * Product clicked: This event is sent, when a product is clicked.
 *
 * When to trigger this event:
 * 1. When a product in a list of products (Paywall ...) is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/cCecBn98b/trigger/K05k4Xq6Hg
 * 2. When a product on the /plus page is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/cCecBn98b/trigger/0dk_4sOLq
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.name: The name or title of the product.
The name of the promotion.
The name of the list presented to the user E.g. product list, search results, shop the look, frequently bought with.

 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/cCecBn98b}
 */
export function productClicked(properties: ProductClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "hnBaN7tW67E", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "list_click"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cCecBn98b", "4c18df470c8bbd74ab38fc5ee711d3daf23387d85711279a849a7fa334afb088", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cCecBn98b", "Product clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product clicked", eventProperties, "cCecBn98b", "4c18df470c8bbd74ab38fc5ee711d3daf23387d85711279a849a7fa334afb088");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "list_click",
        "name": properties.name,
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "list_click",
        "name": properties.name,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ElementEngagedProperties {
  action: ActionValueType;
  label: string;
  elementCategory: string;
  elementType: ElementTypeValueType;
  zone: ZoneValueType;
  identifier: string;
}
/**
 * Element engaged: This event is sent, when specific ux elements are clicked or viewed
 *
 * When to trigger this event:
 * 1. Triggers when the Konto vervollständigen modal is displayed
elementCategory: registration
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ca9HcNf6uf/trigger/y_8nXkT_w
 * 2. Triggered, when the button is clicked
elementCategory: registration
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ca9HcNf6uf/trigger/FrOmLSLEm
 * 3. When the login link is clicked
elementCategory = "service"
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ca9HcNf6uf/trigger/AcjmPJ2Dn
 * 4. When the logout link is clicked
elementCategory = "service"
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ca9HcNf6uf/trigger/cURGyTfN3
 *
 * @param properties the properties associatied with this event
 * @param properties.action: The action type
 * @param properties.label: The label of the item
 * @param properties.elementCategory: The category of the clicked element
 * @param properties.elementType: The type of the element
 * @param properties.zone: Describes the general position of the module
 * @param properties.identifier: General identifier to tie viewed and clicked events together. Could be a css id for example.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/ca9HcNf6uf}
 */
export function elementEngaged(properties: ElementEngagedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "aK1YDgAdu", name: "action", value: properties.action});
  eventPropertiesArray.push({id: "_kCEubpUt", name: "label", value: properties.label});
  eventPropertiesArray.push({id: "EpEEsxkDJ", name: "category", value: properties.elementCategory});
  eventPropertiesArray.push({id: "Uig2SksPV", name: "type", value: properties.elementType});
  eventPropertiesArray.push({id: "vihj61jvd", name: "zone", value: properties.zone});
  eventPropertiesArray.push({id: "_P6urx9Wh", name: "identifier", value: properties.identifier});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/element_engaged/jsonschema/1-0-1"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ca9HcNf6uf", "09e0d33adc0f591a1220be138d0129b31bc5a6ffd01a5cd4e429dd98c998f8c7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Element engaged", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ca9HcNf6uf", "Element engaged", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Element engaged", eventProperties, "ca9HcNf6uf", "09e0d33adc0f591a1220be138d0129b31bc5a6ffd01a5cd4e429dd98c998f8c7");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/element_engaged/jsonschema/1-0-1",
      "data": Object.assign({
        "action": properties.action,
        "label": properties.label,
        "category": properties.elementCategory,
        "type": properties.elementType,
        "zone": properties.zone,
        "identifier": properties.identifier,
        }, { "avo_event_name": "Element engaged" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/element_engaged/jsonschema/1-0-1",
      {
        "action": properties.action,
        "label": properties.label,
        "category": properties.elementCategory,
        "type": properties.elementType,
        "zone": properties.zone,
        "identifier": properties.identifier,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface AdViewedProperties {
  impressionId: string | null | undefined;
  costModel: CostModelValueType | null | undefined;
  cost: string | null | undefined;
  targetUrl: string | null | undefined;
  bannerId: string | null | undefined;
  zoneId: string | null | undefined;
  advertiserId: string | null | undefined;
  campaignId: string | null | undefined;
}
/**
 * Ad viewed: This event is sent, when an ad is viewed. ("ad impression")
 *
 * When to trigger this event:
 * 1. Triggered when ad impression
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/NoFZ0HK3Zj/trigger/NJAyDqWKg
 *
 * @param properties the properties associatied with this event
 * @param properties.impressionId: Identifier for the particular impression instance
 * @param properties.costModel: The cost model for the campaign: 'cpc', 'cpm', or 'cpa'
 * @param properties.cost: Ad cost
 * @param properties.targetUrl: The destination URL
 * @param properties.bannerId: Adserver identifier for the ad banner (creative) being displayed
 * @param properties.zoneId: Adserver identifier for the zone where the ad banner is located
 * @param properties.advertiserId: Adserver identifier for the advertiser which the campaign belongs to
 * @param properties.campaignId: Adserver identifier for the ad campaign which the banner belongs to
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/NoFZ0HK3Zj}
 */
export function adViewed(properties: AdViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.impressionId !== undefined && properties.impressionId !== null ?
    eventPropertiesArray.push({id: "uY1Z16zEb", name: "impressionId", value: properties.impressionId}) :
    eventPropertiesArray.push({id: "uY1Z16zEb", name: "impressionId", value: null});
  properties.costModel !== undefined && properties.costModel !== null ?
    eventPropertiesArray.push({id: "tg2RePtjd", name: "costModel", value: properties.costModel}) :
    eventPropertiesArray.push({id: "tg2RePtjd", name: "costModel", value: null});
  properties.cost !== undefined && properties.cost !== null ?
    eventPropertiesArray.push({id: "Nv4oCns5C", name: "cost", value: properties.cost}) :
    eventPropertiesArray.push({id: "Nv4oCns5C", name: "cost", value: null});
  properties.targetUrl !== undefined && properties.targetUrl !== null ?
    eventPropertiesArray.push({id: "s2XBiTkwd", name: "targetUrl", value: properties.targetUrl}) :
    eventPropertiesArray.push({id: "s2XBiTkwd", name: "targetUrl", value: null});
  properties.bannerId !== undefined && properties.bannerId !== null ?
    eventPropertiesArray.push({id: "Cu1amDkMR", name: "bannerId", value: properties.bannerId}) :
    eventPropertiesArray.push({id: "Cu1amDkMR", name: "bannerId", value: null});
  properties.zoneId !== undefined && properties.zoneId !== null ?
    eventPropertiesArray.push({id: "LJgZKqeSg", name: "zoneId", value: properties.zoneId}) :
    eventPropertiesArray.push({id: "LJgZKqeSg", name: "zoneId", value: null});
  properties.advertiserId !== undefined && properties.advertiserId !== null ?
    eventPropertiesArray.push({id: "Ks6Y-gNLl", name: "advertiserID", value: properties.advertiserId}) :
    eventPropertiesArray.push({id: "Ks6Y-gNLl", name: "advertiserID", value: null});
  properties.campaignId !== undefined && properties.campaignId !== null ?
    eventPropertiesArray.push({id: "s144mL8-U", name: "campaignId", value: properties.campaignId}) :
    eventPropertiesArray.push({id: "s144mL8-U", name: "campaignId", value: null});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow/ad_impression/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NoFZ0HK3Zj", "820c641da4de66c2f0fb6cc888f0b019e6e34e5d9521cd5121aba72832842a8f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Ad viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NoFZ0HK3Zj", "Ad viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Ad viewed", eventProperties, "NoFZ0HK3Zj", "820c641da4de66c2f0fb6cc888f0b019e6e34e5d9521cd5121aba72832842a8f");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow/ad_impression/jsonschema/1-0-0",
      "data": Object.assign({
        "impressionId": properties.impressionId,
        "costModel": properties.costModel,
        "cost": properties.cost,
        "targetUrl": properties.targetUrl,
        "bannerId": properties.bannerId,
        "zoneId": properties.zoneId,
        "advertiserID": properties.advertiserId,
        "campaignId": properties.campaignId,
        }, { "avo_event_name": "Ad viewed" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow/ad_impression/jsonschema/1-0-0",
      {
        "impressionId": properties.impressionId,
        "costModel": properties.costModel,
        "cost": properties.cost,
        "targetUrl": properties.targetUrl,
        "bannerId": properties.bannerId,
        "zoneId": properties.zoneId,
        "advertiserID": properties.advertiserId,
        "campaignId": properties.campaignId,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface AdClickedProperties {
  costModel: CostModelValueType | null | undefined;
  cost: string | null | undefined;
  targetUrl: string;
  bannerId: string | null | undefined;
  zoneId: string | null | undefined;
  advertiserId: string | null | undefined;
  campaignId: string | null | undefined;
  clickId: string | null | undefined;
}
/**
 * Ad clicked: This event is sent, when an ad is clicked.
 *
 * When to trigger this event:
 * 1. An ad is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/QDFLi2aIk/trigger/XIGoZ66Xi
 *
 * @param properties the properties associatied with this event
 * @param properties.costModel: The cost model for the campaign: 'cpc', 'cpm', or 'cpa'
 * @param properties.cost: Ad cost
 * @param properties.targetUrl: The destination URL
 * @param properties.bannerId: Adserver identifier for the ad banner (creative) being displayed
 * @param properties.zoneId: Adserver identifier for the zone where the ad banner is located
 * @param properties.advertiserId: Adserver identifier for the advertiser which the campaign belongs to
 * @param properties.campaignId: Adserver identifier for the ad campaign which the banner belongs to
 * @param properties.clickId: Identifier for the particular click instance
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/QDFLi2aIk}
 */
export function adClicked(properties: AdClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.costModel !== undefined && properties.costModel !== null ?
    eventPropertiesArray.push({id: "tg2RePtjd", name: "costModel", value: properties.costModel}) :
    eventPropertiesArray.push({id: "tg2RePtjd", name: "costModel", value: null});
  properties.cost !== undefined && properties.cost !== null ?
    eventPropertiesArray.push({id: "Nv4oCns5C", name: "cost", value: properties.cost}) :
    eventPropertiesArray.push({id: "Nv4oCns5C", name: "cost", value: null});
  eventPropertiesArray.push({id: "s2XBiTkwd", name: "targetUrl", value: properties.targetUrl});
  properties.bannerId !== undefined && properties.bannerId !== null ?
    eventPropertiesArray.push({id: "Cu1amDkMR", name: "bannerId", value: properties.bannerId}) :
    eventPropertiesArray.push({id: "Cu1amDkMR", name: "bannerId", value: null});
  properties.zoneId !== undefined && properties.zoneId !== null ?
    eventPropertiesArray.push({id: "LJgZKqeSg", name: "zoneId", value: properties.zoneId}) :
    eventPropertiesArray.push({id: "LJgZKqeSg", name: "zoneId", value: null});
  properties.advertiserId !== undefined && properties.advertiserId !== null ?
    eventPropertiesArray.push({id: "Ks6Y-gNLl", name: "advertiserID", value: properties.advertiserId}) :
    eventPropertiesArray.push({id: "Ks6Y-gNLl", name: "advertiserID", value: null});
  properties.campaignId !== undefined && properties.campaignId !== null ?
    eventPropertiesArray.push({id: "s144mL8-U", name: "campaignId", value: properties.campaignId}) :
    eventPropertiesArray.push({id: "s144mL8-U", name: "campaignId", value: null});
  properties.clickId !== undefined && properties.clickId !== null ?
    eventPropertiesArray.push({id: "G-oYwBUqS", name: "clickId", value: properties.clickId}) :
    eventPropertiesArray.push({id: "G-oYwBUqS", name: "clickId", value: null});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow/ad_click/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QDFLi2aIk", "2440891702213560dd379d6b23745e5e9f8033d4cd60f630698f99c94dce1efa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Ad clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QDFLi2aIk", "Ad clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Ad clicked", eventProperties, "QDFLi2aIk", "2440891702213560dd379d6b23745e5e9f8033d4cd60f630698f99c94dce1efa");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow/ad_click/jsonschema/1-0-0",
      "data": Object.assign({
        "costModel": properties.costModel,
        "cost": properties.cost,
        "targetUrl": properties.targetUrl,
        "bannerId": properties.bannerId,
        "zoneId": properties.zoneId,
        "advertiserID": properties.advertiserId,
        "campaignId": properties.campaignId,
        "clickId": properties.clickId,
        }, { "avo_event_name": "Ad clicked" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow/ad_click/jsonschema/1-0-0",
      {
        "costModel": properties.costModel,
        "cost": properties.cost,
        "targetUrl": properties.targetUrl,
        "bannerId": properties.bannerId,
        "zoneId": properties.zoneId,
        "advertiserID": properties.advertiserId,
        "campaignId": properties.campaignId,
        "clickId": properties.clickId,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface CheckoutStepStartedProperties {
  product: Product[];
  checkoutStep: CheckoutStep;
  checkoutEntity: CheckoutEntity;
}
/**
 * Checkout step started: This event is sent, when a new checkout step is opened
 *
 * When to trigger this event:
 * 1. Triggers, when a checkout step is completed.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/q2ljDjaoZ/trigger/sygizahzQY
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.checkoutStep: Schema for a checkout step entity in Ecommerce
 * @param properties.checkoutEntity: Additional checkout data
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/q2ljDjaoZ}
 */
export function checkoutStepStarted(properties: CheckoutStepStartedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "pG67MKKD7x", name: "checkout_step", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
    "step": properties.checkoutStep.step,
    "shipping_postcode": properties.checkoutStep.shippingPostcode,
    "billing_postcode": properties.checkoutStep.billingPostcode,
    "shipping_full_address": properties.checkoutStep.shippingFullAddress,
    "billing_full_address": properties.checkoutStep.billingFullAddress,
    "delivery_provider": properties.checkoutStep.deliveryProvider,
    "delivery_method": properties.checkoutStep.deliveryMethod,
    "coupon_code": properties.checkoutStep.couponCode,
    "account_type": properties.checkoutStep.accountType,
    "payment_method": properties.checkoutStep.paymentMethod,
    "proof_of_payment": properties.checkoutStep.proofOfPayment,
  }});
  eventPropertiesArray.push({id: "5RTXMuQzx", name: "checkout_entity", value: {
    "status": properties.checkoutEntity.status,
    "schema": "iglu:de.npg/checkout_entity/jsonschema/1-0-0",
  }});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "checkout_step"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    messages = messages.concat(assertCheckoutStep(properties.checkoutStep));
    messages = messages.concat(assertCheckoutEntity(properties.checkoutEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q2ljDjaoZ", "d1220003aa710f9c110e05b09705fb73ea2b901d57c0fa91eece4df25722284e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Checkout step started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q2ljDjaoZ", "Checkout step started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Checkout step started': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Checkout step started", eventProperties, "q2ljDjaoZ", "d1220003aa710f9c110e05b09705fb73ea2b901d57c0fa91eece4df25722284e");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    if (properties.checkoutStep !== undefined && properties.checkoutStep !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
        "data": {
          "step": properties.checkoutStep.step,
          "shipping_postcode": properties.checkoutStep.shippingPostcode,
          "billing_postcode": properties.checkoutStep.billingPostcode,
          "shipping_full_address": properties.checkoutStep.shippingFullAddress,
          "billing_full_address": properties.checkoutStep.billingFullAddress,
          "delivery_provider": properties.checkoutStep.deliveryProvider,
          "delivery_method": properties.checkoutStep.deliveryMethod,
          "coupon_code": properties.checkoutStep.couponCode,
          "account_type": properties.checkoutStep.accountType,
          "payment_method": properties.checkoutStep.paymentMethod,
          "proof_of_payment": properties.checkoutStep.proofOfPayment,
        }
      });
    }
    if (properties.checkoutEntity !== undefined && properties.checkoutEntity !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:de.npg/checkout_entity/jsonschema/1-0-0",
        "data": {
          "status": properties.checkoutEntity.status,
        }
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "checkout_step",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "checkout_step",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface CheckoutStepCompletedProperties {
  product: Product[];
  checkoutStep: CheckoutStep;
  checkoutEntity: CheckoutEntity;
}
/**
 * Checkout step completed: This event is sent, when a checkout step is completed. Adds the options that were chosen (payment_method etc.) in the step to the checkout_step object.
 *
 * When to trigger this event:
 * 1. Triggers, when a checkout step is completed.
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/6GzxorYSz/trigger/gzS9JyPFaS
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.checkoutStep: Schema for a checkout step entity in Ecommerce
 * @param properties.checkoutEntity: Additional checkout data
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/6GzxorYSz}
 */
export function checkoutStepCompleted(
  properties: CheckoutStepCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "pG67MKKD7x", name: "checkout_step", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
    "step": properties.checkoutStep.step,
    "shipping_postcode": properties.checkoutStep.shippingPostcode,
    "billing_postcode": properties.checkoutStep.billingPostcode,
    "shipping_full_address": properties.checkoutStep.shippingFullAddress,
    "billing_full_address": properties.checkoutStep.billingFullAddress,
    "delivery_provider": properties.checkoutStep.deliveryProvider,
    "delivery_method": properties.checkoutStep.deliveryMethod,
    "coupon_code": properties.checkoutStep.couponCode,
    "account_type": properties.checkoutStep.accountType,
    "payment_method": properties.checkoutStep.paymentMethod,
    "proof_of_payment": properties.checkoutStep.proofOfPayment,
  }});
  eventPropertiesArray.push({id: "5RTXMuQzx", name: "checkout_entity", value: {
    "status": properties.checkoutEntity.status,
    "schema": "iglu:de.npg/checkout_entity/jsonschema/1-0-0",
  }});
  eventPropertiesArray.push({id: "7Nr-aF-di", name: "type", value: "checkout_step"});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    messages = messages.concat(assertCheckoutStep(properties.checkoutStep));
    messages = messages.concat(assertCheckoutEntity(properties.checkoutEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6GzxorYSz", "430bbea779e589c60b3bc7e4c173a5debce44f369c6fc45423f9f60777e3419a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Checkout step completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6GzxorYSz", "Checkout step completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Checkout step completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Checkout step completed", eventProperties, "6GzxorYSz", "430bbea779e589c60b3bc7e4c173a5debce44f369c6fc45423f9f60777e3419a");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    if (properties.checkoutStep !== undefined && properties.checkoutStep !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
        "data": {
          "step": properties.checkoutStep.step,
          "shipping_postcode": properties.checkoutStep.shippingPostcode,
          "billing_postcode": properties.checkoutStep.billingPostcode,
          "shipping_full_address": properties.checkoutStep.shippingFullAddress,
          "billing_full_address": properties.checkoutStep.billingFullAddress,
          "delivery_provider": properties.checkoutStep.deliveryProvider,
          "delivery_method": properties.checkoutStep.deliveryMethod,
          "coupon_code": properties.checkoutStep.couponCode,
          "account_type": properties.checkoutStep.accountType,
          "payment_method": properties.checkoutStep.paymentMethod,
          "proof_of_payment": properties.checkoutStep.proofOfPayment,
        }
      });
    }
    if (properties.checkoutEntity !== undefined && properties.checkoutEntity !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:de.npg/checkout_entity/jsonschema/1-0-0",
        "data": {
          "status": properties.checkoutEntity.status,
        }
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      "data": Object.assign({
        "type": "checkout_step",
        }, { "avo_event_name": "Snowplow ecommerce action" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:com.snowplowanalytics.snowplow.ecommerce/snowplow_ecommerce_action/jsonschema/1-0-2",
      {
        "type": "checkout_step",
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ProductSwitchedProperties {
  product: Product[];
  checkoutStep: CheckoutStep;
}
/**
 * Product switched: When a product in the checkout process is switched. From monthly to yearly for example.
 *
 * When to trigger this event:
 * 1. When the user clicks on the upgrade option to a yearly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Etxv3WKSVb/trigger/q8SJ6GMLR
 * 2. When the user clicks on the downgrade option to a monthly subscription in the checkout step
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Etxv3WKSVb/trigger/0b5v2s74C
 *
 * @param properties the properties associatied with this event
 * @param properties.product: Schema for a product entity in Ecommerce
 * @param properties.checkoutStep: Schema for a checkout step entity in Ecommerce
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/Etxv3WKSVb}
 */
export function productSwitched(properties: ProductSwitchedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iYU2x5SoNC", name: "product", value: properties.product.map(function(value) { return {
      "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
      "id": value.id,
      "name": value.name,
      "category": value.category,
      "price": value.price,
      "list_price": value.listPrice,
      "quantity": value.quantity,
      "variant": value.variant,
      "brand": value.brand,
      "inventory_status": value.inventoryStatus,
      "position": value.position,
      "currency": value.currency,
      "creative_id": value.creativeId,
    }
  })});
  eventPropertiesArray.push({id: "pG67MKKD7x", name: "checkout_step", value: {
    "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
    "step": properties.checkoutStep.step,
    "shipping_postcode": properties.checkoutStep.shippingPostcode,
    "billing_postcode": properties.checkoutStep.billingPostcode,
    "shipping_full_address": properties.checkoutStep.shippingFullAddress,
    "billing_full_address": properties.checkoutStep.billingFullAddress,
    "delivery_provider": properties.checkoutStep.deliveryProvider,
    "delivery_method": properties.checkoutStep.deliveryMethod,
    "coupon_code": properties.checkoutStep.couponCode,
    "account_type": properties.checkoutStep.accountType,
    "payment_method": properties.checkoutStep.paymentMethod,
    "proof_of_payment": properties.checkoutStep.proofOfPayment,
  }});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/product_switched/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProduct(properties.product));
    messages = messages.concat(assertCheckoutStep(properties.checkoutStep));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Etxv3WKSVb", "6fe0b16a84da609a911ed5324cd20ab3541e2476dfe0f7778728b7e389a30834", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product switched", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Etxv3WKSVb", "Product switched", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product switched': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product switched", eventProperties, "Etxv3WKSVb", "6fe0b16a84da609a911ed5324cd20ab3541e2476dfe0f7778728b7e389a30834");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    if (properties.product !== undefined && properties.product !== null) {
      properties.product.map(function(value) {
        SnowplowDWHTrackerContext.push({
          "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0",
          "data": {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "price": value.price,
            "list_price": value.listPrice,
            "quantity": value.quantity,
            "variant": value.variant,
            "brand": value.brand,
            "inventory_status": value.inventoryStatus,
            "position": value.position,
            "currency": value.currency,
            "creative_id": value.creativeId,
          }});
      });
    }
    if (properties.checkoutStep !== undefined && properties.checkoutStep !== null) {
      SnowplowDWHTrackerContext.push({
        "schema": "iglu:com.snowplowanalytics.snowplow.ecommerce/checkout_step/jsonschema/1-0-0",
        "data": {
          "step": properties.checkoutStep.step,
          "shipping_postcode": properties.checkoutStep.shippingPostcode,
          "billing_postcode": properties.checkoutStep.billingPostcode,
          "shipping_full_address": properties.checkoutStep.shippingFullAddress,
          "billing_full_address": properties.checkoutStep.billingFullAddress,
          "delivery_provider": properties.checkoutStep.deliveryProvider,
          "delivery_method": properties.checkoutStep.deliveryMethod,
          "coupon_code": properties.checkoutStep.couponCode,
          "account_type": properties.checkoutStep.accountType,
          "payment_method": properties.checkoutStep.paymentMethod,
          "proof_of_payment": properties.checkoutStep.proofOfPayment,
        }
      });
    }
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/product_switched/jsonschema/1-0-0",
      "data": Object.assign({}, { "avo_event_name": "Product switched" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/product_switched/jsonschema/1-0-0",
      {},
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

/**
 * Page bookmarked: An article is bookmarked
 *
 * When to trigger this event:
 * 1. When a bookmark is created
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/7YScysT4bd/trigger/27KzSR5N3
 * 2. When an article is bookmarked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/7YScysT4bd/trigger/2w27fnCXk
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/7YScysT4bd}
 */
export function pageBookmarked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/page_bookmarked/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7YScysT4bd", "dc2d9ef0f3f0defb8a752ffb17c11dfc04ac67b82be081914fcf131d17bf94ec", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page bookmarked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7YScysT4bd", "Page bookmarked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Page bookmarked", eventProperties, "7YScysT4bd", "dc2d9ef0f3f0defb8a752ffb17c11dfc04ac67b82be081914fcf131d17bf94ec");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/page_bookmarked/jsonschema/1-0-0",
      "data": Object.assign({}, { "avo_event_name": "Page bookmarked" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/page_bookmarked/jsonschema/1-0-0",
      {},
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface OnboardingFlowInteractedProperties {
  onboardingAction: OnboardingActionValueType;
}
/**
 * Onboarding flow interacted: This event is sent for multiple actions taken on the onboarding flow modal
 *
 * When to trigger this event:
 * 1. When the flow is surfaced to a user.
action= "started"
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/bjbThAOxjw/trigger/HohmKjONu
 * 2. When the flow was completed by the user.
action= "completed"
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/bjbThAOxjw/trigger/aR4gF8khd
 * 3. When the modal was closed or otherwise abandoned by the user.

action= "abandoned"
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/bjbThAOxjw/trigger/trwLjADEz
 *
 * @param properties the properties associatied with this event
 * @param properties.onboardingAction: The action taken in the onboarding process
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/bjbThAOxjw}
 */
export function onboardingFlowInteracted(
  properties: OnboardingFlowInteractedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "n5q4WcxJ2", name: "action", value: properties.onboardingAction});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/onboarding_flow_interacted/jsonschema/1-0-0"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bjbThAOxjw", "7a60c4e376a5d280edb972561b8c212121133e77a6fa8b074bc2113383b628f9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Onboarding flow interacted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bjbThAOxjw", "Onboarding flow interacted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Onboarding flow interacted", eventProperties, "bjbThAOxjw", "7a60c4e376a5d280edb972561b8c212121133e77a6fa8b074bc2113383b628f9");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/onboarding_flow_interacted/jsonschema/1-0-0",
      "data": Object.assign({
        "action": properties.onboardingAction,
        }, { "avo_event_name": "Onboarding flow interacted" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/onboarding_flow_interacted/jsonschema/1-0-0",
      {
        "action": properties.onboardingAction,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface InternalAdClickedProperties {
  zone: ZoneValueType;
  label: string;
  elementCategory: string;
  campaign: string | null | undefined;
}
/**
 * Internal ad clicked: When an internal ad is clicked
 *
 * When to trigger this event:
 * 1. Fires when our internal ad in the header is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/OxyXJgxFCp/trigger/glklXtoTF
 * 2. Fires when our internal edge ad is clicked
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/OxyXJgxFCp/trigger/hLP7tt4Gf
 *
 * @param properties the properties associatied with this event
 * @param properties.zone: Describes the general position of the module
 * @param properties.label: The label of the item
 * @param properties.elementCategory: The category of the clicked element
 * @param properties.campaign: The campaign for this ad.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/OxyXJgxFCp}
 */
export function internalAdClicked(properties: InternalAdClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vihj61jvd", name: "zone", value: properties.zone});
  eventPropertiesArray.push({id: "_kCEubpUt", name: "label", value: properties.label});
  eventPropertiesArray.push({id: "EpEEsxkDJ", name: "category", value: properties.elementCategory});
  properties.campaign !== undefined && properties.campaign !== null ?
    eventPropertiesArray.push({id: "b8Su0S3_S", name: "campaign", value: properties.campaign}) :
    eventPropertiesArray.push({id: "b8Su0S3_S", name: "campaign", value: null});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0"});
  eventPropertiesArray.push({id: "aK1YDgAdu", name: "action", value: "clicked"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "OxyXJgxFCp", "ab04717a8500659fe8ef9cac45e39c2bd221bc59d68c7e736997b5bc1a7d41a8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Internal ad clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("OxyXJgxFCp", "Internal ad clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Internal ad clicked", eventProperties, "OxyXJgxFCp", "ab04717a8500659fe8ef9cac45e39c2bd221bc59d68c7e736997b5bc1a7d41a8");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "clicked",
        "zone": properties.zone,
        "label": properties.label,
        "category": properties.elementCategory,
        "campaign": properties.campaign,
        }, { "avo_event_name": "Internal ad clicked" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0",
      {
        "action": "clicked",
        "zone": properties.zone,
        "label": properties.label,
        "category": properties.elementCategory,
        "campaign": properties.campaign,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface InternalAdViewedProperties {
  zone: ZoneValueType;
  label: string;
  elementCategory: string;
  campaign: string;
}
/**
 * Internal ad viewed: When an internal ad is in the viewport
 *
 * When to trigger this event:
 * 1. Fires when our internal ad in the header is in the viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/zanUqD1KU/trigger/feiXvSbWDl
 * 2. Fires when our internal edge ad is in the viewport
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/zanUqD1KU/trigger/P4NmGzwTCY
 *
 * @param properties the properties associatied with this event
 * @param properties.zone: Describes the general position of the module
 * @param properties.label: The label of the item
 * @param properties.elementCategory: The category of the clicked element
 * @param properties.campaign: The campaign for this ad.
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/zanUqD1KU}
 */
export function internalAdViewed(properties: InternalAdViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vihj61jvd", name: "zone", value: properties.zone});
  eventPropertiesArray.push({id: "_kCEubpUt", name: "label", value: properties.label});
  eventPropertiesArray.push({id: "EpEEsxkDJ", name: "category", value: properties.elementCategory});
  eventPropertiesArray.push({id: "b8Su0S3_S", name: "campaign", value: properties.campaign});
  eventPropertiesArray.push({id: "5p6WwpHmS", name: "schema", value: "iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0"});
  eventPropertiesArray.push({id: "aK1YDgAdu", name: "action", value: "viewed"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zanUqD1KU", "186ea8d49d1e6fed216c9b04530bcda23723a4bf9efcc388c5a8cb63266a2743", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Internal ad viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zanUqD1KU", "Internal ad viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Internal ad viewed", eventProperties, "zanUqD1KU", "186ea8d49d1e6fed216c9b04530bcda23723a4bf9efcc388c5a8cb63266a2743");
    }
    // destination SnowplowDWHTracker
    let SnowplowDWHTrackerContext: any = [];
    SnowplowDWHTracker && SnowplowDWHTracker.logEvent && SnowplowDWHTracker.logEvent({"shema": "iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0",
      "data": Object.assign({
        "action": "viewed",
        "zone": properties.zone,
        "label": properties.label,
        "category": properties.elementCategory,
        "campaign": properties.campaign,
        }, { "avo_event_name": "Internal ad viewed" })
      }
    , SnowplowDWHTrackerContext);
    SnowplowDWHTracker && SnowplowDWHTracker.trackSelfDescribingEvent("iglu:de.npg/internal_ad_engaged/jsonschema/1-0-0",
      {
        "action": "viewed",
        "zone": properties.zone,
        "label": properties.label,
        "category": properties.elementCategory,
        "campaign": properties.campaign,
        },
    SnowplowDWHTrackerContext);
  } else {
    // do nothing
  }
}

export interface ContentEntityUpdatedProperties {
  contentEntity: ContentEntity;
}
/**
 * Content entity updated: This event is sent, when a global content context field is updated.
 *
 * When to trigger this event:
 * 1. When a field in global content context has new values
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/LokZopJIIx/trigger/d8-uTLio0
 * 2. After the tracker has been initialized
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/LokZopJIIx/trigger/lygauu3-3
 *
 * @param properties the properties associatied with this event
 * @param properties.contentEntity: Schema for NPG content entity
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/LokZopJIIx}
 */
export function contentEntityUpdated(
  properties: ContentEntityUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "rfyvtI1ceG", name: "content_entity", value: {
    "container_id": properties.contentEntity.containerId,
    "article_id": properties.contentEntity.articleId,
    "page_type": properties.contentEntity.pageType,
    "freemium_state": properties.contentEntity.freemiumState,
    "has_paywall": properties.contentEntity.hasPaywall,
    "content_user_need": properties.contentEntity.contentUserNeed,
    "content_type": properties.contentEntity.contentType,
    "audience": properties.contentEntity.audience,
    "tags": properties.contentEntity.tags,
    "rtr_topics": properties.contentEntity.rtrTopics,
    "subsections": properties.contentEntity.subsections,
    "publish_tstamp": properties.contentEntity.publishTstamp,
    "section": properties.contentEntity.section,
    "schema": "iglu:de.npg/content/jsonschema/2-0-1",
  }});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertContentEntity(properties.contentEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LokZopJIIx", "ac793dd71d6072c934c555667000cb29ffea363ebf79bd8ebc65a90893e9593e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Content entity updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LokZopJIIx", "Content entity updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Content entity updated': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Content entity updated", eventProperties, "LokZopJIIx", "ac793dd71d6072c934c555667000cb29ffea363ebf79bd8ebc65a90893e9593e");
    }
  } else {
    // do nothing
  }
}

export interface UserEntityUpdatedProperties {
  userEntity: UserEntity;
}
/**
 * User entity updated: This event is sent, when a global user context field is updated.
 *
 * When to trigger this event:
 * 1. When a field in global user context has new values
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/C_YKTpavAk/trigger/XR1CktzPhs
 * 2. After the tracker has been initialized
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/C_YKTpavAk/trigger/s2w-eVCU5
 *
 * @param properties the properties associatied with this event
 * @param properties.userEntity: Schema for NPG user entity
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/C_YKTpavAk}
 */
export function userEntityUpdated(properties: UserEntityUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "NJHJ_JtxZY", name: "user_entity", value: {
    "user_id": properties.userEntity.userId,
    "user_type": properties.userEntity.userType,
    "is_logged_in": properties.userEntity.isLoggedIn,
    "has_plus_access": properties.userEntity.hasPlusAccess,
    "registered_at": properties.userEntity.registeredAt,
    "product_string": properties.userEntity.productString,
    "access_rights": properties.userEntity.accessRights,
    "anonymous_appstore_purchase": properties.userEntity.anonymousAppstorePurchase,
    "products": properties.userEntity.products,
    "schema": "iglu:de.npg/user/jsonschema/1-0-3",
  }});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertUserEntity(properties.userEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "C_YKTpavAk", "6da50fb1251d70496f1421d0685a4ee001d50ac33757ce2d43a3fb5ef011cb72", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("User entity updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("C_YKTpavAk", "User entity updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'User entity updated': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User entity updated", eventProperties, "C_YKTpavAk", "6da50fb1251d70496f1421d0685a4ee001d50ac33757ce2d43a3fb5ef011cb72");
    }
  } else {
    // do nothing
  }
}

export interface EnvironmentEntityUpdatedProperties {
  environmentEntity: EnvironmentEntity;
}
/**
 * Environment entity updated: This event is sent, when a global environment context field is updated.
 *
 * When to trigger this event:
 * 1. When a field in global content context has new values
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/X1WQIYmnin/trigger/NdFKLw19A
 * 2. After the tracker has been initialized
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/X1WQIYmnin/trigger/Pn2xtH_Uj
 *
 * @param properties the properties associatied with this event
 * @param properties.environmentEntity: Describes the environment, where the event happened
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/X1WQIYmnin}
 */
export function environmentEntityUpdated(
  properties: EnvironmentEntityUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "zJ31YiiUkb", name: "environment_entity", value: {
    "environment": properties.environmentEntity.environment,
    "app_mode": properties.environmentEntity.appMode,
    "epaper_view_mode": properties.environmentEntity.epaperViewMode,
    "schema": "iglu:de.npg/environment_entity/jsonschema/1-0-0",
  }});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertEnvironmentEntity(properties.environmentEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "X1WQIYmnin", "2b7ad24e9390f83c633cffa07ede16a3e6929817e36f84365a2203242c4c4030", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Environment entity updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("X1WQIYmnin", "Environment entity updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Environment entity updated': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Environment entity updated", eventProperties, "X1WQIYmnin", "2b7ad24e9390f83c633cffa07ede16a3e6929817e36f84365a2203242c4c4030");
    }
  } else {
    // do nothing
  }
}

export interface EpaperEntityUpdatedProperties {
  epaperEntity: EpaperEntity;
}
/**
 * Epaper entity updated: This event is sent, when a global epaper context field is updated.
 *
 * When to trigger this event:
 * 1. When a field in global epaper context has new values
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/GYdVG4_c4r/trigger/INi0IJlmI
 * 2. After the tracker has been initialized
 * View in Avo: https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/GYdVG4_c4r/trigger/MieQok4w3
 *
 * @param properties the properties associatied with this event
 * @param properties.epaperEntity: Entity that describes an epaper
 *
 * @see {@link https://www.avo.app/schemas/Qg1zFAwD0Y460MvsYCxk/events/GYdVG4_c4r}
 */
export function epaperEntityUpdated(properties: EpaperEntityUpdatedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "nUxZpBEsGe", name: "epaper_entity", value: {
    "schema": "iglu:de.npg/epaper_entity/jsonschema/1-0-0",
    "edition": properties.epaperEntity.edition,
    "book": properties.epaperEntity.book,
    "page_number": properties.epaperEntity.pageNumber,
    "number_of_pages": properties.epaperEntity.numberOfPages,
    "published_at_date": properties.epaperEntity.publishedAtDate,
    "view_mode": properties.epaperEntity.viewMode,
    "issue": properties.epaperEntity.issue,
    "year": properties.epaperEntity.year,
  }});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertEpaperEntity(properties.epaperEntity));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "GYdVG4_c4r", "e60cb13048565e7503de8207e57b6c4f684ccde2f4cd12efa547628ed8799f65", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Epaper entity updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("GYdVG4_c4r", "Epaper entity updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Epaper entity updated': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Epaper entity updated", eventProperties, "GYdVG4_c4r", "e60cb13048565e7503de8207e57b6c4f684ccde2f4cd12efa547628ed8799f65");
    }
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  State,
  Type,
  FreemiumState,
  TypeNavigation,
  ErrorType,
  ModuleType,
  PaymentMethod,
  ElementType,
  Environment,
  Action,
  ViewMode,
  ItemType,
  UserIdentificationAction,
  AppMode,
  OnboardingAction,
  UserType,
  PageType,
  CostModel,
  Zone,
  EpaperViewMode,
  Status,
  ContentOrigin,
  moduleEngaged,
  pageViewed,
  userLoggedIn,
  userLoggedOut,
  navigationItemClicked,
  articleRead,
  paywallViewed,
  productAddedToCart,
  productRemovedFromCart,
  orderCompleted,
  orderFailed,
  promotionViewed,
  promotionClicked,
  productViewed,
  productListViewed,
  productClicked,
  elementEngaged,
  adViewed,
  adClicked,
  checkoutStepStarted,
  checkoutStepCompleted,
  productSwitched,
  pageBookmarked,
  onboardingFlowInteracted,
  internalAdClicked,
  internalAdViewed,
  contentEntityUpdated,
  userEntityUpdated,
  environmentEntityUpdated,
  epaperEntityUpdated,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["moduleEngaged","pageViewed","userLoggedIn","userLoggedOut","navigationItemClicked","articleRead","paywallViewed","productAddedToCart","productRemovedFromCart","orderCompleted","orderFailed","promotionViewed","promotionClicked","productViewed","productListViewed","productClicked","elementEngaged","adViewed","adClicked","checkoutStepStarted","checkoutStepCompleted","productSwitched","pageBookmarked","onboardingFlowInteracted","internalAdClicked","internalAdViewed","contentEntityUpdated","userEntityUpdated","environmentEntityUpdated","epaperEntityUpdated"]
