export default () => {
	try {
		if (process.server) {
			// Context from Nitro Plugin (context.ts)
			// Reminder: useRequestEvent() is undefined on client
			const contextValue = useRequestEvent()?.context?.isApp
			// Set state for later use in client context
			useState('isApp', () => contextValue);
			return contextValue;
		}
		// Client context
		// Return state value as other components (e.g. Footer) expect a return value and not utilizing useState()
		return useState('isApp').value

	} catch (e) {
		return false;
	}
};
