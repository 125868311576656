import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

interface IOnboardingData {
	onboarding_complete: string;
	onboarding_init: string;
	onboarding_show: string;
}

export const useOnboardingStore = defineStore("onboarding", () => {
	const localCacheTime = 10*60; // Anzahl der Sekunden, die die Daten lokal im Cache liegen
	const shouldShow = ref(false); // Zeigt an, ob das Onboarding ausgespielt werden sollte oder nicht
	let lastUpdate = useStorage("onboardingUpdateTime", 0);
	let onboardingData: IOnboardingData = useStorage("onboarding", getOnboardingDefaults());

	function getOnboardingDefaults(): IOnboardingData {
		return {
			onboarding_complete: "",
			onboarding_init: "",
			onboarding_show: "",
		}
	}

	/**
	 * Setzt den Timestamp für die Initialisierung des Onboardings.
	 * Dieser dient als Richtwert für die weitere Ausspielung des Onboardings.
	 */
	function init() {
		onboardingData.value.onboarding_init = getCurrentUnixTimestampAsString();
		log("Initializing the onboarding prozess. New state of onboarding: " + JSON.stringify(onboardingData));
		return setOnboardingData();
	}

	/**
	 * Setzt die Werte zur Ausspielung des Onboardings.
	 */
	function show() {
		onboardingData.value.onboarding_show = getCurrentUnixTimestampAsString();
		shouldShow.value = true;
		log("We are showing the onboarding. New state of onboarding: " + JSON.stringify(onboardingData.value));
		return setOnboardingData();
	}

	/**
	 * Schließt das Onboarding ab, sodass es künftig nicht mehr angezeigt wird.
	 */
	function complete() {
		onboardingData.value.onboarding_complete = getCurrentUnixTimestampAsString();
		log("The Onboarding is completed. New state of onboarding: " + JSON.stringify(onboardingData.value));
		return setOnboardingData();
	}

	/**
	 * Setzt den Stand der Daten auf den Default-Wert zurück
	 */
	function reset() {
		log("Resetting onboarding-data.");
		onboardingData.value = getOnboardingDefaults();
		return setOnboardingData();
	}

	/**
	 * Holt die aktuellen Daten von der API
	 */
	async function getOnboardingData() {
		const secondsSinceLastFetch = (Date.now() - lastUpdate.value) / 1000;
		log("Checking local cache time: ", lastUpdate.value, secondsSinceLastFetch);
		// Wir geben die Daten direkt zurück, wenn sie aktuell genug sind
		if (lastUpdate.value !== 0 && secondsSinceLastFetch < localCacheTime) {
			log("Returning local data as cache time is not expired: " + secondsSinceLastFetch);
			return onboardingData;
		}
		const response = await $fetch(`/api/user/additionalData`);
		log("Fetching the data from Plenigo " + JSON.stringify(response?.data));
		lastUpdate.value = Date.now(); // Wir zeigen an, dass hier die letzte Abfrage auf die Daten stattgefunden hat
		onboardingData.value = extractOnboardingDataFromPlenigoData(response?.data);
		return onboardingData;
	}

	/**
	 * Schreibt die aktuellen Daten über die API. Zuvor werden die aktuellen Daten von Plenigo geholt und zusammengeführt.
	 */
	async function setOnboardingData() {
		let jsonString =  JSON.stringify({"data": onboardingData.value});
		try {
			const response = await $fetch(`/api/user/additionalData`);
			log("Fetching the current data before setting new values: " + JSON.stringify(response?.data));
			if (typeof response.data === "object") {
				jsonString = JSON.stringify({ "data": {...response?.data, ...onboardingData.value} });
			}
		} catch (e) {
			console.error("Unable to set json string from existing data. Using existing data. " + e.toString());
		}
		log("We are setting the new additional data: " + jsonString);
		return $fetch(`/api/user/additionalData`, {
			method: "PUT",
			body: jsonString
		});
	}

	/**
	 * Zieht die für das Onboarding relevanten Felder aus den Plenigo-Daten.
	 * @param plenigoData
	 */
	function extractOnboardingDataFromPlenigoData(plenigoData): IOnboardingData {
		const extractedData = {
			onboarding_complete: plenigoData?.onboarding_complete || "",
			onboarding_init: plenigoData?.onboarding_init || "",
			onboarding_show: plenigoData?.onboarding_show || "",
		};
		log("Extracting data from PlenigoObject: " + JSON.stringify(extractedData));
		return extractedData;
	}

	function getCurrentUnixTimestampAsString() {
		return `${getCurrentUnixTimestamp()}`;
	}

	function getCurrentUnixTimestamp() {
		return Math.floor(Date.now() / 1000);
	}

	function log(message) {
		if (useIsDebug()) {
			console.log("Onboarding: ", message);
		}
	}

	return {
		init, show, complete, reset, getOnboardingData, onboardingData, lastUpdate, shouldShow
	};
});
