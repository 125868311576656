<template>
	<!-- STEP START -->
	<section class="u-onboarding u-onboarding--step-1">
		<div class="background-theme theme-light-blue u-onboarding__side">
			<img :src="`/static/favicons/${usePublication()}/favicon-96x96.png`" alt="" height="96" class="u-onboarding__side-image" />
			<h2 class="mt-0 fs-6 mb-0">Laden Sie unsere preisegekrönte App herunter!</h2>
			<p class="mt-0 fs-3 mb-0">
				Hier können Sie sich Nachrichten vorlesen lassen oder Artikel in Ihrer persönlichen Merkliste speichern.
			</p>
			<OnboardingSteps :activeIndex="step - 1" :steps="steps" @activate="$emit('goto', $event + 1)"></OnboardingSteps>
		</div>
		<div class="u-onboarding__content">
			<img :src="`/static/images/${usePublication()}/app-preview.png`" alt="" height="267" class="mt-3 u-onboarding__app-preview" />
			<img :src="`/static/images/${usePublication()}/appstore-qrcode.png`" alt="" height="124" class="mt-7 u-onboarding__qr-code" />
			<div class="d-flex mt-6 u-onboarding__app-links">
				<AtomsBaseLink :url="appStoreAndroid" class="u-app-teaser__button" :external="true">
					<img src="/static/images/app-store-google.png" alt="App im Google Play Store" height="50" />
				</AtomsBaseLink>
				<AtomsBaseLink :url="appStoreIos" class="u-app-teaser__button" :external="true">
					<img src="/static/images/app-store-ios.svg" alt="App im App Store" height="50" />
				</AtomsBaseLink>
			</div>
			<AtomsBaseCTA
				class="mt-8 u-onboarding__next-step-button mt-10"
				version="primary"
				type="button"
				@click="$emit('goto', 2)"
				:big="true"
			>
				Weiter zu Schritt 2
			</AtomsBaseCTA>
		</div>
	</section>
</template>

<script lang="ts" setup>
	defineProps<{
		step: number;
		steps: string[];
	}>();

	const config = useClientSideConfig();
	const appStoreAndroid = config.app_store_android;
	const appStoreIos = config.app_store_ios;
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-onboarding--step-1 {
		@include mobile {
			.u-onboarding__side-image {
				height: 3.125rem;
			}
			.u-onboarding__app-preview {
				height: 11.875rem;
			}
		}

		.u-onboarding__qr-code {
			@include tablet-below {
				display: none;
			}
		}
		.u-onboarding__app-preview {
			max-height: 22vh;
		}

		.u-onboarding__side {
			display: flex;
			flex-direction: column;
			gap: var(--space-3);
			padding: var(--space-4) var(--space-6);

			@include tablet-above {
				gap: var(--space-6);
			}
		}
	}
</style>
