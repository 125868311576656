import { useCheckoutStore } from "~/stores/checkout.store";
import { useOfferStore } from "~/stores/offer.store";
import { Publications } from "@living-online/types";
import Avo, {
	type AdClickedProperties,
	type AdViewedProperties,
	type Cart,
	type CheckoutEntity,
	type CheckoutStep,
	type ElementEngagedProperties,
	type ModuleEngagedProperties,
	type NavigationItemClickedProperties,
	type OnboardingFlowInteractedProperties,
	type PageViewedProperties,
	type Product,
	type ProductClickedProperties,
	type ProductListViewedProperties,
	type ProductViewedProperties,
	type Promotion,
	type Transaction,
	type TransactionError,
	type UserLoggedInProperties
} from "~/tracking/libs/Avo";

export class DwhTracking {
	publication: Publications;
	debugMode: boolean;

	constructor(publication: Publications, isDebugMode: Boolean = false) {
		this.publication = publication;
		this.debugMode = isDebugMode;
	}

	adClicked(adClickedProperties: AdClickedProperties) {
		if (this.debugMode) {
			console.log("function call 'adClicked' (tenant " + this.publication + ") executed", adClickedProperties);
		} else {
			try {
				Avo.adClicked(adClickedProperties);
			} catch (e) {
				this.debugTrackingError("adClicked", e);
			}
		}
	}

	adViewed(adViewedProperties: AdViewedProperties) {
		if (this.debugMode) {
			console.log("function call 'AdViewedProperties' (tenant " + this.publication + ") executed", AdViewedProperties);
		} else {
			try {
				Avo.adViewed(AdViewedProperties);
			} catch (e) {
				this.debugTrackingError("adViewed", e);
			}
		}
	}

	articleRead() {
		if (this.debugMode) {
			console.log("function call 'articleRead' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.articleRead();
			} catch (e) {
				this.debugTrackingError("articleRead", e);
			}
		}
	}

	pageViewed(pageViewedProperties: PageViewedProperties): void {
		if (this.debugMode) {
			console.log("function call 'pageViewed' (tenant " + this.publication + ") executed", pageViewedProperties);
		} else {
			try {
				Avo.pageViewed(pageViewedProperties);
			} catch (e) {
				this.debugTrackingError("pageViewed", e);
			}
		}
	}

	elementEngaged(elementEngagedProperties: ElementEngagedProperties) {
		if (this.debugMode) {
			console.log("function call 'elementEngaged' (tenant " + this.publication + ") executed", elementEngagedProperties);
		} else {
			try {
				Avo.elementEngaged(elementEngagedProperties);
			} catch (e) {
				this.debugTrackingError("elementEngaged", e);
			}
		}
	}

	moduleEngaged(moduleEngagedProperties: ModuleEngagedProperties) {
		if (this.debugMode) {
			console.log("function call 'moduleEngaged' (tenant " + this.publication + ") executed", moduleEngagedProperties);
		} else {
			try {
				Avo.moduleEngaged(moduleEngagedProperties);
			} catch (e) {
				this.debugTrackingError("moduleEngaged", e);
			}
		}
	}

	navigationItemClicked(navigationItemClickedProperties: NavigationItemClickedProperties) {
		if (this.debugMode) {
			console.log("function call 'navigationItemClicked' (tenant " + this.publication + ") executed", navigationItemClickedProperties);
		} else {
			try {
				Avo.navigationItemClicked(navigationItemClickedProperties);
			} catch (e) {
				this.debugTrackingError("navigationItemClicked", e);
			}
		}
	}

	onboardingFlowInteracted(onboardingAction: OnboardingFlowInteractedProperties) {
		if (this.debugMode) {
			console.log("function call 'onboardingFlowInteracted' (tenant " + this.publication + ") executed", onboardingAction);
		} else {
			try {
				Avo.onboardingFlowInteracted(onboardingAction);
			} catch (e) {
				this.debugTrackingError("onboardingFlowInteracted", e);
			}
		}
	}

	pageBookmarked() {
		if (this.debugMode) {
			console.log("function call 'pageBookmarked' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.pageBookmarked();
			} catch (e) {
				this.debugTrackingError("pageBookmarked", e);
			}
		}
	}

	productClicked(ProductClickedProperties: ProductClickedProperties) {
		if (this.debugMode) {
			console.log("function call 'productClicked' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.productClicked(ProductClickedProperties);
			} catch (e) {
				this.debugTrackingError("productClicked", e);
			}
		}
	}

	paywallViewed() {
		if (this.debugMode) {
			console.log("function call 'paywallViewed' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.paywallViewed();
			} catch (e) {
				this.debugTrackingError("paywallViewed", e);
			}
		}
	}

	productViewed(ProductViewedProperties: ProductViewedProperties) {
		if (this.debugMode) {
			console.log("function call 'productViewed' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.productViewed(ProductViewedProperties);
			} catch (e) {
				this.debugTrackingError("productViewed", e);
			}
		}
	}

	productListViewed(ProductListViewedProperties: ProductListViewedProperties) {
		if (this.debugMode) {
			console.log("function call 'productListViewed' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.productListViewed(ProductListViewedProperties);
			} catch (e) {
				this.debugTrackingError("productListViewed", e);
			}
		}
	}

	userLoggedIn(userLoggedInProperties: UserLoggedInProperties) {
		if (this.debugMode) {
			console.log("function call 'userLoggedIn' (tenant " + this.publication + ") executed", userLoggedInProperties);
		} else {
			try {
				Avo.userLoggedIn(userLoggedInProperties);
			} catch (e) {
				this.debugTrackingError("userLoggedIn", e);
			}
		}
	}

	userLoggedOut() {
		if (this.debugMode) {
			console.log("function call 'userLoggedOut' (tenant " + this.publication + ") executed");
		} else {
			try {
				Avo.userLoggedOut();
			} catch (e) {
				this.debugTrackingError("userLoggedOut", e);
			}
		}
	}

	promotionClicked(promotionData = {}) {
		const product = this.getProductFromStorage();
		const promotion = this.getPromotionFromStorage(promotionData);
		if (this.debugMode) {
			console.log("function call 'promotionClicked' (tenant " + this.publication + ") executed", product, promotion);
		} else {
			try {
				Avo.promotionClicked({ product: product, promotion: promotion });
			} catch (e) {
				this.debugTrackingError("promotionClicked", e);
			}
		}
	}

	promotionViewed(promotionData = {}) {
		const product = this.getProductFromStorage();
		const promotion = this.getPromotionFromStorage(promotionData);
		if (this.debugMode) {
			console.log("function call 'promotionViewed' (tenant " + this.publication + ") executed", product, promotion);
		} else {
			try {
				Avo.promotionViewed({ product: product, promotion: promotion });
			} catch (e) {
				this.debugTrackingError("promotionViewed", e);
			}
		}
	}

	orderFailed(transactionErrorData: any) {
		const product = this.getProductFromStorage();
		const transactionError: TransactionError = {
			errorCode: transactionErrorData.errorCode,
			errorShortcode: "",
			errorType: null,
			errorDescription: transactionErrorData.errorMessage,
			resolution: null
		};
		if (this.debugMode) {
			console.log("function call 'orderFailed' (tenant " + this.publication + ") executed", product, transaction);
		} else {
			try {
				Avo.orderFailed({ product: product, transactionError: transactionError });
			} catch (e) {
				this.debugTrackingError("orderFailed", e);
			}
		}
	}

	orderCompleted(transactionData: any) {
		const product = this.getProductFromStorage();
		const firstProduct = product?.[0] || {};
		const transaction: Transaction = {
			transactionId: String(transactionData.orderId),
			revenue: firstProduct?.price || 0,
			currency: firstProduct?.currency || "EUR",
			totalQuantity: firstProduct?.quantity || 1,
			tax: 0, // ToDo: soll dieses Feld mit einem Wert gefüllt werden?
			shipping: 0, // ToDo: soll dieses Feld mit einem Wert gefüllt werden?
			discountCode: null, // ToDo: muss beim Gutscheinkauf berücksichtigt werden
			discountAmount: 0, // ToDo: muss beim Gutscheinkauf berücksichtigt werden
			creditOrder: false,
			paymentMethod: transactionData.paymentMethod || null // ToDo: mit andi klären, der von plenigo übergebene Wert matcht noch nicht mit der spec unter avo.app
		};
		if (this.debugMode) {
			console.log("function call 'orderCompleted' (tenant " + this.publication + ") executed", product, transaction);
		} else {
			try {
				Avo.orderCompleted({ product: product, transaction: transaction });
			} catch (e) {
				this.debugTrackingError("orderCompleted", e);
			}
		}
	}

	productAddedToCart() {
		const product = this.getProductFromStorage();
		const cart = this.getCartFromStorage();
		const name = useOfferStore().paywallOfferObject.productTitle ?? null; // ToDo: Name wird noch nicht befüllt
		if (this.debugMode) {
			console.log("function call 'productAddedToCart' (tenant " + this.publication + ") executed", product, cart, name);
		} else {
			try {
				Avo.productAddedToCart({ product: product, cart: cart, name: name });
			} catch (e) {
				this.debugTrackingError("productAddedToCart", e);
			}
		}
	}

	productRemovedFromCart() {
		const product = this.getProductFromStorage();
		const cart = this.getCartFromStorage();
		const name = useOfferStore().paywallOfferObject.productTitle ?? null; // ToDo: Name wird noch nicht befüllt
		if (this.debugMode) {
			console.log("function call 'productRemovedFromCart' (tenant " + this.publication + ") executed", product, cart, name);
		} else {
			try {
				Avo.productRemovedFromCart({ product: product, cart: cart, name: name });
			} catch (e) {
				this.debugTrackingError("productRemovedFromCart", e);
			}
		}
	}

	productSwitched(stepData: any) {
		const product = this.getProductFromStorage();
		const checkoutStep = this.getCheckoutStepFromStorage(stepData);
		if (this.debugMode) {
			console.log(
				"function call 'productSwitched' for step " + checkoutStep.step + " (tenant " + this.publication + ") executed",
				product,
				checkoutStep,
			);
		} else {
			try {
				Avo.productSwitched({ product: product, checkoutStep: checkoutStep });
			} catch (e) {
				this.debugTrackingError("productSwitched", e);
			}
		}
	}

	checkoutStepStarted(stepData: any, stepStatus: any) {
		const product = this.getProductFromStorage();
		const checkoutStep = this.getCheckoutStepFromStorage(stepData);
		const checkoutEntity: CheckoutEntity = {
			status: stepStatus,
		};
		if (this.debugMode) {
			console.log(
				"function call 'checkoutStepStarted' for step " + checkoutStep.step + " (tenant " + this.publication + ") executed",
				product,
				checkoutStep,
				checkoutEntity,
			);
		} else {
			try {
				Avo.checkoutStepStarted({
					product: product,
					checkoutStep: checkoutStep,
					checkoutEntity: checkoutEntity
				});
			} catch (e) {
				this.debugTrackingError("checkoutStepStarted", e);
			}
		}
	}

	checkoutStepCompleted(stepData: any, stepStatus: any) {
		const product = this.getProductFromStorage();
		const checkoutStep = this.getCheckoutStepFromStorage(stepData);
		const checkoutEntity: CheckoutEntity = {
			status: stepStatus,
		};
		if (this.debugMode) {
			console.log(
				"function call checkoutStepCompleted for step " + checkoutStep.step + " (tenant " + this.publication + ") executed",
				product,
				checkoutStep,
				checkoutEntity,
			);
		} else {
			try {
				Avo.checkoutStepCompleted({
					product: product,
					checkoutStep: checkoutStep,
					checkoutEntity: checkoutEntity
				});
			} catch (e) {
				this.debugTrackingError("checkoutStepCompleted", e);
			}
		}
	}

	convertStringToFloat(string: any) {
		if (typeof string === "undefined" || string === "" || string === undefined) {
			return null;
		}
		string = string.trim();
		string = string.substring(0, string.length - 1);
		string = string.trim();
		string = string.replace(/,/gi, ".");
		return Number(string);
	}

	getCheckoutStepFromStorage(stepData: any) {
		const checkoutStep: CheckoutStep = {
			step: stepData+1,
			shippingPostcode: null,
			billingPostcode: null,
			shippingFullAddress: null,
			billingFullAddress: null,
			deliveryProvider: null,
			deliveryMethod: null,
			couponCode: null,
			accountType: null,
			paymentMethod: useCheckoutStore().session.paymentMethod || null,
			proofOfPayment: null
		};
		return checkoutStep;
	}

	getProductFromStorage() {
		const price = this.convertStringToFloat(useOfferStore().paywallOfferObject.productStep1Price);
		const listPrice = this.convertStringToFloat(useOfferStore().paywallOfferObject.productStep2Price);
		const product: Product = {
			id: useOfferStore().paywallOfferObject.productOfferId ?? null,
			name: useOfferStore().paywallOfferObject.productTitle || null,
			category: useOfferStore().paywallOfferObject.category || "plus",
			price: parseFloat(price) ?? 0,
			listPrice: parseFloat(listPrice) ?? null,
			quantity: useOfferStore().paywallOfferObject.productQuantity ?? 1,
			variant: useOfferStore().paywallOfferObject.variant ?? null,
			brand: this.publication,
			inventoryStatus: useOfferStore().paywallOfferObject.inventory_status ?? null,
			position: parseInt(useCheckoutStore().session.position) ?? 1,
			currency: useOfferStore().paywallOfferObject.currency || "EUR",
			creativeId: useOfferStore().paywallOfferObject?.creativeId || null
		};
		return [product];
	}

	getCartFromStorage() {
		const price: number | undefined = this.convertStringToFloat(useOfferStore().paywallOfferObject.productStep1Price);
		const cart: Cart = {
			cartId: useCheckoutStore().session.purchaseId ?? null,
			totalValue: price ?? 0,
			currency: "EUR",
		};
		return cart;
	}

	getPromotionFromStorage(promotionData) {
		const offerId = useOfferStore().updateOfferObject.updateOfferId ?? null;
		const productIds = promotionData.productIds;
		const promotion: Promotion = {
			id: offerId,
			name: useOfferStore().updateOfferObject.updateTitle ?? null,
			productIds: Array.isArray(productIds) ? (productIds.length ? productIds : [offerId]) : [productIds || offerId],
			creativeId: typeof promotionData.creativeId !== "undefined" ? promotionData.creativeId : "upsell",
			type: typeof promotionData.type !== "undefined" ? promotionData.type : false,
			slot: typeof promotionData.slot !== "undefined" ? promotionData.slot : false,
			position: typeof promotionData.position !== "undefined" ? promotionData.position : 1
		};
		return promotion;
	}

	debugTrackingError(functionName: any, errorMessage: any) {
		console.error("Checkout Tracking - error in function " + functionName + ": " + errorMessage);
	}

	isReload() {
		if (typeof useCheckoutStore().session.actualUrl == "undefined") return false;
		return useCheckoutStore().session.actualUrl === window.location.href;
	}
}