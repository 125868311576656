//Composable to get the users type
export default (): UserType => {
	if (import.meta.client === false) {
		return "anonymous";
	}

	const userInfoCookieName = useRuntimeConfig().public.userInfoCookieName;
	const cookieValue = useCookie(userInfoCookieName).value as string;

	if (!cookieValue) {
		return "anonymous";
	}

	const cookie: any = useUserDataFromCookie();

	if (cookie.userType) {
		return cookie.userType;
	}

	return "anonymous";
};

type UserType = "anonymous" | "registered" | "premium" | "internal";
