async function embedData(): void {
	const userData = await useUserDataFromCookie();
	const trackingStore = useTrackingStore();
	const { section, subsections } = trackingStore.getSectionAndSubsections();

	const user = {
		user_id: userData?.plenigoId || "",
		products: userData?.subscriptions?.map((subscription: any) => ({
			access_right_unique_id: subscription.accessRightUniqueId,
			internal_title: subscription.internalTitle,
			product_id: subscription.productId,
		})),
		access_rights: userData?.accessRights || [],
		product_string: userData?.subscriptions?.map((subscription: any) => subscription.accessRightUniqueId).join(","),
		anonymous_appstore_purchase: userData?.anonymousStorePurchaseData,
		has_webabo: userData?.subscription,
		user_type: userData?.userType || "anonymous",
	};
	const content = {
		page_type: trackingStore.getPageDocumentType(),
		container_id: trackingStore.getPageId(),
		has_paywall: trackingStore.pagePaywall,
		audience: trackingStore.getPageAudience(),
		content_user_needs: "Information",
		section: section,
		subsections: subsections,
		tags: trackingStore.getPageTags(),
		rtr_topics: trackingStore.getRetrescoTags(),
		content_type: trackingStore.getPageContentType(),
		freemium_state: trackingStore.getArticleType(),
	};
	useHead({
		script: [
			{
				innerHTML: `
					// Initialize dataLayer if not already present
					window.dataLayer = window.dataLayer || [];
					// Data from external trackingStorage
					window.dataLayerObject = window.dataLayerObject || {
						user: ${JSON.stringify(user)},
						content: ${JSON.stringify(content)},
						event: 'datalayer_init'
					};
					// Push the trackingStorage into dataLayer
					window.dataLayer.push(dataLayerObject);
				`,
			},
		],
	});
}

function embedScripts(): void {
	useHead({
		script: [
			// gtm is loaded by @zadigetvoltaire/nuxt-gtm
			/*
			{
				innerHTML: `
				  window['gtag_enable_tcf_support'] = true; // Aktivierung TCF für DSGVO-konforme Analytics-Einbindung (https://support.google.com/analytics/answer/10022331?hl=de&authuser=0)
				  (function(w,d,s,l,i){
					w[l] = w[l] || [];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
				  var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
				  j.async=true;
				  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
						  f.parentNode.insertBefore(j,f);})
				  (window,document,'script','dataLayer','GTM-PS7WZBT');
				`,
			},
			*/
			// sGTM (serverside Tag Manager)
			{
				innerHTML: `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					\t\tnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					\tj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					\t'https://tm.swp.de/mvfz8rj3.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','npg-tm');
				`,
			},
		],
	});
}

function init(): void {
	embedData();
	embedScripts();
}

export default {
	init,
};
