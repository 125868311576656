function unwrapRef(field: any) {
	return typeof field === "object" && "value" in field ? field.value : field;
}

async function fetchFieldValidation(pattern: string, value: string) {
	const response = await fetch("/api/user/form-value-validation", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			pattern: pattern,
			fieldValue: value,
		}),
	});

	return await response.json();
}

const Validator = {
	required: function (field: any, errorMessage = "Bitte füllen Sie dieses Feld aus") {
		return !unwrapRef(field) ? errorMessage : "";
	},
	email: async function (field: any, errorMessage = "Bitte füllen Sie dieses Feld aus") {
		const value = (unwrapRef(field) || "").trim();
		if (value.length === 0) {
			return errorMessage;
		}
		const data = await fetchFieldValidation("emailplus", value);
		return data.success ? "" : errorMessage;
	},
	name: async function (field: any, errorMessage = "Bitte füllen Sie dieses Feld aus") {
		const value = (unwrapRef(field) || "").trim();
		if (value.length === 0) {
			return errorMessage;
		}
		const data = await fetchFieldValidation("name", value);
		return data.success ? "" : errorMessage;
	},
	password: async function (field: any, errorMessage = "Bitte geben Sie ein Passwort ein", additionalInfoRef: any = {}) {
		const value = (unwrapRef(field) || "").trim();

		if (value.length === 0) {
			additionalInfoRef.minlength = false;
			additionalInfoRef.maxlength = false;
			additionalInfoRef.uppercase = false;
			additionalInfoRef.lowercase = false;
			additionalInfoRef.number = false;
			return errorMessage;
		}

		const data = await fetchFieldValidation("password", value);

		additionalInfoRef.minlength = data?.additional_data?.minlength;
		additionalInfoRef.maxlength = data?.additional_data?.maxlength;
		additionalInfoRef.uppercase = data?.additional_data?.uppercase;
		additionalInfoRef.lowercase = data?.additional_data?.lowercase;
		additionalInfoRef.number = data?.additional_data?.number;

		return data.success ? "" : data?.additional_data?.info || errorMessage;
	},
	sameAs: function (field1: any, field2: any, errorMessage = "Bitte geben Sie ein Passwort ein") {
		return unwrapRef(field1) !== unwrapRef(field2) ? errorMessage : "";
	},
	aboList: function (field: any, errorMessage = "Bitte wählen Sie mindestens ein Newsletter aus") {
		const value = unwrapRef(field) || [];
		if (value.length === 0) {
			return errorMessage;
		}
		return "";
	},
};

export default Validator;
