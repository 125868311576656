import { ref } from "vue";
import { useOnboardingStore } from "@/stores/onboarding.store"

export default function useOnboarding() {
	const onboardingStore = useOnboardingStore();
	const { shouldShow, onboardingData } = storeToRefs(onboardingStore);
	if (!import.meta.client || !useUserIsLoggedIn()) {
		log("Das Onboarding kann nur clientseitig aufgerufen werden! Ende.")
		return ref(false);
	}
	const secondsToWaitAfterInitialization: number = 60 * 3; // 3 Minuten nach der Initialisierung
	const secondsToRemindOfCompletion: number = 60 * 60 * 24 * 30; // 30 Tage nach dem Öffnen des Onboardings
	const searchParams = new URLSearchParams(document.location.search);

	async function checkOnboardingStatus() {
		log("Checking onboarding status");
		try {
			if (searchParams.get("onboarding") === "reset") {
				deleteSearchParam("onboarding");
				await onboardingStore.reset();
			}

			if (searchParams.get("onboarding") === "show") {
				deleteSearchParam("onboarding");
				shouldShow.value = true;
				await onboardingStore.show();
			} else if (onboardingData.value.onboarding_complete) {
				log("Onboarding completed. Doing nothing.");
			} else if (onboardingData.value.onboarding_show) {
				const secondsPassedSinceShown = (getCurrentUnixTimestamp() - parseInt(onboardingData.value.onboarding_show));
				log("Onboarding has been shown but not yet been completed.")
				log("Checking time passed: " + secondsPassedSinceShown + " > " + secondsToRemindOfCompletion + " = " + (secondsPassedSinceShown > secondsToRemindOfCompletion));
				if (secondsPassedSinceShown > secondsToRemindOfCompletion) {
					await onboarding.show();
				}
			} else if (onboardingData.value.onboarding_init === "") {
				// New User, no onboarding started
				log("Onboarding initialized. Exit.");
				await onboardingStore.init();
			} else if (onboardingData.value.onboarding_init !== "" && !onboardingData.value.onboarding_show) {
				const secondsPassedSinceInit = getCurrentUnixTimestamp() - parseInt(onboardingData.value.onboarding_init);
				log("Onboarding is initialized but not yet shown.");
				log("Checking time passed: " + secondsPassedSinceInit + " > " + secondsToWaitAfterInitialization + " = " + (secondsPassedSinceInit > secondsToWaitAfterInitialization))
				// Die Zeit wurde initialisiert aber das Onboarding noch nicht gezeigt
				const isWaitTimeEllapsed: Boolean = secondsPassedSinceInit > secondsToWaitAfterInitialization;
				if (isWaitTimeEllapsed) {
					log("Onboarding should be shown. Exit.");
					await onboarding.show();
				}
			}
		} catch (error) {
			console.error("Failed to fetch onboarding status", error);
		}
	}

	/**
	 * Löscht den übergebenen Key aus den Search-Params, ohne die Seite neu zu laden.
	 * @param key
	 */
	function deleteSearchParam(key) {
		if (searchParams.get(key) === null) {
			return;
		}
		let currentUrl = new URL(window.location);

		searchParams.delete(key);

		// Update the search part of the URL
		currentUrl.search = searchParams.toString();

		// Update the browser's address bar without reloading the page
		window.history.pushState({path:currentUrl.href}, '', currentUrl.href);
		log("Deleting the url param. New URL: " + currentUrl.href);
	}

	function log(message) {
		if (useIsDebug()) {
			console.log("Onboarding: ", message);
		}
	}

	function getCurrentUnixTimestamp() {
		return Math.floor(Date.now() / 1000);
	}

	const onboarding = {
		shouldShow,
		data: onboardingData,
		fetch: onboardingStore.getOnboardingData,
		init: onboardingStore.init,
		show: onboardingStore.show,
		complete: onboardingStore.complete,
		reset: onboardingStore.reset
	}

	if (typeof window !== 'undefined') {
		window.onboarding = onboarding;
	}

	onboardingStore.getOnboardingData().then(() => {
		log("Onboarding data: " + JSON.stringify(onboarding.data.value));
		checkOnboardingStatus();
	});

	return { ...onboarding };
}
