<template>
	<!-- STEP START -->
	<section class="u-onboarding u-onboarding--step-start">
		<div class="background-theme theme-light-blue u-onboarding__side">
			<img :src="'/static/images/onboarding-start.svg'" alt="" height="205" class="u-onboarding__side-image" />
			<h2 class="mt-6 mb-0">3 Tipps für noch mehr Vergnügen</h2>
			<p class="fs-4 mt-6 mb-0">Mit <b>ein paar einfachen Klicks</b> lesen Sie noch mehr von dem, was Sie besonders interessiert.</p>
			<AtomsBaseCTA class="mt-8 u-onboarding__next-step-button" version="primary" type="button" @click="$emit('goto', 1)" :big="true">
				Los geht’s
			</AtomsBaseCTA>
		</div>
	</section>
</template>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-onboarding--step-start {
		h2 {
			font-size: 2.5rem;
			max-width: 37.5rem;
		}
		img {
			@include tablet-above {
				max-height: 22vh;
			}
		}
		@include mobile {
			.u-onboarding__side-image {
				height: 9.1875rem;
			}

			h2 {
				font-size: 1.75rem;
			}
		}
	}
</style>
