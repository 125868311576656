export default () => {
	try {
		// Auf dem client verwenden wir den sessionStorage, ansonsten den query-Wert
		if (useRoute().query.debug === "true") {
			if (import.meta.client) {
				sessionStorage.setItem("debug", "true");
			} else {
				return true
			}
		} else if (useRoute().query.debug === "false") {
			if (import.meta.client) {
				sessionStorage.removeItem("debug");
			}
		}
		// Setzt das debugging anhand vom SessionStorage (auf dem client) ansonsten ist es an dieser Stelle false
		return import.meta.client ? sessionStorage.getItem("debug") === "true" : false;
	} catch (e) {
		return false;
	}
};
