<template>
	<section class="u-onboarding u-onboarding--step-2">
		<div class="background-theme theme-light-blue u-onboarding__side">
			<img :src="`/static/images/envelope.svg`" alt="" height="142" />
			<h2 class="fs-6 mt-6 mb-0">Melden Sie sich jetzt für unsere beliebtesten Newsletter an!</h2>
			<p class="fs-3 mt-6 mb-0">Lassen Sie sich bequem per Mail über Ihre Lieblingsthemen informieren.</p>
			<OnboardingSteps class="mt-6" :activeIndex="step - 1" :steps="steps" @activate="$emit('goto', $event + 1)"></OnboardingSteps>
		</div>
		<div class="u-onboarding__content">
			<form class="u-onboarding__newsletter-form" @submit.prevent="onSubmit" novalidate>
				<MoleculesNewsletterCard
					v-for="newsletterCard in newsletterCardList"
					v-bind="newsletterCard"
					:key="newsletterCard.cardKey"
					@select="addAbo"
					@unselect="removeAbo"
				>
				</MoleculesNewsletterCard>
				<p class="fs-1 mt-2 mb-0">Melden Sie sich jetzt unkompliziert an und bleiben Sie automatisch informiert</p>
				<AtomsToggleSwitch
					name="terms"
					v-model="formTerms.fieldValue"
					@change="formTerms.validate"
					:error="!!formTerms.errorMessage"
					:isValidated="formTerms.isValidated"
					class="u-newsletter-form__agreement mt-2 mb-0"
				>
					{{ config?.newsletterAgreementText }}
				</AtomsToggleSwitch>
				<p
					class="u-newsletter-form__info-text mt-2 mb-0"
					v-text="config?.newsletterTextHint"
					v-if="config?.newsletterTextHint?.length > 0"
				></p>
				<AtomsBaseCTA class="u-onboarding__next-step-button mt-10 mx-auto" version="primary" type="submit" :big="true">
					Speichern und weiter
				</AtomsBaseCTA>
				<div class="mt-2" v-if="response?.status">
					<p :class="`font-set-2-bold text-color-${response.status}`" v-if="response?.message">{{ response?.message }}</p>
				</div>
			</form>
		</div>
	</section>
</template>

<script lang="ts" setup>
	import type { INewsletterCard } from "../../storybook/src/components/molecules/MoleculesNewsletterCard.vue";
	import Validate from "../server/utils/form-validator";

	defineProps<{
		step: number;
		steps: string[];
	}>();

	const emit = defineEmits(["goto"]);

	const config = useClientSideConfig();
	const onboardingNewsletter = config.onboarding_newsletter;

	const newsletterData = await useFetch("/api/newsletter");
	const response: { status?: string; message?: string; title?: string } = ref({}) as any;

	const newsletterCardList = ref(createNewsletterCardList(newsletterData?.data?.value || []));

	function createNewsletterCardList(data: any[]): INewsletterCard[] {
		const newsletterIconPath = "/static/images/newsletter/";

		if (!data.length) {
			return [];
		}

		return data
			?.filter((item) => item.active === true)
			?.filter((item) => onboardingNewsletter.includes(item?.salescloud_key))
			?.map((item: any) => {
				let newsletterIcon = newsletterIconPath + item?.salescloud_key + ".svg";

				if ((item?.salescloud_key && item?.salescloud_key.indexOf("nl_") === -1) || !item?.salescloud_key) {
					newsletterIcon = newsletterIconPath + "nl_default.svg";
				}

				return {
					newsletterIcon: newsletterIcon,
					newsletterNotificationTime: item?.interval,
					previewUrl: item?.preview_url,
					title: item?.title,
					text: item?.teaser,
					cardKey: item?.salescloud_key,
				};
			});
	}

	const aboList: Ref<Set<string>> = ref(new Set<string>());

	const formTerms = useFormValue({
		default: false,
		validate: (field) => Validate.required(field, "Bitte geben Sie Ihr Einverständnis"),
	});

	function addAbo(aboKey: string): void {
		aboList.value.add(aboKey);
	}

	function removeAbo(aboKey: string): void {
		aboList.value.delete(aboKey);
	}

	async function onSubmit() {
		if (aboList.value.size === 0) {
			emit("goto", 3);
			return;
		} else if (aboList.value.size > 0 && !(await formTerms.validate())) {
			return;
		}
		sendNewsletterData();
	}

	async function sendNewsletterData() {
		for (const element of aboList.value) {
			let currentAbo = newsletterData?.data?.value.find((item) => item.salescloud_key === element);

			if (!currentAbo) continue;

			const userData = useUserDataFromCookie() || {};

			const body = {
				newsletter_meta_data: config.newsletter_meta_data,
				subscriptionSource: "NL-Anmeldung Onboarding",
				currentURL: window.location.href,
				email: userData?.email,
				firstName: userData?.firstname,
				lastName: userData?.lastname,
				salutation: userData?.salutation === "Keine" ? "" : userData?.salutation,
				campaignId: currentAbo.campaign_id,
			};

			const { data, error } = await useFetch("/api/user/newsletter", {
				method: "POST",
				body,
			});

			if (data.value === "") {
				emit("goto", 3);
			}

			if (error.value !== null) {
				response.value = {
					title: null,
					message: "Ein Fehler ist aufgetreten versuchen Sie es bitte später erneut.",
					status: "error",
				};

				throw new Error("Network response was not OK");
			}
		}
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-onboarding--step-2 {
		.u-newslettercard + .u-newslettercard {
			margin-top: 0.5rem;
		}
	}

	.u-onboarding__newsletter-form {
		text-align: left;
		display: flex;
		flex-direction: column;

		.u-newslettercard__text {
			display: none;
		}
		.u-newslettercard__preview-link {
			display: none;
		}
	}
</style>
