import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

const PAYWALL_OFFER_STORAGE_KEY = "paywallOffer";
const UPDATE_OFFER_STORAGE_KEY = "updateOffer";

export const useOfferStore = defineStore("offer", () => {
	const paywallOfferObject = useStorage(PAYWALL_OFFER_STORAGE_KEY, {});
	const updateOfferObject = useStorage(UPDATE_OFFER_STORAGE_KEY, {});

	return {
		paywallOfferObject,
		updateOfferObject,
	};
});
