//Composable to resolve publication from url or domain.

import { Publications } from "@shared/types";

//If no url is supplied uses the current url
export default (url?: string): { name: string; domain: string; baseUrl: string; userPageTitle: string; userPageUrl: string } => {
	//Use current url
	if (!url) {
		url = useRequestURL().toString();
	}
	const matches = url.toLowerCase().match(/(http(s)?:\/\/)?(www\.)?/gi);
	const urlPrefix = matches && matches.length > 0 ? matches[0].replace("http:", "https:") : "";

	url = url.toLowerCase().replace(/(http(s)?:\/\/)?(www\.)?/gi, "");

	if (url.startsWith(useRuntimeConfig().public.swpDomain.replace("www.", ""))) {
		return {
			name: Publications.SWP,
			domain: useRuntimeConfig().public.swpDomain,
			baseUrl: urlPrefix + useRuntimeConfig().public.swpDomain,
			userPageTitle: "Meine SWP",
			userPageUrl: "/meine-swp/",
		};
	} else if (url.startsWith(useRuntimeConfig().public.mozDomain.replace("www.", ""))) {
		return {
			name: Publications.MOZ,
			domain: useRuntimeConfig().public.mozDomain,
			baseUrl: urlPrefix + useRuntimeConfig().public.mozDomain,
			userPageTitle: "Mein MOZ.de",
			userPageUrl: "/mein-moz/",
		};
	} else if (url.startsWith(useRuntimeConfig().public.lrDomain.replace("www.", ""))) {
		return {
			name: Publications.LR,
			domain: useRuntimeConfig().public.lrDomain,
			baseUrl: urlPrefix + useRuntimeConfig().public.lrDomain,
			userPageTitle: "Meine LR",
			userPageUrl: "/meine-lr/",
		};
	}

	throw new Error("Unknown publication: " + useRequestURL().host);
};
