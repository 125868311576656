//Composable to check if user is present but not logged in
export default (): boolean => {
	return (
		(useCookie(useRuntimeConfig().public.userInfoCookieName).value === undefined ||
			useCookie(useRuntimeConfig().public.userInfoCookieName).value === null) &&
		useCookie("plenigoSession").value !== undefined &&
		useCookie("plenigoSession").value !== null &&
		import.meta.client === true
	);
};
