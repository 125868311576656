import { defineStore } from "pinia";

const PATH_BLACK_LIST = Object.freeze([
	"/thema",
	"/autoren",
	"/autor/",
	"/suche",
	"/plus",
	"/meine-swp/",
	"/meine-lr",
	"/mein-moz",
	"/checkout",
	"/registrierung",
	"/weitere",
	"/newsletter",
	"/service/app/raetsel",
]);

export const usePageStore = defineStore("page", {
	state: () => ({
		page: undefined,
		duplicateIds: [],
	}),
	getters: {
		isInitialized: (state) => !!state.page,
		getType: (state) => state.page?.type,
		getPage: (state) => state.page,
		getSystemdata: (state) => state.page?.systemdata,
		getPaywall: (state) => state.page?.paywall,
		getContent: (state) => state.page?.content,
		getMetadata: (state) => state.page?.metadata,
		getUrl: (state) => state.page?.url,
		getCanonical: (state) => state.page?.canonical,
		getCategoryData: (state) => state.page?.categoryData,
		getShowAds: (state) => state.page?.showAds,
		getRetrescoTopics: (state) => state.page?.metadata?.articleTags,
		getDepulicatedIds: (state) => state.duplicateIds,
		getFreemiumState: (state) => state.page?.metadata?.payCategory,
	},
	actions: {
		async initializePage() {
			const route = useRoute();
			if (PATH_BLACK_LIST.find((i) => route.path.startsWith(i))) {
				return;
			}
			if (!this.page) {
				const { data, error } = await useFetch("/api/page", {
					method: "POST",
					body: {
						path: useRoute().path === "" ? "/" : useRoute().path,
					},
				});
				if (error.value) {
					console.log("hier in error: ", error.value);
					throw createError({
						statusCode: 404,
						statusMessage: "Page Not Found",
					});
				}
				this.page = data?.value;
			}
		},
		addDepulicatedIds(ids: string[] | string) {
			this.duplicateIds = [...this.duplicateIds, ...new Set(ids)];
		},
	},
});
