<template>
	<NuxtLayout>
		<NuxtPage />
		<MoleculesModal
			:show="showLoginRequiredModal"
			:width="'50rem'"
			class="u-login-after-bookmark-modal text-center"
			@close="showLoginRequiredModal = false"
		>
			<AtomsIconBookmark class="u-bookmark-info-icon text-color-primary"></AtomsIconBookmark>
			<div v-if="loginRequiredMessageType === 'topic'">
				<span class="font-set-8 d-block mt-6">Thema folgen</span>
				<span class="font-set-3 d-block mt-3">
					Um diese Funktion nutzen zu können, müssen Sie registriert sein. Jetzt einfach interessante Themen
					speichern, um ihnen zu folgen.
				</span>
				<AtomsBaseCTA class="mt-10 d-block mx-auto px-10" version="primary" type="button" :big="true"
							  @click="openLoginAfterBookmark">
					Anmelden und Thema folgen
				</AtomsBaseCTA>
			</div>
			<div v-else>
				<span class="font-set-8 d-block mt-6">Artikel merken</span>
				<span class="font-set-3 d-block mt-3">
					Um diese Funktion nutzen zu können, müssen Sie registriert sein. Jetzt einfach interessante Artikel
					speichern, um sie später zu lesen und wiederzufinden.
				</span>
				<AtomsBaseCTA class="mt-10 d-block mx-auto px-10" version="primary" type="button" :big="true"
							  @click="openLoginAfterBookmark">
					Anmelden und Artikel merken
				</AtomsBaseCTA>
			</div>
			<span class="font-set-2-bold d-block text-center mt-3">
				Sie haben noch keinen Account?<AtomsBaseCTA
					class="font-set-2"
					version="link"
					type="button"
					@click="openRegistrationAfterBookmark"
				>
					Hier registrieren
				</AtomsBaseCTA>
			</span>
		</MoleculesModal>
		<ClientOnly>
			<MoleculesModal
				:show="shouldShow"
				:width="'860px'"
				class="u-login-after-bookmark-modal text-center u-onboarding__modal"
				@close="shouldShow = false"
			>
				<Onboarding></Onboarding>
			</MoleculesModal>
		</ClientOnly>
	</NuxtLayout>
</template>

<script setup>
	import { useClientStore } from "~/stores/client.store";
	import useOnboarding from "~/composables/useOnboarding";
	import AvoAndSnowplow from "~/tracking/embeds/AvoAndSnowplow";
	import { Publications } from "@shared/types";
	import GoogleTagManager from "~/tracking/embeds/GoogleTagManager";
	import Kilkaya from "~/tracking/embeds/Kilkaya";
	import { usePageStore } from "~/stores/page.store";

	//Do not remove this as this helps with caching pages in Cloudfront
	const nuxtApp = useNuxtApp();
	delete nuxtApp?.payload?.path;
	const appCookieVersion = "2";

	const clientStore = useClientStore();
	const showLoginRequiredModal = ref(false);
	const loginRequiredMessageType = ref("article");
	const scripts = [];
	let shouldShow = ref(false);

	if (useIsApp()) {
		scripts.push({ src: "/static/js/core.iapps.js", defer: true });
	}
	const favicons = [
		{ rel: "shortcut icon", href: `/static/favicons/${usePublication()}/favicon.ico`, type: "image/png" },
		{ rel: "apple-touch-icon-precomposed", sizes: "57x57", href: `/static/favicons/${usePublication()}/apple-touch-icon-57x57.png` },
		{
			rel: "apple-touch-icon-precomposed",
			sizes: "114x114",
			href: `/static/favicons/${usePublication()}/apple-touch-icon-114x114.png`,
		},
		{ rel: "apple-touch-icon-precomposed", sizes: "72x72", href: `/static/favicons/${usePublication()}/apple-touch-icon-72x72.png` },
		{
			rel: "apple-touch-icon-precomposed",
			sizes: "144x144",
			href: `/static/favicons/${usePublication()}/apple-touch-icon-144x144.png`,
		},
		{ rel: "apple-touch-icon-precomposed", sizes: "60x60", href: `/static/favicons/${usePublication()}/apple-touch-icon-60x60.png` },
		{
			rel: "apple-touch-icon-precomposed",
			sizes: "120x120",
			href: `/static/favicons/${usePublication()}/apple-touch-icon-120x120.png`,
		},
		{ rel: "apple-touch-icon-precomposed", sizes: "76x76", href: `/static/favicons/${usePublication()}/apple-touch-icon-76x76.png` },
		{
			rel: "apple-touch-icon-precomposed",
			sizes: "152x152",
			href: `/static/favicons/${usePublication()}/apple-touch-icon-152x152.png`,
		},
		{ rel: "icon", type: "image/png", href: `/static/favicons/${usePublication()}/favicon-196x196.png`, sizes: "196x196" },
		{ rel: "icon", type: "image/png", href: `/static/favicons/${usePublication()}/favicon-96x96.png`, sizes: "96x96" },
		{ rel: "icon", type: "image/png", href: `/static/favicons/${usePublication()}/favicon-32x32.png`, sizes: "32x32" },
		{ rel: "icon", type: "image/png", href: `/static/favicons/${usePublication()}/favicon-16x16.png`, sizes: "16x16" },
		{ rel: "icon", type: "image/png", href: `/static/favicons/${usePublication()}/favicon-128.png`, sizes: "128x128" },
	];

	const userType = useUserType();

	useHead({
		htmlAttrs: {
			lang: "de",
		},
		script: [
			{
				children: `window.isLivingOnline = "true"`,
				tagPriority: 0,
			},
			...scripts,
		],
		link: [...favicons],
	});

	const pageStore = usePageStore();
	await pageStore.initializePage();

	onMounted(async () => {
		useHead({
			script: [
				{
					children: `window.userType = '${userType}'`,
					tagPriority: 0,
				},
				{
					children: `window.isLivingOnline = "true"`,
					tagPriority: 0,
				},
				{
					src: "/static/js/setup_tcfApi.js",
					tagPriority: 0,
				},
				{
					src: "/static/js/cmpScript.js",
					tagPriority: 1,
				},
				{
					src: `https://static.cleverpush.com/channel/loader/${useCleverPushLoader(usePublication())}.js`,
					tagPriority: 0,
					async: true,
				},
			],
		});

		//If the user is login we start ping to refresh permissions
		if (useUserIsLoggedIn()) {
			//Ping every 6 hours
			let nextPing = new Date().getTime() + 21600000;
			if (localStorage) {
				if (localStorage.getItem("nextPing") === null) {
					localStorage.setItem("nextPing", nextPing.toString());
				} else {
					nextPing = parseInt(localStorage.getItem("nextPing"));
				}
			}

			//The 60 second interval in combination with the nextPing interval helps to avoid time drift if the user locks the screen
			//or similar events desyncing the interval from real time occur
			setInterval(async () => {
				if (localStorage) {
					if (localStorage.getItem("nextPing") !== null) {
						nextPing = parseInt(localStorage.getItem("nextPing"));
					}
				}

				//Only when its time to ping we do something
				if (nextPing <= new Date().getTime()) {
					nextPing = new Date().getTime() + 21600000;

					if (localStorage) {
						localStorage.setItem("nextPing", nextPing.toString());
					}

					await userPing();

					if (localStorage) {
						localStorage.setItem("appCookieVersion", appCookieVersion);
					}
				}
			}, 60000);
		}

		//Force user ping if user is logged in but cookie has a new version
		if (useUserIsLoggedIn() && localStorage && localStorage.getItem("appCookieVersion") !== appCookieVersion) {
			await userPing();
			localStorage.setItem("appCookieVersion", appCookieVersion);
		}

		//If user is missing login we try to use plenigo session for login
		if (useUserIsCorrupted() && localStorage) {
			await userPing();
			localStorage.setItem("appCookieVersion", appCookieVersion);
		}
	});

	async function userPing(event = undefined) {
		await fetch("/api/no-cache/user/ping");
	}

	function useCleverPushLoader(publication) {
		switch (publication) {
			case Publications.SWP:
				return "725AjyS2BcPtnRPrg";
			case Publications.MOZ:
				return "SgCaXF9gyWtcWr2uh";
			case Publications.LR:
				return "M86c43uYXsZa7LSsj";
			default:
				return "";
		}
	}

	async function readTeaser(event) {
		if (clientStore.bookmarks.includes(event.detail.id)) {
			await useFetch("/api/user/user-favourites/read-bookmark", {
				method: "POST",
				body: event.detail,
			});
		}
	}

	async function bookmarkArticle(event) {
		if (useUserIsLoggedIn()) {
			const { bookmarks } = await $fetch("/api/user/user-favourites/set-bookmark", {
				method: "POST",
				body: event.detail,
			});
			clientStore.updateBookmarks(bookmarks || []);
		} else {
			showLoginRequiredModal.value = true;
		}
	}

	async function handleShowLoginRequiredModal(e) {
		const detail = e?.detail;
		showLoginRequiredModal.value = true;
		loginRequiredMessageType.value = detail.messageType || "article";
	}

	async function initBookmarkState() {
		clientStore.triggerBookmarkStateUpdate();
	}

	onMounted(() => {
		if (useUserIsLoggedIn()) {
			clientStore.fetchBookmarks();
			shouldShow = useOnboarding().shouldShow;
		}
		document.body.addEventListener("show-login-required-modal", handleShowLoginRequiredModal);
		document.body.addEventListener("bookmark-article", bookmarkArticle);
		document.body.addEventListener("bookmark-mounted", initBookmarkState);
		document.body.addEventListener("teaser-click", readTeaser);
		document.body.addEventListener("user-ping", async () => {
			await userPing();

			if (localStorage) {
				localStorage.setItem("appCookieVersion", appCookieVersion);
			}
		});

		setScrollbarWitdh();

		if (useIsApp()) {
			if (typeof ApplicationEvents !== "undefined") {
				const options = {
					onIncomingEvent: (eventName, eventData) => {
						if (eventName === "changeFontSize") {
							const fontLevel = parseInt(eventData);
							const fontSizeLevel = Math.min(3, Math.max(1, fontLevel));

							if (fontSizeLevel === 2) {
								document.documentElement.style.fontSize = "20px";
							} else if (fontSizeLevel === 3) {
								document.documentElement.style.fontSize = "23px";
							} else {
								document.documentElement.style.fontSize = null;
							}
						}
					},
				};
				const changeFontSizeEvent = new ApplicationEvents(options);
			}
		}

		GoogleTagManager.init();
		AvoAndSnowplow.init();
		Kilkaya.init();
	});

	onUnmounted(() => {
		document.body.removeEventListener("show-login-required-modal", handleShowLoginRequiredModal);
		document.body.removeEventListener("bookmark-article", bookmarkArticle);
		document.body.removeEventListener("bookmark-mounted", initBookmarkState);
		document.body.removeEventListener("teaser-click", readTeaser);
	});

	function setScrollbarWitdh() {
		function getScrollbarWidth() {
			if (!document.body) return;
			// Creating invisible container
			const outer = document.createElement("div");
			outer.style.visibility = "hidden";
			outer.style.overflow = "scroll"; // forcing scrollbar to appear
			outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement("div");
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);
			return scrollbarWidth;
		}
		document.documentElement.style.setProperty("--scrollbar-width", getScrollbarWidth() + "px");
	}

	function openLoginAfterBookmark(event) {
		useOpenLogin(event);
		showLoginRequiredModal.value = false;
	}

	function openRegistrationAfterBookmark(event) {
		useOpenRegistration(event);
		showLoginRequiredModal.value = false;
	}

	// Reset modal when closing it
	watch(showLoginRequiredModal, (newValue) => {
		if (!newValue) {
			loginRequiredMessageType.value = "article";
		}
	});

	watch(
		shouldShow,
		(newShouldShow, oldShouldShow) => {
			if (typeof document !== "undefined" && newShouldShow !== oldShouldShow) {
				document.body.dispatchEvent(
					new CustomEvent("avo-onboarding-flow-interacted", {
						detail: { onboardingAction: newShouldShow ? "started" : "abandoned" },
					}),
				);
			}
		},
		{ immediate: true },
	);
</script>

<style lang="scss">
	.u-login-after-bookmark-modal {
		.u-bookmark-info-icon {
			height: 2.1875rem;

			path {
				fill: currentColor;
			}
		}
	}
</style>
