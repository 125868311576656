import { IncomingQueryParams } from "@living-online/types";
import type { IClientSideConfig } from "~/server/plugins/03.context";

export default defineNuxtRouteMiddleware(async (to, from) => {
	const { data: config } = await useFetch("/api/config");

	if (to.query[IncomingQueryParams.XML] === "IAPPS") {
		setPageLayout("app");
	}

	useNuxtApp().vueApp.$clientSideConfig = config.value as IClientSideConfig;
});
