import { useCheckoutStore } from "~/stores/checkout.store";

export default (page: Record<string, any>, url: string, paywallposition: number) => {
	const urlFragments = url.split("/");
	useCheckoutStore().session.position = paywallposition?.toString() || "";
	useCheckoutStore().session.originUrl = window.location.href;
	useCheckoutStore().session.articleId = page?.documentId ?? false;
	useCheckoutStore().session.pageType = page?.contentType ?? false;
	useCheckoutStore().session.articlePublishedTime = page.lastPublicationDate ?? false;
	useCheckoutStore().session.articleSection = urlFragments?.[2] ?? false;
	useCheckoutStore().session.articleSubSection = urlFragments?.[1] ?? false;
	useCheckoutStore().session.freemiumState = page?.payCategory ?? false;
	useCheckoutStore().session.articleTitle = page.title ?? false;
	useCheckoutStore().session.hasStartedAt = page.firstPublicationDate ?? false;
};
