// https://stackoverflow.com/a/30106551
function b64DecodeUnicode(str) {
	return decodeURIComponent(atob(str).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));
}

export default () => {
	try {
		const userInfoCookieName = useRuntimeConfig().public.userInfoCookieName;
		const userInfoCookie: any = useCookie(userInfoCookieName).value;
		return JSON.parse(b64DecodeUnicode(userInfoCookie.split(".")[1])) || {};
	} catch (e) {
		return {};
	}
};
