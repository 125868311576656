<template>
	<section class="u-onboarding u-onboarding--step-end">
		<div class="background-theme theme-light-blue u-onboarding__side">
			<img :src="'/static/images/celebration.svg'" alt="" height="205" class="u-onboarding__side-image" />
			<h2 class="mt-6 mb-0">Ihre Einstellungen wurden übernommen!</h2>
			<p class="fs-4 mt-6 mb-0">
				Sehen Sie sich jetzt die Artikel an, die zu Ihren ausgewählten Themen passen.<br />Unter der "{{
					usePublicationInfo().userPageTitle
				}}"-Seite können Sie Ihre Einstellungen jederzeit ändern.
			</p>
			<AtomsBaseCTA
				class="mt-8 u-onboarding__next-step-button"
				version="primary"
				type="button"
				:url="usePublicationInfo().userPageUrl"
				:big="true"
			>
				Zur “{{ usePublicationInfo().userPageTitle }}”-Seite
			</AtomsBaseCTA>
		</div>
	</section>
</template>

<script lang="ts" setup>
	import { useOnboardingStore } from "~/stores/onboarding.store";
	onMounted(() => {
		useOnboardingStore().complete();
	});
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-onboarding--step-end {
		h2 {
			font-size: 2.5rem;
			max-width: 37.5rem;
		}
		@include mobile {
			.u-onboarding__side-image {
				height: 9.1875rem;
				max-height: 22vh;
			}

			h2 {
				font-size: 1.75rem;
			}
		}
	}
</style>
