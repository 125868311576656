<template>
	<ClientOnly>
		<MoleculesMyTopicsForm
			v-bind="topicForm"
			v-if="topicForm !== false"
			@changeLocation="saveLocation($event)"
			@addTopic="useSetTopicFollowState($event, allTopics, 'add')"
			@removeTopic="useSetTopicFollowState($event, allTopics, 'delete')"
		>
			<template v-slot:button>
				<slot name="button"></slot>
			</template>
		</MoleculesMyTopicsForm>
		<template #fallback>
			<div>
				<Loading class="mt-10"></Loading>
			</div>
		</template>
	</ClientOnly>
</template>

<script lang="ts" setup>
	const props = withDefaults(
		defineProps<{
			includeFavouriteTopics?: boolean;
		}>(),
		{
			includeFavouriteTopics: false,
		},
	);

	const allTopics: Ref<any> = ref([]);

	async function saveLocation(location: string) {
		await useFetch(`/api/no-cache/user/user-favourites/favourite-location`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: {
				location,
			},
		});
	}

	const topicForm: Ref<any> = ref(false);
	onMounted(async () => {
		const [favouriteLocationResponse, availableLocationsResponse, favouriteTopicsResponse, topTopics] = await Promise.all([
			fetch(`/api/no-cache/user/user-favourites/favourite-location`).then((res) => res.text()),
			fetch(`/api/no-cache/user/user-favourites/available-locations`).then((res) => res.json()),
			fetch(`/api/no-cache/user/user-favourites/favourite-topics`).then((res) => res.json()),
			fetch(`/api/${usePublication()}/retresco/topics/top-topic`).then((res) => res.json()),
		]);

		allTopics.value = [...(topTopics || []), ...(props.includeFavouriteTopics ? favouriteTopicsResponse : [])].filter(
			(topic, index, all) => all.findIndex((t) => t.topicId === topic.topicId) === index,
		);

		topicForm.value = {
			selectedLocation: favouriteLocationResponse,
			locations: availableLocationsResponse.map((l: any) => l.label || ""),
			topics: allTopics.value.map((t: any) => ({
				key: t.topicId,
				title: t.topic,
			})),
			selectedTopics: favouriteTopicsResponse.map((t: any) => t.topicId),
		};
	});
</script>
