import { default as _91_46_46_46slug_93G8GmRmPMOIMeta } from "/app/nuxt/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93uFmEt0KhFlMeta } from "/app/nuxt/pages/autor/[...slug].vue?macro=true";
import { default as autoren4CuhXO4T7yMeta } from "/app/nuxt/pages/autoren.vue?macro=true";
import { default as indexN5ZVL9QCnDMeta } from "/app/nuxt/pages/checkout/index.vue?macro=true";
import { default as debuggerVbQX25ysf3Meta } from "/app/nuxt/pages/debugger.vue?macro=true";
import { default as indexWuOO4VwizBMeta } from "/app/nuxt/pages/my-publication/index.vue?macro=true";
import { default as meine_45themenlMMXy82tJyMeta } from "/app/nuxt/pages/my-publication/meine-themen.vue?macro=true";
import { default as newsletterBCGRI1YYWBMeta } from "/app/nuxt/pages/newsletter.vue?macro=true";
import { default as indexvpzkZVVwWdMeta } from "/app/nuxt/pages/plus/index.vue?macro=true";
import { default as kontogw1GzErYlhMeta } from "/app/nuxt/pages/plus/konto.vue?macro=true";
import { default as kuendigungOv0kuYH2yZMeta } from "/app/nuxt/pages/plus/kuendigung.vue?macro=true";
import { default as email_45bestaetigungCBZPcELltLMeta } from "/app/nuxt/pages/registrierung/email-bestaetigung.vue?macro=true";
import { default as indexehodxQQgSeMeta } from "/app/nuxt/pages/service/app/raetsel/index.vue?macro=true";
import { default as sudokutXscUnYRfKMeta } from "/app/nuxt/pages/service/app/raetsel/sudoku.vue?macro=true";
import { default as suche3CSGJAvRT0Meta } from "/app/nuxt/pages/suche.vue?macro=true";
import { default as _91_46_46_46slug_93D9pu1lLFOMMeta } from "/app/nuxt/pages/thema/[...slug].vue?macro=true";
import { default as indexwA0H2aEmEvMeta } from "/app/nuxt/pages/thema/index.vue?macro=true";
import { default as passwort_45vergessenEP6x1NJWydMeta } from "/app/nuxt/pages/weitere/passwort-vergessen.vue?macro=true";
import { default as indexWunbvki9gHMeta } from "~/pages/my-publication/index.vue?macro=true";
import { default as meine_45themenPc4TuKeimHMeta } from "~/pages/my-publication/meine-themen.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "autor-slug",
    path: "/autor/:slug(.*)*",
    component: () => import("/app/nuxt/pages/autor/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "autoren",
    path: "/autoren",
    component: () => import("/app/nuxt/pages/autoren.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexN5ZVL9QCnDMeta || {},
    component: () => import("/app/nuxt/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "debugger",
    path: "/debugger",
    meta: debuggerVbQX25ysf3Meta || {},
    component: () => import("/app/nuxt/pages/debugger.vue").then(m => m.default || m)
  },
  {
    name: "my-publication",
    path: "/my-publication",
    meta: indexWuOO4VwizBMeta || {},
    component: () => import("/app/nuxt/pages/my-publication/index.vue").then(m => m.default || m)
  },
  {
    name: "my-publication-meine-themen",
    path: "/my-publication/meine-themen",
    meta: meine_45themenlMMXy82tJyMeta || {},
    component: () => import("/app/nuxt/pages/my-publication/meine-themen.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    meta: newsletterBCGRI1YYWBMeta || {},
    component: () => import("/app/nuxt/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "plus",
    path: "/plus",
    meta: indexvpzkZVVwWdMeta || {},
    component: () => import("/app/nuxt/pages/plus/index.vue").then(m => m.default || m)
  },
  {
    name: "plus-konto",
    path: "/plus/konto",
    meta: kontogw1GzErYlhMeta || {},
    component: () => import("/app/nuxt/pages/plus/konto.vue").then(m => m.default || m)
  },
  {
    name: "plus-kuendigung",
    path: "/plus/kuendigung",
    meta: kuendigungOv0kuYH2yZMeta || {},
    component: () => import("/app/nuxt/pages/plus/kuendigung.vue").then(m => m.default || m)
  },
  {
    name: "registrierung-email-bestaetigung",
    path: "/registrierung/email-bestaetigung",
    component: () => import("/app/nuxt/pages/registrierung/email-bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: "service-app-raetsel",
    path: "/service/app/raetsel",
    component: () => import("/app/nuxt/pages/service/app/raetsel/index.vue").then(m => m.default || m)
  },
  {
    name: "service-app-raetsel-sudoku",
    path: "/service/app/raetsel/sudoku",
    component: () => import("/app/nuxt/pages/service/app/raetsel/sudoku.vue").then(m => m.default || m)
  },
  {
    name: "suche",
    path: "/suche",
    component: () => import("/app/nuxt/pages/suche.vue").then(m => m.default || m)
  },
  {
    name: "thema-slug",
    path: "/thema/:slug(.*)*",
    meta: _91_46_46_46slug_93D9pu1lLFOMMeta || {},
    component: () => import("/app/nuxt/pages/thema/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "thema",
    path: "/thema",
    meta: indexwA0H2aEmEvMeta || {},
    component: () => import("/app/nuxt/pages/thema/index.vue").then(m => m.default || m)
  },
  {
    name: "weitere-passwort-vergessen",
    path: "/weitere/passwort-vergessen",
    component: () => import("/app/nuxt/pages/weitere/passwort-vergessen.vue").then(m => m.default || m)
  },
  {
    name: "meine-swp",
    path: "/meine-swp",
    meta: indexWunbvki9gHMeta || {},
    component: () => import("~/pages/my-publication/index.vue").then(m => m.default || m)
  },
  {
    name: "meine-lr",
    path: "/meine-lr",
    component: () => import("~/pages/my-publication/index.vue").then(m => m.default || m)
  },
  {
    name: "mein-moz",
    path: "/mein-moz",
    component: () => import("~/pages/my-publication/index.vue").then(m => m.default || m)
  },
  {
    name: "meine-swp-topic",
    path: "/meine-swp/meine-themen",
    meta: meine_45themenPc4TuKeimHMeta || {},
    component: () => import("~/pages/my-publication/meine-themen.vue").then(m => m.default || m)
  },
  {
    name: "meine-moz-lr",
    path: "/meine-lr/meine-themen",
    component: () => import("~/pages/my-publication/meine-themen.vue").then(m => m.default || m)
  },
  {
    name: "mein-moz-topic",
    path: "/mein-moz/meine-themen",
    component: () => import("~/pages/my-publication/meine-themen.vue").then(m => m.default || m)
  }
]