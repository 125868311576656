//Composable to resolve publication from url or domain.

import { Publications } from "@shared/types";

//If no url is supplied uses the current url
export default (url?: string): string => {
	//Use current url
	if (!url) {
		url = useRequestURL().toString();
	}

	url = url.toLowerCase().replace(/(https?:\/\/)?(www\.)?/gi, "");

	if (url.startsWith(useRuntimeConfig().public.swpDomain.replace("www.", ""))) {
		return Publications.SWP;
	} else if (url.startsWith(useRuntimeConfig().public.mozDomain.replace("www.", ""))) {
		return Publications.MOZ;
	} else if (url.startsWith(useRuntimeConfig().public.lrDomain.replace("www.", ""))) {
		return Publications.LR;
	}

	throw new Error("Unknown publication: " + useRequestURL().host);
};
