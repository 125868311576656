import { useTrackingStore } from "~/stores/tracking.store";
import { toISOStringTZ } from "@shared/functions/transformer/date";

// iBau-Bot Plenigo Ids
const bots = ["801001097403", "801001106427", "801001100949"];

function embedData(): void {
	const domain = `https://www.${usePublicationInfo().domain}`;
	const trackingStore = useTrackingStore();
	const { section, subsections } = trackingStore.getSectionAndSubsections();
	const route = useRoute();
	const userDataCookie = useUserDataFromCookie();
	const userNeeds = trackingStore.getPageUserNeeds();
	const k5a = {
		section: section ? `"section": "${section}",` : "",
		subsection: subsections.length ? `"subsection": ${JSON.stringify(subsections)},` : "",
		author:
			typeof JSON.stringify(trackingStore.getPageAuthors()) !== "undefined"
				? `"author": ${JSON.stringify(trackingStore.getPageAuthors())},`
				: "",
		paid: route.path === "/" ? "" : `"paid": ${trackingStore.getPageIsPaidContent()},`,
		paywall: route.path === "/" ? "" : `"paywall": "${trackingStore.getPagePaywall()}",`,
		topics: trackingStore.getPageTags(),
	};

	trackingStore.getPageAudience() ? k5a.topics.push(`Themengebiet: ${trackingStore.getPageAudience()}`) : null;
	userNeeds !== "Keine Kategorie" ? k5a.topics.push(`Bedürfniskategorie: ${userNeeds}`) : null;

	const tags = `"tag": ${JSON.stringify(k5a.topics)}`;

	useHead({
		script: [
			{
				innerHTML: `
					var k5aMeta = {
						"url": "${domain + route.fullPath}",
						"type": "${trackingStore.getPageKilkayaDocumentType()}",
						"publishtime": "${toISOStringTZ(new Date(trackingStore.getPagePublicationDate()))}",
						"modifiedtime": "${toISOStringTZ(new Date(trackingStore.getPageModificationDate()))}",
						"login": ${useUserIsLoggedIn() ? 1 : 0},
						"subscriber": ${userDataCookie?.subscription ? 1 : 0},
						${k5a.section}
						${k5a.subsection}
						${k5a.author}
						${k5a.paid}
						${k5a.paywall}
						${tags}
					};
				`,
			},
		],
	});
}

function embedTopics() { }

function embedScript(): void {
	useHead({
		script: [
			{
				src: "//cls.k5a.io/6093b0e54e5b9d15851a1112.js",
				async: true,
			},
		],
	});
}

function init(): void {
	const isBot = bots.includes(useUserDataFromCookie()?.plenigoId)

	if (!isBot) {
		embedData();
		embedScript();
	}

	return
}

export default {
	init,
};
